import styled from "styled-components";


export const Background = styled.div`
     height: calc(100vh - 80px);
     @media(max-height:677px){
        height:100%;
    }
    padding: 0.5rem calc((100vw - 1000px) / 2);
    background-color: #0093E9;
    background-image: linear-gradient(0deg, #0093E9 0%, #99d4f7 50%, #ffffff 100%);
`;

export const Hr = styled.div`
background-color: #0093E9; 
height: 0.19rem;
width: 92%;
margin-top: 15px;   
`

export const Container = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 15% 1fr;
    grid-template-areas: ' t t '
                         ' e d  ';
    width: 100%;
    height: 40%;
    background: #fff;
    padding: 2rem 1rem;
    box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
    border-radius: .5rem;
    margin-top: 40px;
    min-height:479px;
    min-width:289px;
`

export const Titulo = styled.div`
grid-area: t;
`

export const Esquerda = styled.div`
grid-area: e;
`

export const Direita = styled.div`
margin-top: 40px;
grid-area: d;
`

export const InputLabel = styled.div`
display: flex;
flex-direction: column;

select{
    border-radius: 5px;
    text-align: center;
    height: 30px;
    width: 200px;
    margin-left: 5px;
    margin-right: 5px;
    -webkit-appearance:none; 
    background: url("https://cdn-icons-png.flaticon.com/512/60/60995.png") 95.5% 50% no-repeat;
    background-size: 14px;
}

label{
        margin-right: 15px;
        margin-left: 5px;
}
`
export const Button = styled.button`
  margin-top: 10px;
  margin-left: 5px;
  width: 80px;
  height: 35px;
  border-radius: 4px;
  background: #2b74e0;
  color: #fff;
  border: 2px;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;
    &:hover{
        background-color:#1563CF ;
    }
`;

export const Center = styled.div`
width: 100%;
margin: 5px auto auto 150px;
`