import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
  Input,
  Select,
  Label,
} from "../../ModalEditStyle";
import api from "../../../../services/api";
import Swal from "sweetalert2";

export default function ModalRegister({ showModal, setShowModal, data }) {
  const modalRef = useRef();
  const [matricula, setMatricula] = useState(0);
  const [nome, setNome] = useState("");
  const [vinculo, setVinculo] = useState("");
  const [apelido, setApelido] = useState("");

  const [inativo, setInativo] = useState(0);
  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  useEffect(() => {
    if (data) {
      setMatricula(data.matricula);
      setNome(data.nome);
      setVinculo(data.vinculo);
      setApelido(data.apelido);
      setInativo(data.inativo);
    }
  }, [data]);

  async function handleSubmit(e, id) {
    e.preventDefault();
    const data = {
      matricula: matricula,
      nome: nome,
      vinculo: vinculo,
      apelido: apelido,
      inativo: inativo,
    };
    try {
      const response = await api.patch(`/rh/contributors`, {
        data,
      });
      if (response.data.error) {
        return Swal.fire("Atenção!", response.data.error, "warning");
      }
      Toast.fire({
        icon: "success",
        title: "Editado com sucesso!",
      });
      setShowModal(!showModal);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      alert(
        error
      ); /*Swal('Atenção!', 'Serviço indisponível no momento', 'error')*/
    }
  }
  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              setShowModal(!showModal);
              // clear();
            }
          }}
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form onSubmit={handleSubmit}>
                <h3>Atualizar Colaborador</h3>
                <FormContent>
                  <Label>Nome:</Label>
                  <Input
                    type="name"
                    value={nome}
                    placeholder="Nome"
                    onChange={(e) => setNome(e.target.value.toUpperCase())}
                    required
                  />
                  <Label>Vínculo:</Label>
                  <Select
                    type="text"
                    value={vinculo}
                    placeholder="Vínculo"
                    onChange={(e) => setVinculo(e.target.value)}
                    required
                  >
                    <option value="ESTATUTARIO">ESTATUTARIO</option>
                    <option value="TEMPORARIO - CLT">TEMPORARIO - CLT</option>
                  </Select>
                  <Label>Apelido:</Label>
                  <Input
                    type="text"
                    value={apelido}
                    placeholder="Apelido"
                    onChange={(e) => setApelido(e.target.value.toUpperCase())}
                    required
                  />
                  <Label>Situação:</Label>
                  <Select
                    type="text"
                    value={inativo}
                    onChange={(e) => setInativo(e.target.value)}
                    required
                  >
                    <option value={0}>ATIVO</option>
                    <option value={1}>INATIVO</option>
                  </Select>
                </FormContent>
                <br />
                <Button type="submit">Atualizar</Button>
              </Form>
              <CloseModalButton
                onClick={() => {
                  setShowModal(!showModal);
                  // clear();
                }}
              />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
