import React, { useState, useEffect } from "react";
import {
  Container,
  Content,
  Hr,
  Titulo,
  Button,
  Input,
  Row,
  MainDiv,
  Table,
} from "./style";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import Pagination from "../../../components/Pagination";
import ModalRegister from "../../../components/Modal/Sec/Cursos/Register";
import ModalEdit from "../../../components/Modal/Sec/Cursos/Edit";
import { IoPencilOutline, IoTrashOutline } from "react-icons/io5";

export default function Cursos() {
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [curso, setCurso] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");

  useEffect(() => {
    api
      .get(`/sec/courses?nome=${search}`)
      .then((response) => {
        setCursos(response.data.cursos);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [search]);

  useEffect(() => {
    setTotalPages(Math.ceil(cursos.length / 15));
  }, [cursos]);

  function handleDelete(id) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "Você não poderá reverter essa ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Deletar",
      cancelButtonColor: "gray",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await api.delete(`/sec/courses/${id}`);
        if (response.data.error) {
          return Swal.fire(
            "Exclusão não permitida!",
            response.data.error,
            "warning"
          );
        }
        Toast.fire({
          icon: "success",
          title: "Deletado com sucesso!",
        });
        setCursos(cursos.filter((curso) => curso.id !== id));
      }
    });
  }

  return (
    <Container>
      <Content>
        <MainDiv>
          <Titulo>Controle de Cursos</Titulo>
          <Hr />
          <Row>
            <Button
              onClick={() => {
                setShowModalRegister(true);
              }}
            >
              Novo
            </Button>
            <Input>
              <input
                type="text"
                placeholder="Pesquisar curso..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value.toUpperCase());
                  setPage(1);
                }}
              />
            </Input>
          </Row>
          <Table>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Carga Horária</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {cursos.slice((page - 1) * 15, page * 15).map((c, i) => (
                  <tr key={i}>
                    <td data-title="ID" value={c.id}>
                      {c.id}
                    </td>
                    <td data-title="Nome" value={c.nome} className="td-left">
                      {c.nome}
                    </td>
                    <td data-title="Carga Horária">{c.carga_horaria}</td>
                    <td data-title="Editar">
                      <IoPencilOutline
                        title="Editar"
                        style={{ fontSize: "25px", cursor: "pointer" }}
                        onClick={() => {
                          setCurso(c);
                          setShowModalEdit(true);
                        }}
                      />
                    </td>
                    <td data-title="Excluir">
                      <IoTrashOutline
                        title="Excluir"
                        style={{ fontSize: "25px", cursor: "pointer" }}
                        onClick={() => {
                          handleDelete(c.id);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Table>
          <Pagination page={page} setPage={setPage} totalPages={totalPages} />
          <ModalRegister
            showModal={showModalRegister}
            setShowModal={setShowModalRegister}
          />
          <ModalEdit
            showModal={showModalEdit}
            setShowModal={setShowModalEdit}
            curso={curso}
          />
        </MainDiv>
      </Content>
    </Container>
  );
}
