import React, { useState, useEffect } from "react";
import {
  Container,
  Background,
  Hr,
  Titulo,
  Button,
  Input,
  Row,
  Table,
} from "./style";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import Pagination from "../../../components/Pagination";
import ModalRegister from "../../../components/Modal/Rh/Deslocamentos/Register";
import ModalEdit from "../../../components/Modal/Rh/Deslocamentos/Edit";
import { isAuthenticatedDev } from "../../../auth";
import { IoPencilOutline, IoTrashOutline } from "react-icons/io5";

export default function Deslocamentos() {
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [deslocamentos, setDeslocamentos] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [deslocamento, setDeslocamento] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    api
      .get(`/rh/deslocamentos?dev=${isAuthenticatedDev()}&nome=${search}`)
      .then((response) => {
        setDeslocamentos(response.data.deslocamentos);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [search]);

  useEffect(() => {
    if (deslocamentos) {
      setTotalPages(Math.ceil(deslocamentos.length / 15));
    } else {
      setTotalPages(1);
    }
  }, [deslocamentos]);

  async function handleDelete(id) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "O deslocamento será excluído permanentemente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Excluir",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await api.delete(`/rh/deslocamentos/${id}`);
        if (response.data.error) {
          return Swal.fire(
            "Exclusão não permitida!",
            response.data.error,
            "warning"
          );
        }
        Toast.fire({
          icon: "success",
          title: "Excluído com sucesso!",
        });
        setDeslocamentos(
          deslocamentos.filter((deslocamento) => deslocamento.id !== id)
        );
      }
    });
  }

  return (
    <Background>
      <Container>
        <Titulo>Controle de Deslocamentos</Titulo>
        <Hr />
        <Row>
          <Button
            onClick={() => {
              setShowModalRegister(true);
            }}
          >
            Novo
          </Button>
          <Input>
            <input
              value={search}
              onChange={(e) => {
                setSearch(e.target.value.toUpperCase());
                setPage(1);
              }}
              type="search"
              placeholder="Pesquisar nome..."
            />
          </Input>
        </Row>
        <Table>
          {deslocamentos ? (
            <>
              <table>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Nome</th>
                    <th>Centro de custo</th>
                    <th>Valor ida</th>
                    <th>Valor volta</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {deslocamentos
                    .slice((page - 1) * 15, page * 15)
                    .map((d, i) => (
                      <tr key={i}>
                        <td data-title="Id" value={d.id}>
                          {d.id}
                        </td>
                        <td data-title="Nome" className="td-left">
                          {d.cidade_origem.toUpperCase()} -
                          {d.cidade_destino.toUpperCase()}
                        </td>
                        <td
                          data-title="Centro de custo"
                          title={d.centro_custo}
                          value={d.id_centro_custo}
                        >
                          {d.id_centro_custo}
                        </td>
                        <td data-title="Valor ida" value={d.valor_ida}>
                          R${d.valor_ida}
                        </td>
                        <td data-title="Valor volta" value={d.valor_volta}>
                          R${d.valor_volta}
                        </td>
                        <td data-title="Editar">
                          <IoPencilOutline
                            title="Editar"
                            style={{ fontSize: "25px", cursor: "pointer" }}
                            onClick={() => {
                              setDeslocamento(d);
                              setShowModalEdit(true);
                            }}
                          />
                        </td>
                        <td data-title="Excluir">
                          <IoTrashOutline
                            title="Excluir"
                            style={{ fontSize: "25px", cursor: "pointer" }}
                            onClick={async () => {
                              await handleDelete(d.id);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          ) : (
            <>
              <table>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Nome</th>
                    <th>Centro de custo</th>
                    <th>Valor ida</th>
                    <th>Valor volta</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
              </table>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <h2>DESLOCAMENTOS NÃO ENCONTRADOS</h2>
              </div>
            </>
          )}
        </Table>
        <ModalRegister
          showModal={showModalRegister}
          setShowModal={setShowModalRegister}
        />
        <ModalEdit
          showModal={showModalEdit}
          setShowModal={setShowModalEdit}
          deslocamento={deslocamento}
        />
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Container>
    </Background>
  );
}
