import styled from "styled-components";

export const Background = styled.div`
  height: calc(100vh - 80px);
  @media (max-height: 700px) {
    height: 100%;
  }
  display: flex;
  padding: 0.5rem calc((100vw - 1000px) / 2);
  background-color: #0093e9;
  background-image: linear-gradient(
    0deg,
    #0093e9 0%,
    #99d4f7 50%,
    #ffffff 100%
  );
`;

export const Container = styled.div`
  margin: 60px auto auto 0;
  width: 100%;
  height: auto;
  background: #fff;
  padding: 2rem 1rem;
  align-items: center;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;

  /* min-height:479px; */
  min-width: 89px;
`;
export const Titulo = styled.div``;

export const Hr = styled.div`
  background-color: #0093e9;
  height: 0.19rem;
  width: 92%;
  margin-top: 15px;
`;

export const Form = styled.div`
  margin-top: 10px;
  font-size: 18px;

  label {
    margin-left: 5px;
    margin-top: 10px;
  }

  input {
    border-radius: 5px;
    text-align: center;
    height: 30px;
    width: 150px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 3px 7px;
  }

  select {
    border-radius: 5px;
    text-align: center;
    height: 30px;
    width: 350px;
    margin-left: 5px;
    margin-right: 5px;
    -webkit-appearance: none;
    background: url("https://cdn-icons-png.flaticon.com/512/60/60995.png") 95.5%
      50% no-repeat;
    background-size: 14px;
  }
`;

export const InputLabel = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  label {
    margin-right: 15px;
    margin-left: 5px;
  }

  input {
    height: 30px;
    width: 170px;
    border-radius: 5px;
    text-align: center;
    padding: 3px 7px;
  }

  textarea {
    height: 100px;
    width: 500px;
    border-radius: 5px;
    text-align: left;
    resize: none;
    padding: 5px;
  }
`;

export const Button = styled.button`
  margin-right: 5px;

  width: 100px;
  height: 30px;
  border-radius: 4px;
  background: #2b74e0;
  color: #fff;
  border: 2px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;
  &:hover {
    background-color: #1563cf;
  }
`;

export const Table = styled.div`
  margin-top: 15px;

  /* table{
    color: black;
    font-weight: bolder;
    font-size: 20px;
    border: 2px solid whitesmoke;
    width: 100%;
    text-align: center;
    border-collapse: collapse;

    thead {
        background: whitesmoke;
        
    }

    tr:nth-child(even) {
    background: #f3f3f3;
  }

  td{
      border: 2px solid black;
  }
} */
`;
export const Selects = styled.div`
  display: flex;
  position: relative;
  padding-bottom: 7px;
  ::-webkit-scrollbar-track {
    background-color: #f4f4f4;
  }
  ::-webkit-scrollbar {
    width: 2px;
    background: #f4f4a4;
  }
  ::-webkit-scrollbar-thumb {
    background: #dad7d7;
  }

  @media (max-width: 885px) {
    overflow-x: auto;
  }
  h5 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  div {
    display: flex;
    flex-direction: column;
    margin-right: 35px;
    &:nth-child(3) {
      margin-right: 0;
    }
  }
  select {
    border-radius: 5px;
    margin-top: 7px;
    text-align: center;
    height: 30px;
    width: 170px;
    margin-left: 5px;
    margin-right: 5px;
    -webkit-appearance: none;
    background: url("https://cdn-icons-png.flaticon.com/512/60/60995.png") 95.5%
      50% no-repeat;
    background-size: 14px;
  }
`;

export const ButtonAdd = styled.button`
  float: right;
  width: 22px;
  height: 21px;
  border-radius: 4px;
  background: #2b74e0;
  color: #fff;
  border: 2px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;
  &:hover {
    background-color: #1563cf;
  }
`;

export const BigButton = styled.button`
  margin-right: 5px;
  width: 140px;
  height: 45px;
  border-radius: 4px;
  background: #2b74e0;
  color: #fff;
  border: 2px;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;
  &:hover {
    background-color: #1563cf;
  }
`;
