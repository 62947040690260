import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
  Input,
  Select,
  Label,
} from "../../ModalRegisterStyle";
import api from "../../../../services/api";
import Swal from "sweetalert2";

export default function ModalRegister({ showModal, setShowModal }) {
  const modalRef = useRef();
  const [idCentroCusto, setIdCentroCusto] = useState("");
  const [idOrigem, setIdOrigem] = useState("");
  const [idDestino, setIdDestino] = useState("");
  const [valorIda, setValorIda] = useState("");
  const [valorVolta, setValorVolta] = useState("");
  const [centroCusto, setCentroCusto] = useState("");
  const [origem, setOrigem] = useState("");
  const [destino, setDestino] = useState("");
  const [centrosCusto, setCentrosCusto] = useState([]);
  const [cidades, setCidades] = useState([]);
  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    api
      .get(`/rh/costcenter`)
      .then((response) => {
        setCentrosCusto(response.data.centros_custo);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/rh/cidades`)
      .then((response) => {
        setCidades(response.data.cidades);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  /**
   * Vai servir para gerar o pdf do relatório
   * @param {Previne o evento padrão da função} e
   */
  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      id_centro_custo: idCentroCusto,
      id_cidade_origem: idOrigem,
      id_cidade_destino: idDestino,
      valor_ida: valorIda,
      valor_volta: valorVolta,
    };
    try {
      const response = await api.post(`rh/deslocamentos`, {
        data,
      });
      if (response.data.error) {
        return Swal.fire("Atenção!", response.data.error, "warning");
      }
      Swal.fire("Sucesso!", response.data.success, "success");
      Toast.fire({
        icon: "success",
        title: "Cadastrado com sucesso!",
      });
      setShowModal(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      alert(error);
    }
  }

  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              setShowModal(!showModal);
            }
          }}
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form onSubmit={handleSubmit}>
                <h3>Cadastrar Deslocamento</h3>
                <FormContent>
                  <Label>Centro de custo:</Label>
                  <Select
                    value={centroCusto}
                    onChange={(e) => {
                      setCentroCusto(e.target.value);
                      setIdCentroCusto(
                        e.target[e.target.options.selectedIndex].id
                      );
                    }}
                  >
                    <option disabled value="">
                      Selecione o centro de custo
                    </option>
                    {centrosCusto.map((c, i) => (
                      <option key={i} id={c.id}>
                        {c.nome}
                      </option>
                    ))}
                  </Select>
                  <Label>Origem:</Label>
                  <Select
                    value={origem}
                    onChange={(e) => {
                      setOrigem(e.target.value);
                      setIdOrigem(e.target[e.target.options.selectedIndex].id);
                    }}
                  >
                    <option disabled value="">
                      Selecione a cidade
                    </option>
                    {cidades.map((c, i) => (
                      <option key={i} id={c.id}>
                        {c.nome.toUpperCase()}
                      </option>
                    ))}
                  </Select>
                  <Label>Destino:</Label>
                  <Select
                    value={destino}
                    onChange={(e) => {
                      setDestino(e.target.value);
                      setIdDestino(e.target[e.target.options.selectedIndex].id);
                    }}
                  >
                    <option disabled value="">
                      Selecione a cidade
                    </option>
                    {cidades.map((c, i) => (
                      <option key={i} id={c.id}>
                        {c.nome.toUpperCase()}
                      </option>
                    ))}
                  </Select>
                  <Label>Valor da Ida:</Label>
                  <Input
                    type="number"
                    pattern="[0-9]+([,\.][0-9]+)?"
                    min="0"
                    step="any"
                    value={valorIda}
                    placeholder="R$"
                    onChange={(e) => setValorIda(e.target.value)}
                    required
                  />
                  <Label>Valor da Volta:</Label>
                  <Input
                    type="number"
                    value={valorVolta}
                    placeholder="R$"
                    onChange={(e) => setValorVolta(e.target.value)}
                    required
                  />
                </FormContent>
                <br />
                <Button type="submit">Cadastrar</Button>
              </Form>
              <CloseModalButton
                onClick={() => {
                  setShowModal(!showModal);
                }}
              />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
