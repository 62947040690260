import styled from "styled-components";

export const Background = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  font: 12pt "Tahoma";
  box-sizing: border-box;
  -moz-box-sizing: border-box;

  @page {
    size: A4;
    margin: 0;
  }
  @media print {
    width: 210mm;
    height: 297mm;
  }
`;

export const Page = styled.div`
  width: 210mm;
  min-height: 297mm;
  padding: 10mm;
  margin: 10mm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  @media print {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
`;

export const Subpage = styled.div`
  /* padding: 0.5cm; */
  /* border: 5px red solid; */
  height: 257mm;
  /* outline: 2cm #ffeaea solid; */
`;

export const Images = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  object-fit: cover;

  img {
    &:nth-child(1) {
      height: 60px;
    }

    &:nth-child(2) {
      height: 40px;
    }
  }
`;

export const Breaker = styled.div`
  width: 100%;
  height: fit-content;
`;

export const Content = styled.div`
  width: 100%;
`;

export const Title = styled.h1`
  font-size: 12px;
  text-align: center;
  padding: 20px 0;
`;

export const Table = styled.div`
  border: 2px solid black;
  font-size: 12px;
  width: 100%;
  text-align: center;


  th {
    border: 0.1px solid black;
  }

  td {
    border: 0.1px solid black;
    height: 45px;
  }

  th {
    &:nth-child(1) {
      width: 20%;
    }
    &:nth-child(2) {
      width: 30%;
    }
    &:nth-child(3) {
      width: 10%;
    }
    &:nth-child(4) {
      width: 40%;
    }
  }
`;

export const Assinaturas = styled.div`

  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;


  div {
    font-size: 12px;
  }

`;
