import { useState, useEffect } from "react";
import {
  Background,
  Container,
  Titulo,
  Hr,
  Table,
  Input,
  Button,
  Header,
  Checkbox,
  AddIcon,
  RemoveIcon,
} from "./style";
import api from "../../../services/api";
import Pagination from "../../../components/Pagination";
import { Swal, Toast } from "../../../services/SwalTemplates";

export default function RhTurmas() {
  const [Turmas, setTurmas] = useState([]);
  const [Search, setSearch] = useState("");
  const [TurmasIds, setTurmasIds] = useState([]);
  const [TotalPages, setTotalPages] = useState(1);
  const [Page, setPage] = useState(1);
  const [Reload, setReload] = useState(false);

  useEffect(() => {
    api
      .get(`/rh/turmas?search=${Search}`)
      .then((response) => {
        setTurmas(response?.data?.turmas);
      })
      .catch((error) => {
        Swal.fire({ title: "Error", text: "Erro!", icon: "error" });
      });
  }, [Search, Reload]);

  useEffect(() => {
    setTotalPages(Math.ceil(Turmas.length / 15));
  }, [Turmas]);

  async function HandleEdit() {
    if (!TurmasIds.length) {
      Swal.fire({
        title: "Atenção!",
        text: "Selecione ao menos uma turma!",
        icon: "warning",
      });
      return;
    }

    const { value: data_fim } = await Swal.fire({
      title: "Editar Data de Fim",
      html: `<p>Insira a data desejada para as turmas selecionadas</p>
            <input id="swal-input1" class="swal2-input" type="date">`,
      focusConfirm: false,
      confirmButtonText: "Editar",
      preConfirm: () => {
        return document.getElementById("swal-input1").value;
      },
    });

    if (data_fim) {
      const data = {
        ids: TurmasIds,
        data_fim: data_fim,
      };

      api
        .patch(`/rh/updatedatas`, { data })
        .then((response) => {
          Toast.fire({ title: response?.data?.success, icon: "success" });
          setTurmasIds([]);
          setReload((r) => !r);
        })
        .catch((error) => {
          Swal.fire({ title: "Error", text: "Erro!", icon: "error" });
        });
    }
  }

  return (
    <Background>
      <Container>
        <Titulo>Turmas</Titulo>
        <Hr />
        <Header>
          <Button onClick={() => HandleEdit()}>Editar</Button>
          <Input
            type="search"
            placeholder="Pesquisar ..."
            value={Search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Header>
        <Table>
          <table>
            <thead>
              <tr>
                <th>
                  <Checkbox
                    title="Desmarcar todos"
                    onClick={() => {
                      setTurmasIds([]);
                    }}
                  >
                    {TurmasIds.length > 0 && <RemoveIcon />}
                  </Checkbox>
                </th>
                <th>Nome</th>
                <th>Unidade</th>
                <th>Data Início</th>
                <th>Data Fim</th>
              </tr>
            </thead>
            <tbody>
              {Turmas?.slice((Page - 1) * 15, Page * 15)?.map((turma, i) => (
                <tr key={i}>
                  <td>
                    <Checkbox
                      title="Selecionar"
                      onClick={() => {
                        if (!TurmasIds?.includes(turma?.id)) {
                          setTurmasIds((v) => [...v, turma?.id]);
                        } else {
                          setTurmasIds((v) =>
                            v?.filter((t) => t !== turma?.id)
                          );
                        }
                      }}
                    >
                      {TurmasIds.includes(turma.id) && <AddIcon />}
                    </Checkbox>
                  </td>
                  <td className="td-left" data-title="Nome" title={turma?.nome}>
                    {turma?.nome.length > 50
                      ? turma?.nome?.substring(0, 50)
                      : turma?.nome}
                    {turma?.nome.length > 50 && "..."}
                  </td>
                  <td
                    className="td-left"
                    data-title="Unidade"
                    title={turma?.unidade}
                  >
                    {turma?.unidade.length > 40
                      ? turma?.unidade?.substring(0, 40)
                      : turma?.unidade}
                    {turma?.unidade.length > 40 && "..."}
                  </td>
                  <td data-title="Data Início">{turma?.data_inicio}</td>
                  <td data-title="Data Fim">{turma?.data_fim}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Table>
        <Pagination page={Page} setPage={setPage} totalPages={TotalPages} />
      </Container>
    </Background>
  );
}
