import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
  Input,
  Select,
  Label,
} from "./style";
import api from "../../../../../services/api";
import Swal from "sweetalert2";

export default function ModalEdit({ showModal, setShowModal, dados }) {
  const modalRef = useRef();
  const [nome, setNome] = useState("");

  const [tipo, setTipo] = useState("");
  const [evento, setEvento] = useState(0);
  const [id, setId] = useState(0);
  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  useEffect(() => {
    if (dados) {
      setNome(dados.nome);
      setEvento(dados.id_evento);
      setTipo(dados.tipo);
      setId(dados.id);
    }
  }, [dados]);

  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      id: id,
      nome: nome,
      id_evento: evento,
      tipo: tipo,
    };
    try {
      const response = await api.patch(`/rh/eventos`, {
        data,
      });
      if (response.data.error) {
        return Swal.fire("Atenção!", response.data.error, "warning");
      }
      Toast.fire({
        icon: "success",
        title: "Editado com sucesso!",
      });
      setShowModal(!showModal);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      Swal("Atenção!", "Serviço indisponível no momento", "error");
    }
  }
  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              setShowModal(!showModal);
            }
          }}
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form onSubmit={handleSubmit}>
                <h3>Atualizar Evento</h3>
                <FormContent>
                  <Label>Nome:</Label>
                  <Input
                    type="name"
                    value={nome}
                    placeholder="Nome"
                    onChange={(e) => setNome(e.target.value.toUpperCase())}
                    required
                  />
                  <Label>Evento:</Label>
                  <Input
                    type="number"
                    value={evento}
                    placeholder="Evento"
                    onChange={(e) => setEvento(e.target.value)}
                    required
                  />
                  <Label>Tipo:</Label>
                  <Select
                    value={tipo}
                    onChange={(e) => {
                      setTipo(e.target.value);
                    }}
                    required
                  >
                    <option disabled value="">
                      Selecione o tipo
                    </option>
                    <option value="C">Crédito</option>
                    <option value="D">Débito</option>
                  </Select>
                </FormContent>
                <br />
                <Button type="submit">Atualizar</Button>
              </Form>
              <CloseModalButton
                onClick={() => {
                  setShowModal(!showModal);
                }}
              />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
