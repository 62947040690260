import React, { useState, useEffect } from "react";
import api from "../services/api";
import { Swal } from "../services/SwalTemplates";

export default function DiaFimFes() {
  const [regras, setRegras] = useState([]);

  useEffect(() => {
    api
      .get(`/regras?nome=${"DIA MÊS ENCERRAR LANCTOS"}`)
      .then((response) => {
        if (response.data.regras) {
          setRegras(response.data.regras[0]);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, []);

  const hoje = new Date();
  const fechamento = new Date(
    hoje.getFullYear(),
    hoje.getMonth(),
    regras.valorFloat
  );
  let dias = 0;
  if (regras.ativo) {
    if (hoje > fechamento) {
      fechamento.setMonth(fechamento.getMonth() + 1);
      dias = Math.ceil((fechamento - hoje) / 86400000);
      return (
        <>
          <>Faltam </>
          <span
            style={{
              color:
                dias <= 3
                  ? "var(--red-color)"
                  : dias > 3 && dias <= 6
                  ? "#ffca00"
                  : "var(--blue-color)",
            }}
          >
            {dias}
          </span>
          <> dias para o fechamento do mês</>
        </>
      );
    } else {
      dias = Math.ceil((fechamento - hoje) / 86400000);
      return (
        <>
          <>Faltam </>
          <span
            style={{
              color:
                dias <= 3
                  ? "var(--red-color)"
                  : dias > 3 && dias <= 6
                  ? "#ffca00"
                  : "var(--blue-color)",
            }}
          >
            {dias}
          </span>
          <> dias para o fechamento do mês</>
        </>
      );
    }
  } else {
    return null;
  }
}
