import React, { useState, useEffect } from "react";
import {
  Background,
  Container,
  Titulo,
  Hr,
  Row,
  Nome,
  Input,
  ToggleButton,
  Circle,
  Button,
  ButtonDiv,
} from "./style";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import { MdHelp } from "react-icons/md";
import {
  IoTrashOutline,
  IoAddCircleOutline,
  IoPencilOutline,
} from "react-icons/io5";
import Modal from "../../../components/Modal/Dev/Regras";

export default function RegrasDev() {
  const [regras, setRegras] = useState([]);
  const [modalDados, setModalDados] = useState("");
  const [regrasChangedIndex, setRegrasChangedIndex] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [changed, setChanged] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    api
      .get(`/regras`)
      .then((response) => {
        setRegras(response.data.regras);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [reload]);

  async function handleSubmit(e) {
    e.preventDefault();

    let dataArray = [];
    regras.forEach((r, i) => {
      if (regrasChangedIndex.includes(r.id)) {
        dataArray.push(r);
      }
    });
    const data = {
      dados: dataArray,
    };
    try {
      const response = await api.patch(`/rh/regras`, {
        data,
      });
      if (response.data.error) {
        return Swal.fire("Atenção!", response.data.error, "warning");
      }
      Toast.fire({
        icon: "success",
        title: "Editado com sucesso!",
      });
      setReload(!reload);
    } catch (error) {
      Swal("Atenção!", "Serviço indisponível no momento", "error");
    }
  }

  async function handleDelete(id) {
    try {
      const response = await api.delete(`/dev/regras?id=${id}`);
      if (response.data.error) {
        return Swal.fire("Atenção!", response.data.error, "warning");
      }
      Toast.fire({
        icon: "success",
        title: "Editado com sucesso!",
      });
      setReload(!reload);
    } catch (error) {
      Swal("Atenção!", "Serviço indisponível no momento", "error");
    }
  }

  return (
    <Background>
      <Container>
        <Titulo>
          Regras
          <IoAddCircleOutline
            title="Adicionar Disciplina"
            style={{
              fontSize: "30px",
              cursor: "pointer",
              color: "var(--blue-color)",
              marginLeft: "10px",
            }}
            onClick={() => {
              setShowModal(true);
            }}
          />
        </Titulo>
        <Hr />
        {regras.map((r, i) => (
          <Row key={i}>
            <Nome>
              {r.nome}
              <MdHelp
                title="Descrição"
                size={16}
                color={"var(--blue-color)"}
                style={{ marginLeft: "5px", cursor: "pointer" }}
                onClick={() => {
                  Swal.fire({
                    title: "Descrição",
                    html: `<p align="justify">${r.descricao}</p>`,
                    showConfirmButton: false,
                    showCloseButton: true,
                  });
                }}
              />
            </Nome>
            <Input
              type={r.valorFloat ? "number" : "text"}
              value={r.valorFloat ? r.valorFloat : r.valorString}
              min={1}
              max={31}
              onChange={(e) => {
                if (!regrasChangedIndex.includes(r.id)) {
                  setRegrasChangedIndex([...regrasChangedIndex, r.id]);
                }
                setChanged(true);
                setRegras((regra) => [
                  ...regras.slice(0, i),
                  {
                    id: regra[i].id,
                    nome: regra[i].nome,
                    descricao: regra[i].descricao,
                    valorFloat: r.valorFloat
                      ? parseFloat(e.target.value)
                      : undefined,
                    valorString: r.valorFloat ? undefined : e.target.value,
                    ativo: regra[i].ativo,
                  },
                  ...regras.slice(i + 1),
                ]);
              }}
            />
            <ToggleButton
              active={r.ativo}
              onClick={() => {
                if (!regrasChangedIndex.includes(r.id)) {
                  setRegrasChangedIndex([...regrasChangedIndex, r.id]);
                }
                setChanged(true);
                setRegras((regra) => [
                  ...regras.slice(0, i),
                  {
                    id: regra[i].id,
                    nome: regra[i].nome,
                    descricao: regra[i].descricao,
                    valorString: regra[i].valorString,
                    valorFloat: regra[i].valorFloat,
                    ativo: !regra[i].ativo,
                  },
                  ...regras.slice(i + 1),
                ]);
              }}
            >
              <Circle active={r.ativo} />
            </ToggleButton>
            <IoPencilOutline
              title="Editar"
              style={{
                fontSize: "25px",
                cursor: "pointer",
                gridArea: "u",
                marginLeft: "30px",
                color: "var(--blue-color)",
              }}
              onClick={() => {
                setModalDados(r);
                setShowModal(true);
              }}
            />
            <IoTrashOutline
              title="Excluir"
              style={{
                fontSize: "25px",
                color: "var(--red-color)",
                gridArea: "d",
                cursor: "pointer",
                marginLeft: "30px",
              }}
              onClick={() => {
                Swal.fire({
                  title: "Você tem certeza?",
                  text: "Você não poderá reverter essa ação!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "var(--blue-color)",
                  cancelButtonColor: "var(--red-color)",
                  confirmButtonText: "Excluir",
                  cancelButtonText: "Cancelar",
                }).then((result) => {
                  if (result.isConfirmed) {
                    handleDelete(r.id);
                  }
                });
              }}
            />
          </Row>
        ))}
        <ButtonDiv changed={changed}>
          <Button
            style={{ backgroundColor: "var(--blue-color)" }}
            onClick={(e) => {
              handleSubmit(e);
              setChanged(false);
            }}
          >
            Salvar
          </Button>
          <Button
            style={{ backgroundColor: "var(--red-color)" }}
            onClick={() => {
              setReload(!reload);
              setChanged(false);
              setRegrasChangedIndex([]);
            }}
          >
            Cancelar
          </Button>
        </ButtonDiv>
      </Container>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        dados={modalDados}
        setDados={setModalDados}
      />
    </Background>
  );
}
