import React, { useState, useEffect } from "react";
import {
  Background,
  Container,
  Hr,
  Titulo,
  Button,
  Input,
  Row,
  Table,
} from "./style";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import Pagination from "../../../components/Pagination";
import ModalRegister from "../../../components/Modal/Rh/TiposLancamentos/Register";
import ModalEdit from "../../../components/Modal/Rh/TiposLancamentos/Edit";
import { IoPencilOutline, IoTrashOutline } from "react-icons/io5";

export default function Lancamentos() {
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [lancamentos, setLancamentos] = useState([]);
  const [lancamento, setLancamento] = useState("");

  useEffect(() => {
    api
      .get(`/rh/releases?search=${search}`)
      .then((response) => {
        setLancamentos(response.data.tipos_lancamentos);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [search]);

  useEffect(() => {
    setTotalPages(Math.ceil(lancamentos.length / 15));
  }, [lancamentos]);

  async function handleDelete(id) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "O usuário será excluído permanentemente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Excluir",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await api.delete(`/rh/releases/${id}`);
        if (response.data.error) {
          return Swal.fire(
            "Exclusão não permitida!",
            response.data.error,
            "warning"
          );
        }
        Toast.fire({
          icon: "success",
          title: "Excluído com sucesso!",
        });
        setLancamentos(
          lancamentos.filter((lancamento) => lancamento.id !== id)
        );
      }
    });
  }

  return (
    <Background>
      <Container>
        <Titulo>Controle de Lançamentos</Titulo>
        <Hr />
        <Row>
          <Button
            onClick={() => {
              setShowModalRegister(true);
            }}
          >
            Novo
          </Button>
          <Input>
            <input
              value={search}
              onChange={(e) => {
                setSearch(e.target.value.toUpperCase());
                setPage(1);
              }}
              type="search"
              placeholder="Pesquisar lançamento..."
            />
          </Input>
        </Row>
        <Table>
          <table>
            <thead>
              <tr>
                <th>Id</th>
                <th>Nome</th>
                <th>Evento</th>
                <th>Centro de Custo</th>
                <th>Vínculo</th>
                <th>Mult.</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {lancamentos?.slice((page - 1) * 15, page * 15)?.map((l, i) => (
                <tr key={i}>
                  <td data-title="Id" value={l.id}>
                    {l?.id}
                  </td>
                  <td data-title="Nome" value={l.nome} className="td-left">
                    {l?.nome}
                  </td>
                  <td data-title="Evento" value={l.evento}>
                    {l?.id_evento}
                  </td>
                  <td
                    data-title="Centro de Custo"
                    value={l?.centro_custo}
                    className="td-left"
                  >
                    {l?.centro_custo}
                  </td>
                  <td data-title="Vínculo" value={l.vinculo}>
                    {l?.vinculo}
                  </td>
                  <td data-title="Vínculo" value={l.vinculo}>
                    {l?.multiplicador}
                  </td>
                  <td data-title="Editar">
                    <IoPencilOutline
                      title="Editar"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={() => {
                        setLancamento(l);
                        setShowModalEdit(true);
                      }}
                    />
                  </td>
                  <td data-title="Excluir">
                    <IoTrashOutline
                      title="Excluir"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={async () => {
                        await handleDelete(l.id);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Table>
        <ModalRegister
          showModal={showModalRegister}
          setShowModal={setShowModalRegister}
        />
        <ModalEdit
          showModal={showModalEdit}
          setShowModal={setShowModalEdit}
          lancamento={lancamento}
        />
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Container>
    </Background>
  );
}
