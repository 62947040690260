import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  Input,
  Hr,
  TableForm,
  InputLabel,
} from "./style";
import Swal from "sweetalert2";
import Auth from "../../../../services/auth";
import api from "../../../../services/api";
import Pagination from "../../../Pagination";

export default function ModalRegister({
  showModal,
  setShowModal,
  disciplinasGrade,
  setDisciplinasGrade,
  defaultModulo,
  setTitlePage,
}) {
  const [disciplinas, setDisciplinas] = useState([]);
  const [idCoord] = useState(Auth.id());
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [disciplinasConst, setDisciplinasConst] = useState([]);
  const modalRef = useRef();
  const [modulo, setModulo] = useState(defaultModulo);
  const [modulos] = useState([
    "MOD/Série I",
    "MOD/Série II",
    "MOD/Série III",
    "MOD/Série IV",
    "MOD/Série V",
    "MOD/Série VI",
    "MOD/Série VII",
    "MOD/Série VIII",
    "MOD/Série IX",
    "MOD/Série X",
  ]);
  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });

  useEffect(() => {
    setTotalPages(Math.ceil(disciplinas.length / 10));
  }, [disciplinas]);

  useEffect(() => {
    api
      .get(`/coord/disciplinagrade?idCoord=${idCoord}&nome=${search}`)
      .then((response) => {
        setDisciplinas(response.data.disciplinas);
        if (disciplinasConst.length === 0) {
          setDisciplinasConst(response.data.disciplinas);
        }
      })
      .catch((error) => alert(error));
  }, [disciplinasConst, idCoord, search]);

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  /**
   * Ordena a array com relação ao módulo
   * @param {*} a
   * @param {*} b
   * @returns
   */
  function orderModulo(a, b) {
    if (a.modulo < b.modulo) {
      return -1;
    }
    if (a.modulo > b.modulo) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    setModulo(defaultModulo);
  }, [defaultModulo]);

  function handleAdd(id) {
    if (
      disciplinasGrade[
        disciplinasGrade.findIndex((o) => o.modulo === modulo)
      ] &&
      disciplinasGrade[disciplinasGrade.findIndex((o) => o.modulo === modulo)]
        .dados
    ) {
      if (
        disciplinasGrade
          .map((d) => d.dados.map((d2) => d2.id_disciplina).includes(id))
          .includes(true)
      ) {
        Swal.fire({
          title: "Atenção!",
          text: "Essa disciplina já foi inserida!",
          icon: "warning",
          confirmButtonColor: "var(--blue-color)",
        });
      } else {
        setDisciplinasGrade(
          [
            ...disciplinasGrade.filter((o) => o.modulo !== modulo),
            {
              modulo: modulo,
              dados: [
                ...disciplinasGrade[
                  disciplinasGrade.findIndex((o) => o.modulo === modulo)
                ].dados,
                ...disciplinasConst.filter((d) => d.id_disciplina === id),
              ],
            },
          ].sort(orderModulo)
        );
      }
    } else {
      if (disciplinasGrade.map((d) => d.id_disciplina).includes(id)) {
        Swal.fire({
          title: "Atenção!",
          text: "Essa disciplina já foi inserida!",
          icon: "warning",
          confirmButtonColor: "var(--blue-color)",
        });
      } else {
        setDisciplinasGrade(
          [
            ...disciplinasGrade.filter((o) => o.modulo !== modulo),
            {
              modulo: modulo,
              dados: [
                ...disciplinasConst.filter((d) => d.id_disciplina === id),
              ],
            },
          ].sort(orderModulo)
        );
      }
    }
    setTitlePage(modulo);
  }

  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              setShowModal(!showModal);
            }
          }}
        >
          <animated.div style={animation}>
            <ModalContent>
              <h3 style={{ textAlign: "center" }}>Adicionar Disciplina</h3>
              <Hr />
              <InputLabel>
                <label>Módulo</label>
                <select
                  value={modulo}
                  onChange={(e) => setModulo(e.target.value)}
                  required
                >
                  <option disabled value="">
                    Selecione o módulo
                  </option>
                  {modulos.map((m, i) => (
                    <option value={m} key={i}>
                      {m}
                    </option>
                  ))}
                </select>
              </InputLabel>
              <Hr />
              <Form>
                <Input
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value.toUpperCase());
                    setPage(1);
                  }}
                  type="search"
                  placeholder="Pesquisar disciplina..."
                />
                <TableForm>
                  <table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Nome</th>
                        <th>Carga Horária</th>
                      </tr>
                    </thead>
                    <tbody>
                      {disciplinas
                        ?.slice((page - 1) * 10, page * 10)
                        ?.map((d, i) => (
                          <tr
                            title="+"
                            key={i}
                            onClick={() => {
                              handleAdd(d.id_disciplina);
                              setShowModal(false);
                            }}
                          >
                            <td>{d.id_disciplina}</td>
                            <td>{d.disciplina}</td>
                            <td>{d.carga_horaria}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </TableForm>
              </Form>
              <Pagination
                page={page}
                setPage={setPage}
                totalPages={totalPages}
              />
              <CloseModalButton
                onClick={() => {
                  setShowModal(!showModal);
                }}
              />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
