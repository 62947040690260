import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
  Input,
  Select,
  Label,
} from "../../ModalEditStyle";
import api from "../../../../services/api";
import { Swal, Toast } from "../../../../services/SwalTemplates";

export default function ModalRegister({
  showModalEdit,
  setShowModalEdit,
  dados,
  turma,
  horario_inicio_posterior,
  horario_fim_anterior,
  rowTable,
}) {
  const modalRef = useRef();
  const [horario_inicio, setHorario_inicio] = useState("");
  const [horario_fim, setHorario_fim] = useState("");
  // const [turnos, setTurnos] = useState([]);
  // const [turno, setTurno] = useState('');
  const [dia_semana, setDia_semana] = useState("");
  const [id, setId] = useState("");
  const [DataInicio, setDataInicio] = useState("");
  const [DataFim, setDataFim] = useState("");
  // const [idTurno, setIdTurno] = useState(0);

  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModalEdit ? 1 : 0,
    transform: showModalEdit ? `translateY(0%)` : `translateY(-100%)`,
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModalEdit) setShowModalEdit(!showModalEdit);
    },
    [setShowModalEdit, showModalEdit]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  useEffect(() => {
    
    if (dados) {
      setId(dados?.id);
      setHorario_inicio(dados?.horario_inicio);
      setHorario_fim(dados?.horario_fim);
      setDia_semana(dados?.dia_semana);
      setDataInicio(
        `${dados?.data_inicio.split("/")[2]}-${
          dados?.data_inicio.split("/")[1]
        }-${dados?.data_inicio.split("/")[0]}`
      );
      setDataFim(
        `${dados?.data_fim.split("/")[2]}-${dados?.data_fim.split("/")[1]}-${
          dados?.data_fim.split("/")[0]
        }`
      );
    }
  }, [dados]);

  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      id: id.toString(),
      horario_inicio: horario_inicio,
      horario_fim: horario_fim,
      dia_semana: dia_semana,
      data_inicio: DataInicio,
      data_fim: DataFim,
    };

    try {
      if (horario_inicio > horario_fim) {
        return Swal.fire({
          title: "Atenção!",
          text: "O horário posterior deve ser maior que o atual!",
          icon: "warning",
        });
      }

      if (new Date(DataInicio).getTime() >= new Date(DataFim).getTime()) {
        return Swal.fire({
          title: "Atenção!",
          text: "A data de início deve ser maior que a de fim!",
          icon: "warning",
        });
      }

      if (rowTable.length !== 0) {
        let f = rowTable.filter(
          (r) => r.dia_semana === dia_semana && r.e_troca === 0 && r.id !== id
        );
        if (f.length) {
          let valid = false;
          for (let i = 0; i <= f.length; i++) {
            if (
              i === 0
                ? f[i].horario_inicio >= horario_fim
                : i === f.length
                ? f[i - 1].horario_fim <= horario_inicio
                : f[i - 1].horario_fim <= horario_inicio &&
                  f[i].horario_inicio >= horario_fim
            ) {
              valid = true;
            }
          }
          if (!valid && !turma.includes("ENFERMAGEM")) { //exceção para ENFERMAGEM
            return Swal.fire(
              "Atenção!",
              `O horário definido ${horario_inicio} e ${horario_fim} já está alocado`,
              "warning"
            );
          }
        }
      }

      api
        .patch(`/coord/lancamentohoras`, {
          data,
        })
        .then((response) => {
          if (response.data.error) {
            return Swal.fire("Atenção!", response.data.error, "warning");
          }
          Toast.fire({
            icon: "success",
            title: "Editado com sucesso!",
          });
          setShowModalEdit(!showModalEdit);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    } catch (error) {
      Swal("Atenção!", "Serviço indisponível no momento", "error");
    }
  }
  return (
    <>
      {showModalEdit ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              setShowModalEdit(!showModalEdit);
            }
          }}
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form onSubmit={handleSubmit}>
                <h3>Atualizar</h3>
                <FormContent>
                  <Label>Data Início</Label>
                  <Input
                    type="date"
                    value={DataInicio}
                    onChange={(e) => setDataInicio(e.target.value)}
                    required
                  />
                  <Label>Data Fim</Label>
                  <Input
                    type="date"
                    value={DataFim}
                    onChange={(e) => setDataFim(e.target.value)}
                    required
                  />
                  <Label>Horário Início</Label>
                  <Input
                    type="time"
                    value={horario_inicio}
                    onChange={(e) => setHorario_inicio(e.target.value)}
                    required
                  />
                  <Label>Horário Fim</Label>
                  <Input
                    type="time"
                    value={horario_fim}
                    onChange={(e) => setHorario_fim(e.target.value)}
                    required
                  />
                  <Label>Dia Semana</Label>
                  <Select
                    value={dia_semana}
                    onChange={(e) => setDia_semana(e.target.value)}
                    required
                  >
                    <option>SEG</option>
                    <option>TER</option>
                    <option>QUA</option>
                    <option>QUI</option>
                    <option>SEX</option>
                    <option>SÁB</option>
                  </Select>
                </FormContent>
                <Button type="submit">Atualizar</Button>
              </Form>
              <CloseModalButton
                onClick={() => {
                  setShowModalEdit(!showModalEdit);
                }}
              />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
