import styled from "styled-components";
import { MdClose } from "react-icons/md";

export const Background = styled.div`
  min-height: calc(100vh - 80px);
  @media (max-height: 739px) {
    height: 100%;
  }
  display: flex;
  padding: 0.5rem calc((100vw - 1000px) / 2);
  background-color: #0093e9;
  background-image: linear-gradient(
    0deg,
    #0093e9 0%,
    #99d4f7 50%,
    #ffffff 100%
  );
`;

export const Container = styled.div`
  margin: 60px auto auto 0;
  width: 100%;
  height: auto;
  background: #fff;
  padding: 2rem 1rem;
  align-items: center;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  min-width: 89px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
`;

export const Data = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const Input = styled.input`
  border-radius: 5px;
  width: 90%;
  height: 30px;
  border: 1px solid black;
  caret-color: #0093e9;
  grid-area: b;
  padding: 3px 7px;
`;

export const Disciplinas = styled.form`
  display: grid;
  // grid-template-areas:
  //   "a a a"
  //   "b b c"
  //   "d d d";
  padding-top: 20px;
  align-items: center;
  justify-content: center;
`;

export const Modulos = styled.form`
  padding-top: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
`;

export const Right = styled.div``;

export const Hr = styled.div`
  background-color: #0093e9;
  height: 0.19rem;
  width: 100%;
  margin-top: 15px;
`;

export const Form = styled.div`
  margin-top: 10px;
  font-size: 18px;

  input {
    border-radius: 5px;
    text-align: center;
    height: 30px;
    padding: 3px 7px;
  }

  select {
    border-radius: 5px;
    text-align: center;
    height: 30px;
    width: 100%;
    -webkit-appearance: none;
    background: url("https://cdn-icons-png.flaticon.com/512/60/60995.png") 95.5%
      50% no-repeat;
    background-size: 14px;
  }
`;

export const InputLabel = styled.div`
  grid-area: a;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  label {
    margin-right: 15px;
    margin-left: 5px;
    margin-top: 10px;
  }

  input {
    height: 30px;
    border-radius: 5px;
    text-align: center;
    padding: 3px 7px;
    border: 1px solid black;
  }

  select {
    height: 30px;
    border-radius: 5px;
    text-align: center;
    padding: 3px 7px;
  }
`;

export const Button = styled.button`
  width: 25%;
  height: 30px;
  border-radius: 4px;
  background: #2b74e0;
  color: #fff;
  border: 2px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;
  text-transform: uppercase;
  font-weight: bold;
  grid-area: b;
  &:hover {
    background-color: #1563cf;
  }
`;

export const TableForm = styled.div`
  margin-top: 15px;
  grid-area: d;

  table {
    color: black;

    font-size: 15px;
    border: 1px solid whitesmoke;
    width: 100%;
    text-align: center;
    border-collapse: collapse;

    thead {
      background: whitesmoke;
      border: 1px solid;
    }

    tr:nth-child(even) {
      background: #f3f3f3;
    }

    td {
      border: 1px solid black;
    }
  }
`;

export const Selects = styled.select`
  border-radius: 5px;
  margin-top: 7px;
  text-align: center;
  height: 30px;
  width: 250px;
  margin-left: 5px;
  margin-right: 5px;
  -webkit-appearance: none;
  background: url("https://cdn-icons-png.flaticon.com/512/60/60995.png") 95.5%
    50% no-repeat;
  background-size: 14px;
`;

export const ButtonAdd = styled.button`
  float: right;
  width: 22px;
  height: 21px;
  border-radius: 4px;
  background: #2b74e0;
  color: #fff;
  border: 2px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;
  &:hover {
    background-color: #1563cf;
  }
`;

export const Titulo = styled.h1`
  color: #0093e9;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 550px) {
    font-size: 28px;
  }
`;

export const BigButton = styled.button`
  margin-right: 5px;
  width: 140px;
  height: 45px;
  border-radius: 4px;
  background: #2b74e0;
  color: #fff;
  border: 2px;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;
  &:hover {
    background-color: #1563cf;
  }
`;

export const RowCenter = styled.div`
  display: ${(props) => (props.hidden ? "none" : "false")};
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
`;

export const Modal = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 23;
`;

export const ModalContent = styled.div`
  max-width: 100%;
  height: 90%;
  margin: 0 auto;
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  padding: 1rem;
  position: relative;
`;

export const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;

export const Table = styled.div`
  margin-top: 15px;
  width: 99%;
  overflow: auto;
  td {
    text-align: center;
  }
`;
