import React, { useState, useEffect } from "react";
import {
  Background,
  Content,
  Button,
  Table,
  Filters,
  Select,
  Header,
} from "./style";
import api from "../../../services/api";
import { Swal } from "../../../services/SwalTemplates";
import Pagination from "../../../components/Pagination";
import Switch from "../../../components/Switch";

export default function Logs() {
  const [email, setEmail] = useState("");
  const [acao, setAcao] = useState("");
  const [logs, setLogs] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selected, setSelected] = useState(0);
  const [search, setSearch] = useState("");

  useEffect(() => {
    api
      .get(`/dev/logs?email=${email}&acao=${acao}&limit=${limit}`)
      .then((response) => {
        setLogs(response.data.logs);
      })
      .catch((error) => {});
    setPage(1);
  }, [acao, email, limit]);

  useEffect(() => {
    setTotalPages(Math.ceil(logs.length / 10));
  }, [logs]);

  useEffect(() => {
    if (selected === 0) {
      setEmail(search);
      setAcao("");
    } else {
      setAcao(search);
      setEmail("");
    }
  }, [search, selected]);

  return (
    <Background>
      <Content>
        <Header>
          <h1 style={{ fontWeight: "bolder" }}>Registros de Log</h1>
          <Button
            type="button"
            onClick={async () => {
              Swal.fire({
                title: "Criptografar Texto",
                html:
                  '<input id="swal-input1" class="swal2-input">' +
                  '<input id="swal-input2" class="swal2-input" disabled>' +
                  '<div id="copiado" style="color: #c22a40"></div>' +
                  "<br>" +
                  '<input type="checkbox" id="swal-input3"> Criptografia de Senhas',
                focusConfirm: true,
                showCloseButton: true,
                confirmButtonText: "Criptografar",
                confirmButtonColor: "var(--blue-color)",
                preConfirm: async () => {
                  const data = {
                    value: document.getElementById("swal-input1").value,
                    hash: document.getElementById("swal-input3").checked,
                  };
                  await api
                    .post(`/dev/encrypt`, { data })
                    .then((response) => {
                      document.getElementById("swal-input2").value =
                        response.data.value;
                      navigator.clipboard.writeText(response.data.value);
                      document.getElementById("copiado").innerHTML =
                        "Texto copiado!";
                      setTimeout(() => {
                        document.getElementById("copiado").innerHTML = "";
                      }, 2000);
                    })
                    .catch((error) => console.log(error));
                  return false;
                },
              });
            }}
          >
            Criptografar
          </Button>
        </Header>
        <hr />
        <Filters>
          <Switch
            text1={"E-mail"}
            text2={"Ação"}
            placeholder1={"Insira o e-mail"}
            placeholder2={"Insira a ação"}
            search={search}
            selected={selected}
            setSelected={setSelected}
            setSearch={setSearch}
          />
          <Select value={limit} onChange={(e) => setLimit(e.target.value)}>
            <option value={10}>10 logs</option>
            <option value={50}>50 logs</option>
            <option value={100}>100 logs</option>
            <option value={250}>250 logs</option>
            <option value={500}>500 logs</option>
            <option value={-1}>Sem limite</option>
          </Select>
        </Filters>
        <Table>
          <table>
            <thead>
              <tr>
                <th>Data</th>
                <th>Ação</th>
                <th>Descricao</th>
                <th>E-mail</th>
              </tr>
            </thead>
            <tbody>
              {logs.slice((page - 1) * 10, page * 10).map((l, i) => (
                <tr key={i}>
                  <td data-title="Data" value={l.data_hora}>
                    {l.data_hora}
                  </td>
                  <td data-title="Ação" value={l.acao}>
                    {l.acao}
                  </td>
                  <td data-title="Descrição" value={l.descricao}>
                    {l.descricao}
                  </td>
                  <td data-title="E-mail" value={l.email}>
                    {l.email}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Table>
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Content>
    </Background>
  );
}
