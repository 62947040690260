import styled from "styled-components";

export const Background = styled.div`
  min-height: calc(100vh - 80px);
  display: flex;
  padding: 0.5rem calc((100vw - 1000px) / 2);
  background-color: #0093e9;
  justify-content: center;
  background-image: linear-gradient(
    0deg,
    #0093e9 0%,
    #99d4f7 50%,
    #ffffff 100%
  );
`;

export const Container = styled.div`
  width: 98%;
  height: fit-content;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  min-width: 289px;

  @media (max-width: 830px) {
    width: 90%;
  }
`;

export const Hr = styled.div`
  background-color: #0093e9;
  margin: 20px 0;
  height: 0.2rem;
  width: 80%;
`;

export const Titulo = styled.h1`
  color: #0093e9;
  text-align: center;

  @media (max-width: 550px) {
    font-size: 28px;
  }
`;

export const Table = styled.table`
  margin-top: 15px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Button = styled.button`
  width: 140px;
  height: 45px;
  border-radius: 4px;
  background: #2b74e0;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  text-decoration: none;
  transition: linear 0.2s;

  &:hover {
    background-color: #1563cf;
  }
`;

export const Input = styled.input`
  padding: 3px 7px;
  width: 250px;
  height: 45px;
  border-radius: 4px;
  font-size: 17px;
`;
