import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 1rem 0;

  @media screen and (max-width: 768px) {
    width: 95%;
  }
`;

export const Titulo = styled.h4`
  text-align: center;
`;
