import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
  Input,
  Label,
  Select,
} from "../../ModalEditStyle";
import api from "../../../../services/api";
import Swal from "sweetalert2";

export default function ModalRegister({ showModal, setShowModal, data }) {
  const modalRef = useRef();
  const [id, setId] = useState("");
  const [nome, setNome] = useState("");
  const [carga_horaria, setCarga_horaria] = useState("");
  const [insalubre, setInsalubre] = useState(0);
  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  useEffect(() => {
    if (data) {
      setId(data.id);
      setNome(data.nome);
      setCarga_horaria(data.carga_horaria);
      setInsalubre(data.insalubre);
    }
  }, [data]);

  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      id: id,
      nome: nome,
      carga_horaria: carga_horaria,
      insalubre: insalubre,
    };
    try {
      const response = await api.patch(`/coord/subjects`, { data });
      if (response.data.error) {
        return Swal.fire(
          "Exclusão não permitida!",
          response.data.error,
          "warning"
        );
      }
      Toast.fire({
        icon: "success",
        title: "Editado com sucesso!",
      });
      setShowModal(!showModal);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      alert(
        error
      ); /*Swal('Atenção!', 'Serviço indisponível no momento', 'error')*/
    }
  }
  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              setShowModal(!showModal);
              // clear();
            }
          }}
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form onSubmit={handleSubmit}>
                <h3>Atualizar Disciplina</h3>
                <FormContent>
                  <Label>Nome:</Label>
                  <Input
                    type="name"
                    value={nome}
                    placeholder="Nome"
                    onChange={(e) => setNome(e.target.value.toUpperCase())}
                    required
                  />
                  <Label>Carga Horária:</Label>
                  <Input
                    type="name"
                    value={carga_horaria}
                    placeholder="Carga Horária"
                    onChange={(e) => setCarga_horaria(e.target.value)}
                    required
                  />
                  <Label>Insalubre:</Label>
                  <Select
                    value={insalubre}
                    onChange={(e) => {
                      setInsalubre(e.target.value);
                    }}
                    required
                  >
                    <option value={0}>Não</option>
                    <option value={1}>Sim</option>
                  </Select>
                </FormContent>
                <br />
                <Button type="submit">Atualizar</Button>
              </Form>
              <CloseModalButton
                onClick={() => {
                  setShowModal(!showModal);
                  // clear();
                }}
              />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
