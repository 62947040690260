import styled from "styled-components";

export const Background = styled.div`
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 80px);
  padding: 0.5rem calc((100vw - 1000px) / 2);
  background-color: #0093e9;
  background-image: linear-gradient(
    0deg,
    #0093e9 0%,
    #99d4f7 50%,
    #ffffff 100%
  );
  @media (max-height: 510px) {
    height: 100%;
  }
`;

export const Container = styled.div`
  margin: 55px 0 0 0;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 2rem 1rem;
  align-items: center;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  min-width: 289px;

  @media screen and (max-width: 1050px) {
    width: 95%;
  }

  th {
    @media screen and (max-width: 830px) {
      display: none;
    }
  }
`;

export const InputContainer = styled.div`
  display: grid;
  grid-template-areas:
    "p s"
    "t .";
  align-items: center;
  justify-items: center;
  margin-top: 20px;

  @media screen and (max-width: 854px) {
    display: flex;
    flex-direction: column;
  }
`;

export const InputLabel = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  width: 300px;

  label {
    margin-right: 15px;
    margin-left: 5px;
    margin-top: 10px;
  }

  select {
    height: 30px;
    border-radius: 5px;
    text-align: center;
    padding: 3px 7px;
  }

  input {
    height: 30px;
    border-radius: 5px;
    text-align: center;
    padding: 3px 7px;
    border: 1px solid black;
  }
`;

export const Hr = styled.div`
  background-color: #0093e9;
  height: 0.19rem;
  width: 100%;
  margin-top: 15px;
`;

export const Titulo = styled.h1`
  color: #0093e9;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 550px) {
    font-size: 28px;
  }
`;

export const Table = styled.div`
  margin-top: 15px;
  width: 99%;
  overflow: auto;
`;

export const Button = styled.button`
  width: 140px;
  height: 45px;
  border-radius: 4px;
  color: #fff;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;
  margin: 0 5px;

  &:hover {
    background-color: #1563cf;
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  width: 100%;
  margin-top: 35px;
  justify-content: center;
`;
