import styled from "styled-components";

export const Background = styled.div`
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  padding: 0.5rem calc((90vw - 1200px) / 2);
  background-color: #0093e9;
  background-image: linear-gradient(
    0deg,
    #0093e9 0%,
    #99d4f7 50%,
    #ffffff 100%
  );
`;

export const Container = styled.div`
  width: 98%;
  height: fit-content;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  min-width: 289px;

  @media (max-width: 830px) {
    width: 90%;
  }
`;

export const Hr = styled.div`
  background-color: #0093e9;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 0.2rem;
  width: 80%;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Titulo = styled.h1`
  color: #0093e9;
  text-align: center;

  @media (max-width: 550px) {
    font-size: 28px;
  }
`;

export const Button = styled.button`
  margin-right: 5px;
  width: 140px;
  height: 45px;
  border-radius: 4px;
  background: #2b74e0;
  color: #fff;
  border: 2px;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;

  &:hover {
    background-color: #1563cf;
  }
`;

export const Input = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;

  select {
    padding: 3px 7px;
    width: 250px;
    height: 45px;
    border-radius: 4px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 17px;
  }

  input {
    padding: 3px 7px;
    width: 250px;
    height: 45px;
    border-radius: 4px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 17px;
  }
`;

export const Table = styled.div`
  margin-top: 15px;
  overflow-x: auto;

  td {
    padding: 0 10px;
  }
`;
