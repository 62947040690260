import styled from "styled-components";
import { MdClose } from "react-icons/md";

export const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 23;
`;

export const Container = styled.div`
  min-width: 800px;
  height: 90%;
  margin: 0 auto;
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
`;

export const Hr = styled.div`
  background-color: #0093e9;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 0.2rem;
  width: 80%;
  top: 35vh;
  justify-content: center;
  align-items: center;
`;

export const Titulo = styled.h1`
  color: #0093e9;
  text-align: center;

  @media (max-width: 550px) {
    font-size: 28px;
  }
`;

export const Table = styled.div`
  margin-top: 15px;
  width: 99%;
  overflow: auto;

  td {
    text-align: center;

    @media (max-width: 830px) {
      text-align: right;
    }
  }
`;

export const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;
