import styled from "styled-components";

export const Background = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  box-sizing: border-box;
  -moz-box-sizing: border-box;

  @page {
    size: landscape;
    margin: 0;
  }
  @media print {
    width: 297mm;
    height: 210mm;
  }
`;

export const Page = styled.div`
  width: 297mm;
  height: 210mm;
  padding: 5mm;
  margin: 10mm auto;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  @media print {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
  }
`;

export const Subpage = styled.div`
  /* padding: 0.5cm; */
  /* border: 1px red solid; */
  /* height: 257mm; */
  height: 100%;
  /* overflow: hidden; */
  /* outline: 2cm #ffeaea solid; */

  .hidden {
    display: none;
  }
`;

export const P = styled.p`
  padding: 30px 0;
`;

export const Images = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  object-fit: cover;
  img {
    &:nth-child(1) {
      height: 60px;
    }

    &:nth-child(2) {
      height: 40px;
    }
  }
`;

export const Content = styled.div`
  width: 100%;

  p {
    font-size: 12px;
  }
`;

export const Title = styled.h1`
  font-size: 12px;
  text-align: center;
  padding: 4px 0;
`;

export const Table = styled.table`
  font-size: 8px;
  width: 100%;
  position: relative;
  text-align: center;
  z-index: 1000;
  table-layout: fixed;

  th {
    font-weight: normal;
    border: 0.1px solid #000;
    border-collapse: collapse;
    text-transform: uppercase;

    &:nth-child(n + 3):nth-child(-n + 18),
    &:nth-child(n + 22):nth-child(-n + 29) {
      background-color: #dcdcdc;
    }

    &:nth-child(n + 19):nth-child(-n + 21) {
      background-color: #a9a9a9;
    }
  }
  td {
    font-weight: normal;
    border: 0.1px solid #000;
    height: 20px !important;
    border-collapse: collapse;
    text-transform: uppercase;
  }

  .wrap {
    font-size: 8px;
    overflow-wrap: break-word;
    white-space: normal;
  }
`;

export const Assinaturas = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding-top: 40px;

  div {
    font-size: 12px;
  }
`;

export const THead = styled.div`
  /* border: 2px solid black; */
  font-size: 8px;
  width: 100%;
  position: relative;
  text-align: center;
  z-index: 1000;

  display: grid;
  grid-template-areas:
    "c1 c2"
    "c1 c3";

  .single {
    width: 34.42px;
  }

  .double {
    width: 68.85px;
  }

  div {
    font-weight: normal;
    border: 0.1px solid #000;
    border-collapse: collapse;
    text-transform: uppercase;

    &:nth-child(n + 3):nth-child(-n + 8),
    &:nth-child(n + 12):nth-child(-n + 19) {
      background-color: #dcdcdc;
    }

    &:nth-child(n + 9):nth-child(-n + 11) {
      background-color: #a9a9a9;
    }
  }

  .c1 {
    grid-area: c1;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .c2 {
    grid-area: c2;
    display: flex;
    width: 100%;

    div {
      &:nth-child(1) {
        width: 550.56px;
      }

      &:nth-child(2) {
        width: 103.23px;
      }

      &:nth-child(3) {
        width: 275.28px;
      }
    }
  }

  .c3 {
    grid-area: c3;
    display: flex;
    height: 100px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-size: 9px;
        font-family: Arial, Helvetica;
        transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform-origin: center;
      }
    }
  }
`;
