import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";

export const Background = styled.div`
  height: calc(100vh - 80px);
  @media (max-height: 550px) {
    height: 100%;
  }
  display: flex;
  padding: 0.5rem calc((100vw - 1000px) / 2);
  background-color: #0093e9;
  background-image: linear-gradient(
    0deg,
    #0093e9 0%,
    #99d4f7 50%,
    #ffffff 100%
  );
`;

export const Container = styled.div`
  margin: 100px auto auto 0;
  width: 100%;
  height: auto;
  background: #fff;
  padding: 2rem 1rem;
  align-items: center;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  min-width: 289px;
  @media (max-width: 830px) {
    margin: 30px auto auto 0;
  }
`;

export const Titulo = styled.div`
  h1 {
    color: #0093e9;
  }
`;

export const Hr = styled.div`
  background-color: #0093e9;
  height: 0.19rem;
  width: 92%;
  margin-top: 15px;
`;

export const Novo = styled(Link)`
  margin-top: 20px;
  position: absolute;
  width: 100px;
  height: 50px;
  border-radius: 4px;
  background: #2b74e0;
  padding: 13px;
  color: #fff;
  border: 2px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;
  &:hover {
    background-color: #1563cf;
  }
`;

export const Table = styled.div`
  margin-top: 100px;

  table {
    color: black;

    font-size: 15px;
    border: 2px solid whitesmoke;
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    @media (max-width: 830px) {
      margin-top: 10px;
      tbody {
        margin-bottom: 90px;
      }
    }
  }
`;

export const BigButton = styled.button`
  margin-right: 15px;
  width: 140px;
  height: 45px;
  border-radius: 4px;
  background: #2b74e0;
  color: #fff;
  border: 2px;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;
  &:hover {
    background-color: #1563cf;
  }
`;

export const CheckBox = styled.div`
  border: 2px solid var(--blue-color);
  width: 19px;
  height: 19px;
  border-radius: 25%;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Form = styled.div`
  margin-top: 10px;
  font-size: 18px;

  form {
    display: flex;
    flex-direction: column;
    gap: 15px 30px;

    input[type="date"],
    input[type="month"] {
      margin-left: 5px;
      height: 30px;
      width: 200px;
      border-radius: 5px;
      text-align: center;
      padding: 3px 7px;
    }
  }
`;

export const InputLabel = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;

  label {
    margin-top: 5px;
    margin-right: 5px;
    text-align: right;
    width: 180px;
  }

  input {
    height: 30px;
    width: 300px;
    border-radius: 5px;
    text-align: center;
    padding: 3px 7px;
  }

  select {
    height: 30px;
    width: 300px;
    border-radius: 5px;
    border-top: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid #767676;
    border-bottom: 2px solid #767676;
    text-align: center;
    -webkit-appearance: none;
    background: url("https://cdn-icons-png.flaticon.com/512/60/60995.png") 95.5%
      50% no-repeat;
    background-size: 14px;
    &:invalid {
      color: gray;
    }
  }

  b {
    margin-left: 5px;
  }

  @media (max-width: 950px) {
    label {
      width: 150px !important;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
