import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import {
  Background,
  Container,
  Titulo,
  Hr,
  Button,
  Table,
  Row,
  Input,
} from "./style";
import { IoPencilOutline, IoTrashOutline } from "react-icons/io5";
import Auth from "../../../services/auth";
import Pagination from "../../../components/Pagination";
import ModalEdit from "../../../components/Modal/Coord/EditLancamentoEventuais";
import { Link } from "react-router-dom";

export default function Eventuais() {
  const [id] = useState(Auth.id());
  const [page, setPage] = useState(1);
  const [lancamentos_eventuais, setLancamentos_eventuais] = useState([]);
  const [lancamento_eventual, setLancamento_eventual] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [showModalEdit, setShowModalEdit] = useState(false);

  useEffect(() => {
    api
      .get(`/coord/events?nome=${search}&idCoord=${id}`)
      .then((response) => {
        setLancamentos_eventuais(response.data.lancamentos_eventuais);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [id, search, showModalEdit]);

  useEffect(() => {
    setTotalPages(Math.ceil(lancamentos_eventuais.length / 15));
  }, [lancamentos_eventuais, showModalEdit]);

  function handleDelete(id) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "Você não poderá reverter essa ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Deletar",
      cancelButtonColor: "gray",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await api.delete(`/coord/events/${id}`);
        if (response.data.error)
          return Swal.fire(
            "Exclusão não permitida!",
            response.data.error,
            "warning"
          );
        Toast.fire({
          icon: "success",
          title: "Deletado com sucesso!",
        });
        setLancamentos_eventuais(
          lancamentos_eventuais.filter((lancamento) => lancamento.id !== id)
        );
      }
    });
  }

  return (
    <Background>
      <Container>
        <Titulo>Lançamentos Eventuais</Titulo>
        <Hr />
        <Row>
          <Link to="/eventuaiscoord">
            <Button>Novo</Button>
          </Link>
          <Input>
            <input
              value={search}
              onChange={(e) => {
                setSearch(e.target.value.toUpperCase());
                setPage(1);
              }}
              type="search"
              placeholder="Pesquisar..."
            />
          </Input>
        </Row>
        <Table>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Unidade</th>
                <th>Tipo</th>
                <th>C.C.</th>
                <th>Professor</th>
                <th>Curso</th>
                <th>Data início</th>
                <th>Data fim</th>
                <th>Qtde / Carga</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {lancamentos_eventuais
                .slice((page - 1) * 15, page * 15)
                .map((t, i) => (
                  <tr key={i}>
                    <td data-title="ID">{t.id}</td>
                    <td
                      title={t.unidade}
                      data-title="Unidade"
                      className="td-left"
                    >
                      {t.unidade.slice(0, 10)}
                      {t.unidade.length > 10 ? "..." : ""}
                    </td>
                    <td
                      data-title="Tipo"
                      title={t.tipo_lancamento}
                      className="td-left"
                    >
                      {t.tipo_lancamento.slice(0, 15)}
                    </td>
                    <td data-title="Centro Custo" title={t.centro_custo}>
                      {t.id_centro_custo}
                    </td>
                    <td
                      data-title="Professor"
                      title={t.professor}
                      className="td-left"
                    >
                      {t.professor.slice(0, 15)}
                      {t.professor.length > 15 ? "..." : ""}
                    </td>
                    <td data-title="Curso" className="td-left" title={t.curso}>
                      {t.curso.slice(0, 25)}
                      {t.curso.length > 25 ? "..." : ""}
                    </td>
                    <td data-title="Data início">{t.data_inicio}</td>
                    <td data-title="Data fim">{t.data_fim}</td>
                    <td data-title="Carga Horária">
                      {t.carga_horaria
                        ? t.carga_horaria
                        : t.quantidade_deslocamentos}
                    </td>
                    <td>
                      <IoPencilOutline
                        title="Editar"
                        style={{
                          fontSize: "25px",
                          cursor:
                            new Date().getMonth() + 1 ===
                            parseInt(t.data_inicio.split("/")[1])
                              ? "pointer"
                              : "unset",
                          color:
                            new Date().getMonth() + 1 ===
                            parseInt(t.data_inicio.split("/")[1])
                              ? "black"
                              : "gray",
                        }}
                        onClick={() => {
                          if (
                            new Date().getMonth() + 1 ===
                            parseInt(t.data_inicio.split("/")[1])
                          ) {
                            setShowModalEdit(true);
                            setLancamento_eventual(t);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <IoTrashOutline
                        title="Excluir"
                        style={{ fontSize: "25px", cursor: "pointer" }}
                        onClick={() => {
                          handleDelete(t.id);
                        }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Table>
        <ModalEdit
          showModal={showModalEdit}
          setShowModal={setShowModalEdit}
          data={lancamento_eventual}
        />
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Container>
    </Background>
  );
}
