import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";

export const NavContainer = styled.div`
  width: 100%;
  height: 80px;
  justify-content: center;
  display: ${(props) => (props.hidden ? "flex" : "none")};

  @media screen and (max-width: 854px) {
    justify-content: ${(props) => (props.open ? "center" : "flex-start")};
  }

  img {
    object-fit: contain;
    filter: drop-shadow(3px 5px 6px #121218);
  }
  .logo-out {
    margin-left: 20px;

    @media screen and (max-width: 854px) {
      display: ${(props) => (props.open ? "none" : "static")};
    }

    @media screen and (min-width: 854px) {
      display: none;
    }
  }

  .logo-in {
    @media screen and (max-width: 854px) {
      display: none;
    }
  }
`;

export const NavMain = styled.div`
  width: 1000px;
  padding: 0 15px;
  justify-content: space-between;
  display: flex;

  @media screen and (max-width: 854px) {
    padding-top: 50px;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    background: #dddddd;
    position: fixed;
    transform: ${(props) =>
      props.open ? "translateX(0)" : "translateX(-100%)"};
    transition: transform 200ms ease-in-out;
    z-index: 9;
    overflow: hidden;
  }
`;

export const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  color: var(--red-color);
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: bolder;
  height: 100%;
  cursor: pointer;
  transition: linear 0.2s;
  justify-content: center;
  text-decoration: none;

  &:hover {
    font-size: 1.4rem !important;
  }

  &.active {
    color: #151515;
    font-size: 1.6rem !important;
  }

  @media screen and (max-width: 854px) {
    height: fit-content;
    margin: 30px 0;
  }
`;

export const NavDropDown = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 0;
  height: fit-content;
  transition: max-height 0.3s;
  margin-top: 28px;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  position: relative;
  background: white;
  padding: 0 10px;
  padding-bottom: 10px;

  @media screen and (max-width: 854px) {
    margin-top: 30px;
    margin-bottom: 30px;
    justify-content: center;
  }

  .drop-icon {
    margin-left: 5px;
    transition: transform 0.3s ease;
  }

  &:hover {
    margin-top: 18px;
    padding-top: 10px;
    max-height: 100vh;
    transition-duration: 0.7s;
    background-color: white;

    .drop-icon {
      transition-duration: 0.7s;
      transform: rotate(90deg);
    }
  }
`;

export const DropDownButton = styled.div`
  color: var(--red-color);
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: bolder;
  margin-bottom: 10px;
  transition: linear 0.2s;

  &.active {
    color: #151515;
    font-size: 1.6rem !important;
  }

  &:hover {
    font-size: 1.4rem !important;
  }
`;

export const DropDownOption = styled.div`
  margin: 10px 0;
  font-weight: bolder;
  cursor: pointer;
  color: var(--red-color);
  transition: linear 0.2s;
  text-align: center;

  &:hover {
    font-size: 1.1rem !important;
    color: black;
  }
`;

export const DropDownList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
`;

export const NavButton = styled.button`
  border-radius: 4px;
  background: #e8343d;
  padding: 10px 22px;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
  height: 38px;
  font-weight: bolder;
  align-self: center;

  &:hover {
    background: #f8f8ff;
    color: #010606;
  }

  @media screen and (max-width: 854px) {
    margin: 20px 0;
  }
`;

export const Bars = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  position: ${(props) => (props.open ? "fixed" : "absolute")};
  top: 8px;
  right: 0;
  transform: translate(-80%, 80%);
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  cursor: pointer;
  display: none;
  z-index: 20;

  div {
    width: 1.5rem;
    height: 0.15rem;
    background: black;
    border-radius: 0.24rem;
    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-child(1) {
      transform: ${(props) => (props.open ? "rotate(45deg)" : "rotate(0)")};
    }

    &:nth-child(2) {
      transform-origin: right;
      transform: ${(props) => (props.open ? "scaleX(0)" : "scaleX(100%)")};
      opacity: ${(props) => (props.open ? 0 : 1)};
    }

    &:nth-child(3) {
      transform: ${(props) => (props.open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }

  @media screen and (max-width: 854px) {
    display: inherit;
  }
`;
