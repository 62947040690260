import styled from "styled-components";

export const Background = styled.div`
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  background-color: #0093e9;
  background-image: linear-gradient(
    0deg,
    #0093e9 0%,
    #99d4f7 50%,
    #ffffff 100%
  );
`;

export const Container = styled.div`
  margin: 60px 0 0 0;
  min-width: 80vw;
  width: fit-content;
  height: 100%;
  background: #fff;
  padding: 2rem 1rem;
  align-items: center;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  min-height: 479px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;

  @media screen and (max-width: 1050px) {
    width: 90%;
  }
`;

export const Titulo = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto auto;
  gap: 0 10px;

  h1 {
    display: flex;
    align-items: center;
    color: #0093e9;
  }
`;

export const Hr = styled.div`
  background-color: #0093e9;
  height: 0.19rem;
  width: 100%;
  margin-top: 15px;
`;

export const Form = styled.div`
  margin-top: 10px;
  font-size: 18px;

  form {
    display: grid;
    align-items: center;
    justify-content: left;
    gap: 0 10px;
    grid-template-areas:
      "p s q"
      "p t r";

    @media screen and (max-width: 800px) {
      justify-content: center;
      justify-items: center;
      grid-template-areas:
        "p"
        "s"
        "t"
        "q"
        "r";
    }
  }

  label {
    margin-left: 5px;
    width: 40px;
  }

  input {
    margin-right: 5px;
  }

  th {
    @media screen and (max-width: 830px) {
      display: none;
    }
  }
`;

export const InputLabel = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 10px 0;

  label {
    margin-right: 13px;
    margin-left: 5px;
  }

  input {
    height: 30px;
    width: 210px;
    border-radius: 5px;
    text-align: center;
    padding: 3px 7px;
  }

  select {
    height: 30px;
    width: 350px;
    border-radius: 5px;
    text-align: center;
    margin-left: 5px;
    -webkit-appearance: none;
    background: url("https://cdn-icons-png.flaticon.com/512/60/60995.png") 95.5%
      50% no-repeat;
    background-size: 14px;
    &:invalid {
      color: gray;
    }
  }
`;

export const Button = styled.button`
  margin-right: 5px;
  width: 100px;
  height: 30px;
  border-radius: 4px;
  background: #2b74e0;
  color: #fff;
  border: 2px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;
  &:hover {
    background-color: #1563cf;
  }
`;

export const BigButton = styled.button`
  font-weight: bolder;
  background: #2b74e0;
  border: none;
  border-radius: 0.25rem;
  padding: 15px 30px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.1);
  }
`;

export const Table = styled.div`
  margin-top: 15px;
  width: 99%;
  overflow: auto;
`;

export const Input = styled.input`
  margin-top: 15px;
  padding: 3px 7px;
  width: 250px;
  height: 45px;
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 17px;
`;