import React, { useState, useEffect } from "react";
import {
  Container,
  Hr,
  Titulo,
  Button,
  Input,
  Row,
  Background,
  Table,
} from "./style";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import Pagination from "../../../components/Pagination";
import ModalRegister from "../../../components/Modal/Rh/CentroCustos/Register";
import ModalEdit from "../../../components/Modal/Rh/CentroCustos/Edit";
import { IoPencilOutline, IoTrashOutline } from "react-icons/io5";
import { isAuthenticatedDev } from "../../../auth";

export default function CentroCusto() {
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [custos, setCustos] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [custo, setCusto] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    api
      .get(`/rh/costcenter?nome=${search}&dev=${isAuthenticatedDev()}`)
      .then((response) => {
        setCustos(response.data.centros_custo);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [search]);

  useEffect(() => {
    setTotalPages(Math.ceil(custos.length / 15));
  }, [custos]);

  async function handleDelete(id) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "O centro de custo será excluído permanentemente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Excluir",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await api.delete(`/rh/costcenter/${id}`);
        if (response.data.error) {
          return Swal.fire(
            "Exclusão não permitida!",
            response.data.error,
            "warning"
          );
        }
        Toast.fire({
          icon: "success",
          title: "Excluído com sucesso!",
        });
        setCustos(custos.filter((custo) => custo.id !== id));
      }
    });
  }

  return (
    <Background>
      <Container>
        <Titulo>Controle de Centros de Custo</Titulo>
        <Hr />
        <Row>
          <Button
            onClick={() => {
              setShowModalRegister(true);
            }}
          >
            Novo
          </Button>
          <Input>
            <input
              value={search}
              onChange={(e) => {
                setSearch(e.target.value.toUpperCase());
                setPage(1);
              }}
              type="search"
              placeholder="Pesquisar nome..."
            />
          </Input>
        </Row>
        <Table>
          <table>
            <thead>
              <tr>
                <th>Id</th>
                <th>Nome</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {custos.slice((page - 1) * 15, page * 15).map((c, i) => (
                <tr key={i}>
                  <td data-title="Id" value={c.id}>
                    {c.id}
                  </td>
                  <td data-title="Nome" value={c.nome} className="td-left">
                    {c.nome}
                  </td>
                  <td data-title="Editar">
                    <IoPencilOutline
                      title="Editar"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={() => {
                        setCusto(c);
                        setShowModalEdit(true);
                      }}
                    />
                  </td>
                  <td data-title="Excluir">
                    <IoTrashOutline
                      title="Excluir"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={async () => {
                        await handleDelete(c.id);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Table>
        <ModalRegister
          showModal={showModalRegister}
          setShowModal={setShowModalRegister}
        />
        <ModalEdit
          showModal={showModalEdit}
          setShowModal={setShowModalEdit}
          data={custo}
        />
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Container>
    </Background>
  );
}
