import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import {
  Background,
  Container,
  Titulo,
  Hr,
  Form,
  InputLabel,
  BigButton,
  CheckBox,
  ButtonContainer,
} from "./style.js";
import Auth from "../../../services/auth";
import GoBack from "../../../components/GoBack";
import { Link } from "react-router-dom";
import DataAtual from "../../../services/DataAtual";
import { IoMdCheckmark } from "react-icons/io";

export default function EventuaisCoord() {
  const history = useHistory();
  const [id] = useState(Auth.id());
  const [nomeCoord] = useState(Auth.nome());
  const [unidades, setUnidades] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [curso, setCurso] = useState("");
  const [unidade, setUnidade] = useState("");
  const [centros_custo, setCentros_custo] = useState([]);
  const [centro_custo, setCentro_custo] = useState(0);
  const [professores, setProfessores] = useState([]);
  const [professor, setProfessor] = useState("");
  const [tipos_lancamentos, setTipo_lancamentos] = useState([]);
  const [nomeTipoLancamento, setNomeTipoLancamento] = useState("");
  const [tipo_lancamento, setTipo_lancamento] = useState(0);
  const [data_inicio, setData_inicio] = useState(DataAtual());
  const [data_fim, setData_fim] = useState(DataAtual());
  const [carga_horaria, setCarga_horaria] = useState("");
  const [justificativa, setJustificativa] = useState("");
  const [valorIda, setValorIda] = useState(0);
  const [valorVolta, setValorVolta] = useState(0);
  const [origemDestino, setOrigemDestino] = useState("");
  const [deslocamentos, setDeslocamentos] = useState([]);
  const [valorIdaChecked, setValorIdaChecked] = useState(true);
  const [valorVoltaChecked, setValorVoltaChecked] = useState(true);
  const [quantidadeDeslocamentos, setQuantidadeDeslocamentos] = useState(1);
  const [repetirMes, setRepetirMes] = useState(false);

  useEffect(() => {
    api
      .get(`/coord/units?idCoord=${id}`)
      .then((response) => {
        setUnidades(response.data.unidades);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });

    api
      .get(`/coord/contributors?inativo=${false}`)
      .then((response) => {
        setProfessores(response.data.colaboradores);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [history, id]);

  useEffect(() => {
    api
      .get(`/coord/costcenter?idCoord=${id}&idUnidade=${unidade}`)
      .then((response) => {
        setCentros_custo(response.data.centros_custo);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [history, id, unidade]);

  useEffect(() => {
    api
      .get(
        `/coord/tipos?id_centro_custo=${centro_custo}&matricula_colaborador=${professor}`
      )
      .then((response) => {
        setTipo_lancamentos(
          response.data.tipos_lancamentos.filter(
            (v) => !v?.nome?.includes("HORAS NORMAIS")
          )
        );
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [centro_custo, history, professor]);

  useEffect(() => {
    api
      .get(
        `/coord/courses?idCoord=${id}&idUnidade=${unidade}&idCentroCusto=${centro_custo}`
      )
      .then((response) => {
        setCursos(response.data.cursos);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [unidade, centro_custo, id, history]);

  useEffect(() => {
    setValorIda(0);
    setValorVolta(0);
    setQuantidadeDeslocamentos(1);
    setOrigemDestino("");
    api
      .get(`/coord/deslocamentos?id_centro_custo=${centro_custo}`)
      .then((response) => {
        setDeslocamentos(response.data.deslocamentos);
        if (response.data.deslocamentos[0]) {
          setValorIda(response.data.deslocamentos[0].valor_ida);
          setValorVolta(response.data.deslocamentos[0].valor_volta);
          setOrigemDestino(response.data.deslocamentos[0].id);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [centro_custo]);

  async function handleSubmit(e) {
    e.preventDefault();

    if (data_fim < data_inicio)
      return Swal.fire(
        "Atenção!",
        "A data final NÃO PODE ser anterior a data de início",
        "warning"
      );

    if (
      repetirMes &&
      Math.abs(
        parseInt(data_fim.split("-")[1]) - parseInt(data_inicio.split("-")[1])
      ) < 1 &&
      parseInt(data_fim.split("-")[0]) - parseInt(data_inicio.split("-")[0]) < 1
    )
      return Swal.fire(
        "Atenção!",
        "Para repetir durante os meses, é necessário um período maior!",
        "warning"
      );

    // if (data_inicio.split("-")[1] < new Date().getMonth() + 1)
    //   return Swal.fire(
    //     "Atenção!",
    //     "Não é possível cadastrar um eventual de um mês passado!",
    //     "warning"
    //   );

    const data = {
      id_unidade: unidade,
      id_tipo_lancamento: tipo_lancamento,
      id_curso: curso,
      id_centro_custo: centro_custo,
      id_colaborador: professor,
      data_inicio: data_inicio,
      data_fim: data_fim,
      repetir_mes: repetirMes,
      carga_horaria: nomeTipoLancamento.toLowerCase().includes("deslocamento")
        ? undefined
        : carga_horaria,
      justificativa: nomeTipoLancamento.toLowerCase().includes("deslocamento")
        ? undefined
        : justificativa,
      tem_ida: valorIdaChecked,
      tem_volta: valorVoltaChecked,
      valor_ida: valorIdaChecked ? valorIda : 0,
      valor_volta: valorVoltaChecked ? valorVolta : 0,
      qtde: nomeTipoLancamento.toLowerCase().includes("deslocamento")
        ? quantidadeDeslocamentos
        : undefined,
      id_cidade_origem: deslocamentos.filter((d) => d.id === origemDestino)[0]
        ? deslocamentos.filter((d) => d.id === origemDestino)[0]
            .id_cidade_origem
        : undefined,
      id_cidade_destino: deslocamentos.filter((d) => d.id === origemDestino)[0]
        ? deslocamentos.filter((d) => d.id === origemDestino)[0]
            .id_cidade_destino
        : undefined,
    };

    try {
      if (nomeTipoLancamento.toLowerCase().includes("deslocamento")) {
        if (!valorIdaChecked && !valorVoltaChecked) {
          return Swal.fire({
            title: "Atenção!",
            text: "É necessário inserir o valor de ida e/ou volta!",
            icon: "warning",
            confirmButtonColor: "var(--blue-color)",
          });
        }
      }
      const response = await api.post(`/coord/events`, {
        data,
      });
      if (response.data.error)
        return Swal.fire({
          title: "Atenção!",
          text: response.data.error,
          icon: "warning",
        });
      Toast.fire({
        icon: "success",
        title: "Lançamento gerado com sucesso!",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      alert(error);
    }
  }

  useEffect(() => {
    setValorIda(
      deslocamentos.filter((d) => d.id === origemDestino)[0]
        ? deslocamentos.filter((d) => d.id === origemDestino)[0].valor_ida
        : 0
    );
    setValorVolta(
      deslocamentos.filter((d) => d.id === origemDestino)[0]
        ? deslocamentos.filter((d) => d.id === origemDestino)[0].valor_volta
        : 0
    );
  }, [deslocamentos, origemDestino]);

  function addZero(value) {
    return parseInt(value, 10) < 10 ? "0" + value : value;
  }

  useEffect(() => {
    let hoje = new window.Date();
    var ultimoDia = new window.Date(hoje.getFullYear(), hoje.getMonth() + 1, 0);
    if (nomeTipoLancamento.toLowerCase().includes("deslocamento")) {
      setData_inicio(
        `${hoje.getFullYear()}-${addZero(hoje.getMonth() + 1)}-01`
      );
      setData_fim(
        `${hoje.getFullYear()}-${addZero(hoje.getMonth() + 1)}-${addZero(
          ultimoDia.getDate()
        )}`
      );
    } else {
      setData_inicio(
        `${hoje.getFullYear()}-${addZero(hoje.getMonth() + 1)}-${addZero(
          hoje.getDate()
        )}`
      );
      setData_fim(
        `${hoje.getFullYear()}-${addZero(hoje.getMonth() + 1)}-${addZero(
          hoje.getDate()
        )}`
      );
    }
  }, [nomeTipoLancamento]);

  useEffect(() => {
    if (!repetirMes) {
      setData_inicio(
        new Date()
          .toLocaleDateString("en-ZA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replaceAll("/", "-")
      );
      setData_fim(
        new Date()
          .toLocaleDateString("en-ZA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replaceAll("/", "-")
      );
    }
  }, [repetirMes]);

  return (
    <Background>
      <GoBack />
      <Container>
        <Titulo style={{ display: "flex" }}>
          <h1>Lançamentos Eventuais</h1>
          <legend>{nomeCoord}</legend>
        </Titulo>
        <Hr />
        <Form>
          <form onSubmit={handleSubmit}>
            <InputLabel>
              <label>Unidade</label>
              <select
                value={unidade}
                onChange={(e) => setUnidade(e.target.value)}
                required
              >
                <option disabled value="">
                  Selecione a unidade
                </option>
                {unidades.map((u, i) => (
                  <option key={i} value={u.id}>
                    {u.nome}
                  </option>
                ))}
              </select>
            </InputLabel>
            <InputLabel>
              <label>Centro custo</label>
              <select
                value={centro_custo}
                onChange={(e) => {
                  setCentro_custo(e.target.value);
                }}
                required
              >
                <option disabled value={0}>
                  Selecione o centro de custo
                </option>
                {centros_custo.map((c, i) => (
                  <option key={i} value={c.id}>
                    {c.nome}
                  </option>
                ))}
              </select>
            </InputLabel>
            <InputLabel>
              <label>Professor</label>
              <select
                value={professor}
                onChange={(e) => setProfessor(e.target.value)}
                required
              >
                <option disabled value="">
                  Selecione o professor
                </option>
                {professores.map((p, i) => (
                  <option key={i} value={p.matricula}>
                    {p.nome}
                  </option>
                ))}
              </select>
            </InputLabel>
            <InputLabel>
              <label>Tipo de lançamento</label>
              <select
                value={nomeTipoLancamento}
                onChange={(e) => {
                  setNomeTipoLancamento(e.target.value);
                  setTipo_lancamento(
                    e.target[e.target.options.selectedIndex].id
                  );
                }}
                required
              >
                <option disabled value="">
                  Selecione o lançamento
                </option>
                {tipos_lancamentos.map((t, i) => (
                  <option key={i} id={t.id}>
                    {t.nome}
                  </option>
                ))}
              </select>
            </InputLabel>
            <InputLabel
              style={{
                display: nomeTipoLancamento
                  .toLowerCase()
                  .includes("deslocamento")
                  ? ""
                  : "none",
              }}
            >
              <label>Origem / Destino</label>
              <select
                value={origemDestino}
                onChange={(e) => setOrigemDestino(parseInt(e.target.value))}
                required={nomeTipoLancamento
                  .toLowerCase()
                  .includes("deslocamento")}
              >
                <option disabled value="">
                  Selecione a origem/destino
                </option>
                {deslocamentos.map((d, i) => (
                  <option key={i} value={d.id}>
                    {d.cidade_origem + " / " + d.cidade_destino}
                  </option>
                ))}
              </select>
            </InputLabel>
            <InputLabel
              style={{
                display: nomeTipoLancamento
                  .toLowerCase()
                  .includes("deslocamento")
                  ? ""
                  : "none",
              }}
            >
              <label>Valor de ida</label>
              <CheckBox
                onClick={() => {
                  setValorIdaChecked(!valorIdaChecked);
                }}
              >
                <IoMdCheckmark
                  style={{
                    transition: "all 0.2s ease-in-out",
                  }}
                  color={valorIdaChecked ? "var(--blue-color)" : "transparent"}
                />
              </CheckBox>
              <input
                style={{ width: "80px" }}
                type="number"
                value={valorIda}
                onChange={(e) => setValorIda(e.target.value)}
                // disabled={!valorIdaChecked}
                disabled
              />
            </InputLabel>
            <InputLabel
              style={{
                display: nomeTipoLancamento
                  .toLowerCase()
                  .includes("deslocamento")
                  ? ""
                  : "none",
              }}
            >
              <label>Valor de volta</label>
              <CheckBox
                onClick={() => {
                  setValorVoltaChecked(!valorVoltaChecked);
                }}
              >
                <IoMdCheckmark
                  style={{
                    transition: "all 0.2s ease-in-out",
                  }}
                  color={
                    valorVoltaChecked ? "var(--blue-color)" : "transparent"
                  }
                />
              </CheckBox>
              <input
                style={{ width: "80px" }}
                type="number"
                value={valorVolta}
                onChange={(e) => setValorVolta(e.target.value)}
                // disabled={!valorVoltaChecked}
                disabled
              />
            </InputLabel>
            <InputLabel
              style={{
                display: nomeTipoLancamento
                  .toLowerCase()
                  .includes("deslocamento")
                  ? ""
                  : "none",
              }}
            >
              <label>Quantidade</label>
              <input
                style={{ width: "80px" }}
                type="number"
                value={quantidadeDeslocamentos}
                onChange={(e) => setQuantidadeDeslocamentos(e.target.value)}
                required
              />
            </InputLabel>
            <InputLabel>
              <label>Curso</label>
              <select
                value={curso}
                onChange={(e) => setCurso(e.target.value)}
                required
              >
                <option disabled value="">
                  Selecione o curso
                </option>
                {cursos.map((c, i) => (
                  <option key={i} value={c.id}>
                    {c.curso}
                  </option>
                ))}
              </select>
            </InputLabel>
            <br />
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <div>
                <label>Início</label>
                <input
                  type={repetirMes ? "month" : "date"}
                  value={repetirMes ? data_inicio.slice(0, 7) : data_inicio}
                  onChange={(e) => {
                    if (repetirMes) {
                      setData_inicio(
                        e.target.value + "-" + new Date().getDate()
                      );
                      setData_fim(e.target.value + "-" + new Date().getDate());
                    } else {
                      setData_inicio(e.target.value);
                      setData_fim(e.target.value);
                    }
                  }}
                  // disabled={nomeTipoLancamento
                  //   .toLowerCase()
                  //   .includes("deslocamento")}
                />
              </div>
              <div>
                <label>Fim</label>
                <input
                  type={repetirMes ? "month" : "date"}
                  value={repetirMes ? data_fim.slice(0, 7) : data_fim}
                  onChange={(e) => {
                    if (repetirMes) {
                      setData_fim(e.target.value + "-" + new Date().getDate());
                    } else {
                      setData_fim(e.target.value);
                    }
                  }}
                  // disabled={nomeTipoLancamento
                  //   .toLowerCase()
                  //   .includes("deslocamento")}
                />
              </div>
              <div style={{ display: "flex" }}>
                <CheckBox
                  onClick={() => {
                    setRepetirMes(!repetirMes);
                  }}
                >
                  <IoMdCheckmark
                    style={{
                      transition: "all 0.2s ease-in-out",
                    }}
                    color={repetirMes ? "var(--blue-color)" : "transparent"}
                  />
                </CheckBox>
                Repetir por mês
              </div>
            </div>
            <InputLabel
              style={{
                display: nomeTipoLancamento
                  .toLowerCase()
                  .includes("deslocamento")
                  ? "none"
                  : "",
              }}
            >
              <label>
                Carga horária
                <b>
                  <u>mensal</u>
                </b>
              </label>
              <input
                style={{
                  width: "100px",
                  cursor: "pointer",
                  textAlign: "center",
                }}
                type="number"
                id=""
                value={carga_horaria}
                onChange={(e) => setCarga_horaria(e.target.value)}
              />
            </InputLabel>
            <InputLabel
              style={{
                display: nomeTipoLancamento
                  .toLowerCase()
                  .includes("deslocamento")
                  ? "none"
                  : "",
              }}
            >
              <label>Justificativa</label>
              <input maxLength={100}
                style={{ width: "500px", marginRight:"-130px" }}
                type="textarea"
                value={justificativa}
                onChange={(e) => setJustificativa(e.target.value)}
                id=""
              /><label style={{ fontSize: "10pt"}}>{justificativa.length}/100</label>
            </InputLabel>
            <br />
            <ButtonContainer>
              <BigButton>Salvar</BigButton>
              <Link to="/eventuais">
                <BigButton style={{ backgroundColor: "var(--red-color)" }}>
                  Cancelar
                </BigButton>
              </Link>
            </ButtonContainer>
          </form>
        </Form>
      </Container>
    </Background>
  );
}
