import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import {
  Background,
  Container,
  Titulo,
  Hr,
  Button,
  Form,
  InputLabel,
  InputLabel2,
} from "../Fechamento/style.js";
import Auth from "../../../services/auth";
import { Swal, Toast } from "../../../services/SwalTemplates";
import TimePicker from "react-time-picker";
import { Link } from "react-router-dom";

export default function LancamentoTurma() {
  const [id] = useState(Auth.id());
  const [nomeCoord] = useState(Auth.nome());
  const [unidades, setUnidades] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [unidade, setUnidade] = useState("");
  const [curso, setCurso] = useState("");
  const [professores, setProfessores] = useState([]);
  const [tipos_lancamentos, setTipo_lancamentos] = useState([]);
  const [value, onChange] = useState("10:00");

  useEffect(() => {
    api
      .get(`/coord/units`)
      .then((response) => {
        setUnidades(response.data.unidades);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/courses`)
      .then((response) => {
        setCursos(response.data.cursos);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/contributors?`)
      .then((response) => {
        setProfessores(response.data.colaboradores);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/releases`)
      .then((response) => {
        setTipo_lancamentos(response.data.tipos_lancamentos);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    const data = {
      id_usuario: id,
      id_curso: curso,
    };

    try {
      const response = await api.post(`/coord/fechamentos`, {
        data,
      });
      if (response.data.error) return alert(response.data.error);
      Toast.fire({
        icon: "success",
        title: "Fechamento gerado com sucesso!",
      });
    } catch (error) {
      alert(error);
    }
  }

  return (
    <Background>
      <Container>
        <Titulo>
          <h1>Lançamentos Curso Técnico/Tecnólogo </h1>
          <legend>{nomeCoord}</legend>
        </Titulo>
        <Hr />
        <Form>
          {/* <form onSubmit={handleSubmit}> */}
          <form onSubmit={handleSubmit}>
            <InputLabel>
              <label>Unidade</label>
              <select
                value={unidade}
                onChange={(e) => setUnidade(e.target.value)}
                required
              >
                <option selected disabled value="">
                  Selecione a unidade
                </option>
                {unidades.map((u, i) => (
                  <option key={i} value={u.id}>
                    {u.nome}
                  </option>
                ))}
              </select>
            </InputLabel>
            <InputLabel>
              <label>Curso</label>
              <select
                value={curso}
                onChange={(e) => setCurso(e.target.value)}
                required
              >
                <option selected disabled value="">
                  Selecione o curso
                </option>
                {tipos_lancamentos.map((t, i) => (
                  <option key={i} value={t.id}>
                    {t.nome}
                  </option>
                ))}
              </select>
            </InputLabel>
            <InputLabel>
              <label>Centro de custo</label>
              <select
                value={curso}
                onChange={(e) => setCurso(e.target.value)}
                required
              >
                <option selected disabled value="">
                  Selecione o centro de custo
                </option>
                {cursos.map((c, i) => (
                  <option key={i} value={c.id}>
                    {c.nome}
                  </option>
                ))}
              </select>
            </InputLabel>
            <InputLabel>
              <label>Turma</label>
              <select
                value={curso}
                onChange={(e) => setCurso(e.target.value)}
                required
              >
                <option selected disabled value="">
                  Selecione a turma
                </option>
                {professores.map((p, i) => (
                  <option key={i} value={p.id}>
                    {p.nome}
                  </option>
                ))}
              </select>
            </InputLabel>
            <br />
            <label>Início</label>
            <input type="date" name="date" id="" />
            <label>Fim</label>
            <input type="date" name="date" id="" />
            <br />
            <br />
            <InputLabel>
              <InputLabel2 style={{ width: "180px", marginLeft: "5px" }}>
                <InputLabel>
                  <label>Componente curricular</label>
                  <Button style={{ width: "30px" }}>+</Button>
                </InputLabel>
                <select
                  value={curso}
                  onChange={(e) => setCurso(e.target.value)}
                  required
                  style={{ width: "180px" }}
                >
                  <option selected disabled value="">
                    Selecione
                  </option>
                  {professores.map((p, i) => (
                    <option key={i} value={p.id}>
                      {p.nome}
                    </option>
                  ))}
                </select>
              </InputLabel2>
              <InputLabel2>
                <label>Professor</label>
                <br />
                <select
                  value={curso}
                  onChange={(e) => setCurso(e.target.value)}
                  required
                  style={{ width: "180px" }}
                >
                  <option selected disabled value="">
                    Selecione a turma
                  </option>
                  {professores.map((p, i) => (
                    <option key={i} value={p.id}>
                      {p.nome}
                    </option>
                  ))}
                </select>
              </InputLabel2>
              <InputLabel2>
                <label>Horário das aulas</label>
                <br />
                <InputLabel>
                  <select
                    value={curso}
                    onChange={(e) => setCurso(e.target.value)}
                    required
                    style={{ width: "100px" }}
                  >
                    <option selected disabled value="">
                      SEG
                    </option>
                    <option value="SEG">SEG</option>
                    <option value="TER">TER</option>
                    <option value="QUA">QUA</option>
                    <option value="QUI">QUI</option>
                    <option value="SEX">SEX</option>
                    <option value="SAB">SAB</option>
                  </select>
                  <TimePicker
                    style={{ width: "10px", marginLeft: "4px" }}
                    onChange={onChange}
                    value={value}
                  />
                  <TimePicker
                    style={{ width: "10px", marginLeft: "4px" }}
                    onChange={onChange}
                    value={value}
                  />
                  <Link to="/trocaprofessordetalhe">
                    <Button style={{ width: "30px", marginLeft: "4px" }}>
                      T
                    </Button>
                  </Link>
                </InputLabel>
              </InputLabel2>
            </InputLabel>
            <br />
            <Button>Salvar</Button>
            <Button>Cancelar</Button>
          </form>
        </Form>
      </Container>
    </Background>
  );
}
