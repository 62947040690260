import { IconBack, IconDownload } from "./style";
import { useHistory } from "react-router-dom";

export default function GoBackPdf() {
  const history = useHistory();

  return (
    <>
      <IconBack title="Voltar" onClick={() => history.goBack()} />
      <IconDownload title="Baixar" onClick={() => window.print()} />
    </>
  );
}
