import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  @media (min-width: 830px) {
    height: calc(100vh - 80px);
  }
  @media (max-height: 550px) {
    height: 100%;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 80px);
  &:nth-child(1) {
    /* background: linear-gradient(90deg, rgba(63,58,148,1) 0%, rgba(0,212,255,1) 100%); */
    background-color: #0093e9;
    background-image: linear-gradient(
      0deg,
      #0093e9 0%,
      #99d4f7 50%,
      #ffffff 100%
    );
  }

  &:nth-child(2) {
    background-color: #0093e9;
    background-image: linear-gradient(
      0deg,
      #0093e9 0%,
      #99d4f7 50%,
      #ffffff 100%
    );
  }
`;

export const Hr = styled.div`
  background-color: #0093e9;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 0.2rem;
  width: 80%;
  top: 35vh;
  justify-content: center;
  align-items: center;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Titulo = styled.h1`
  color: #0093e9;
  text-align: center;

  @media (max-width: 550px) {
    font-size: 28px;
  }
`;

export const Button = styled.button`
  margin-right: 5px;
  width: 140px;
  height: 45px;
  border-radius: 4px;
  background: #2b74e0;
  color: #fff;
  border: 2px;
  outline: none;
  cursor: pointer;

  font-weight: bold;
  font-size: 17px;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;
  &:hover {
    background-color: #1563cf;
  }
`;

export const Input = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;

  select {
    padding: 3px 7px;
    width: 250px;
    height: 45px;
    border-radius: 4px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 17px;
  }

  input {
    padding: 3px 7px;
    width: 250px;
    height: 45px;
    border-radius: 4px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 17px;
  }
`;

export const MainDiv = styled.div`
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  /* margin-bottom: 5px; */
  background-color: white;
  border-radius: 0.5rem;
  padding: 20px;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1500px) {
    width: 98%;
  }

  @media screen and (max-height: 932px) {
    margin-bottom: -150px;
  }

  @media screen and (max-height: 743px) {
    margin-bottom: -300px;
  }
`;

export const Div = styled.div`
  margin-top: 20px;
  width: 700px;
  height: auto;
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  background-color: white;
  border: 1px solid gray;
`;

export const Table = styled.div`
  margin-top: 15px;
  width: 99%;
  overflow: auto;

  td {
    padding: 0 10px;
  }
`;
