import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
  Input,
  Select,
  Label,
} from "./style";
import api from "../../../../services/api";
import Swal from "sweetalert2";

export default function ModalRegister({
  showModal,
  setShowModal,
  data,
  idTurma,
  idCurso,
  idCentro_custo,
  dataInicio,
  dataFim,
}) {
  const modalRef = useRef();
  const [disciplina, setDisciplina] = useState("");
  const [idDisciplina, setIdDisciplina] = useState(0);
  const [idProfessorNovo, setIdProfessorNovo] = useState(0);
  const [idProfessorAntigo, setIdProfessorAntigo] = useState(0);
  const [professores, setProfessores] = useState([]);
  const [horario_inicio, setHorario_inicio] = useState("");
  const [horario_fim, setHorario_fim] = useState("");
  const [id, setId] = useState("");
  const [dia_semana, setDia_semana] = useState("");
  const [professorAntigo, setProfessorAntigo] = useState("");
  const [professorNovo, setProfessorNovo] = useState("");
  const [idLancamentoGrade, setIdLancamentoGrade] = useState(0);
  const [dataInicioNovo, setDataInicioNovo] = useState("");
  const [dataFimAntigo, setDataFimAntigo] = useState("");
  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    api
      .get(`/coord/contributors?inativo=${false}`)
      .then((response) => {
        setProfessores(response.data.colaboradores);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  useEffect(() => {
    if (data) {
      setId(data?.id);
      setDisciplina(data?.disciplina);
      setIdDisciplina(data.id_disciplina);
      setProfessorAntigo(data.colaborador);
      setIdProfessorAntigo(data.matricula_colaborador);
      setHorario_inicio(data.horario_inicio);
      setHorario_fim(data.horario_fim);
      setIdProfessorAntigo(data.matricula_colaborador);
      setIdProfessorNovo(data.matricula_colaborador);
      setDia_semana(data.dia_semana);
      setIdLancamentoGrade(data.id_lancamento_grade);
    }
  }, [data]);

  async function handleSubmit(e) {
    e.preventDefault();

    // inverte formato da data - 16/02/2023
    const m = dataInicio.split("/");
    dataInicio = m[2]+'-'+m[1]+'-'+m[0];

    if (new Date(dataInicioNovo) <= new Date(dataInicio))
      return Swal.fire({
        title: "Atenção!",
        text: "A data início deve ser posterior à da turma!",
        icon: "warning",
        confirmButtonColor: "var(--blue-color)",
      });

    const data = {
      id: id,
      id_curso: idCurso,
      id_turma: idTurma,
      id_disciplina: idDisciplina,
      id_centro_custo: idCentro_custo,
      matricula_colaborador_antigo: idProfessorAntigo.toString(),
      matricula_colaborador_novo: idProfessorNovo,
      dia_semana: dia_semana,
      horario_inicio: horario_inicio,
      id_lancamento_grade: idLancamentoGrade,
      horario_fim: horario_fim,
      data_inicio_novo: dataInicioNovo,
      data_fim_antigo: dataFimAntigo,
      data_inicio:
        dataInicio.split("/")[2] +
        "-" +
        dataInicio.split("/")[1] +
        "-" +
        dataInicio.split("/")[0],
      data_fim:
        dataFim.split("/")[2] +
        "-" +
        dataFim.split("/")[1] +
        "-" +
        dataFim.split("/")[0],
    };
    try {
      const response = await api.patch(`/coord/trocaprofessor`, {
        data,
      });
      if (response.data.error) {
        return Swal.fire("Atenção!", response.data.error, "warning");
      }
      Toast.fire({
        icon: "success",
        title: "Editado com sucesso!",
      });
      setShowModal(!showModal);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      alert(
        error
      ); /*Swal('Atenção!', 'Serviço indisponível no momento', 'error')*/
    }
  }

  function addZero(value) {
    return parseInt(value, 10) < 10 ? "0" + value : value;
  }

  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              setShowModal(!showModal);
            }
          }}
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form onSubmit={handleSubmit}>
                <h3>Trocar Professor</h3>
                <FormContent>
                  <Label>Disciplina:</Label>
                  <Input
                    type="text"
                    value={disciplina}
                    onChange={(e) => {
                      setDisciplina(e.target.value);
                      // Se for ficar input, trazer id para o modal e setar no useEffect antes de enviar
                      // setIdUnidade(e.target[e.target.options.selectedIndex].id);
                    }}
                    disabled
                  />
                  <Label>Professor Atual:</Label>
                  <Select
                    value={professorAntigo}
                    onChange={(e) => {
                      setProfessorAntigo(e.target.value);
                      setIdProfessorAntigo(
                        e.target[e.target.options.selectedIndex].id
                      );
                    }}
                    disabled
                  >
                    {professores.map((p, i) => (
                      <option id={p.matricula} key={i}>
                        {p.nome}
                      </option>
                    ))}
                  </Select>
                  <Label>Novo Professor:</Label>
                  <Select
                    value={professorNovo}
                    onChange={(e) => {
                      setProfessorNovo(e.target.value);
                      setIdProfessorNovo(
                        e.target[e.target.options.selectedIndex].id
                      );
                    }}
                    required
                  >
                    <option disabled value="">
                      Selecione o professor
                    </option>
                    {professores.map((p, i) => (
                      <option id={p.matricula} key={i}>
                        {p.nome}
                      </option>
                    ))}
                  </Select>
                  <Label>Data Início</Label>
                  <Input
                    type="date"
                    value={dataInicioNovo}
                    required
                    onChange={(e) => {
                      let date = new Date(e.target.value);
                      setDataFimAntigo(
                        `${date.getFullYear()}-${addZero(
                          date.getMonth() + 1
                        )}-${addZero(date.getDate())}`
                      );
                      setDataInicioNovo(e.target.value);
                    }}
                  />
                  <Label>Horário Início:</Label>
                  <Input
                    type="time"
                    value={horario_inicio}
                    onChange={(e) => {
                      setHorario_inicio(e.target.value);
                      // setIdUnidade(e.target[e.target.options.selectedIndex].id);
                    }}
                    disabled
                  />
                  <Label>Horário Fim:</Label>
                  <Input
                    type="time"
                    value={horario_fim}
                    onChange={(e) => {
                      setHorario_fim(e.target.value);
                      // setIdUnidade(e.target[e.target.options.selectedIndex].id);
                    }}
                    disabled
                  />
                  {/* <Label>Unidade:</Label>
                                    <Select
                                        type="name"
                                        value={unidade}
                                        placeholder="unidade"
                                        id='unidade'
                                        onChange={(e) => {
                                            setUnidade(e.target.value)
                                            setIdUnidade(e.target[e.target.options.selectedIndex].id);
                                        }}
                                        required
                                    >
                                        {unidades.map((u,i) => (
                                            <option
                                                id={u.id}
                                            >{u.nome}</option>
                                        ))}
                                    </Select>
                                    <Label>Curso:</Label>
                                    <Select
                                        value={curso}
                                        id='curso'
                                        onChange={(e) => {
                                            setCurso(e.target.value)
                                            setIdCurso(e.target[e.target.options.selectedIndex].id);
                                        }}
                                        required
                                    >
                                        {cursos.map((c,i) => (
                                            <option
                                                id={c.id}
                                            >{c.curso}</option>
                                        ))}
                                    </Select>
                                    <Label>Centro de Custo:</Label>
                                    <Select
                                        type="centro_custo"
                                        value={centro_custo}
                                        id='centro_custo'
                                        placeholder="centro_custo"
                                        onChange={(e) => {
                                            setCentro_custo(e.target.value)
                                            setIdCentro_custo(e.target[e.target.options.selectedIndex].id);
                                        }}
                                        required
                                    >
                                        {centros_custo.map((c,i) => (
                                            <option
                                                id={c.id}
                                            >{c.nome}</option>
                                        ))}
                                    </Select>
                                    <Label>Módulo:</Label>
                                    <Select
                                        value={modulo}
                                        onChange={e => setModulo(e.target.value)}
                                        required
                                    >
                                        <option selected disabled value=''>Selecione o módulo</option>
                                        <option value='MOD I'>MÓDULO I</option>
                                        <option value='MOD II'>MÓDULO II</option>
                                        <option value='MOD III'>MÓDULO III</option>
                                        <option value='MOD IV'>MÓDULO IV</option>
                                        <option value='MOD V'>MÓDULO V</option>
                                        <option value='MOD VI'>MÓDULO VI</option>
                                        <option value='MOD VII'>MÓDULO VII</option>
                                        <option value='MOD VIII'>MÓDULO VIII</option>
                                        <option value='MOD IX'>MÓDULO IX</option>
                                        <option value='MOD X'>MÓDULO X</option>
                                    </Select>
                                    <Label>Turno:</Label>
                                    <Select
                                        value={turno}
                                        onChange={(e) => {
                                            setTurno(e.target.value)
                                            setIdTurno(e.target[e.target.options.selectedIndex].id);
                                        }}
                                        required
                                    >
                                        {turnos.map((t,i) => (
                                            <option
                                                id={t.id}
                                            >{t.nome}</option>
                                        ))}
                                    </Select>
                                    <Label>Ano/Semestre:</Label>
                                    <Select
                                        type="text"
                                        value={ano_semestre}
                                        onChange={e => setAno_semestre(e.target.value)}
                                        required
                                    >
                                        {AnoSemestre().map((a,i) => (
                                            <option 
                                                key={i}
                                            >{a}</option>
                                        ))}
                                    </Select>
                                    <Label>Início:</Label>
                                    <Input
                                        type="date"
                                        value={inicio}
                                        placeholder="Início"
                                        onChange={(e) => setInicio(e.target.value)}
                                        required
                                    />
                                    <Label>Fim:</Label>
                                    <Input
                                        type="date"
                                        value={fim}
                                        placeholder="Fim"
                                        onChange={(e) => setFim(e.target.value)}
                                        required
                                    /> */}
                </FormContent>
                <br />
                <Button type="submit">Atualizar</Button>
              </Form>
              <CloseModalButton
                onClick={() => {
                  setShowModal(!showModal);
                }}
              />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
