import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
  Input,
  Select,
  Label,
} from "../../ModalEditStyle";
import api from "../../../../services/api";
import Swal from "sweetalert2";
import AnoSemestre from "../../../../services/AnoSemestre";

export default function ModalRegister({ showModal, setShowModal, data }) {
  const modalRef = useRef();
  const [idUnidade, setIdUnidade] = useState(0);
  const [idCentro_custo, setIdCentro_custo] = useState(0);
  const [idTurno, setIdTurno] = useState(0);
  const [centro_custo, setCentro_custo] = useState("");
  const [modulo, setModulo] = useState("");
  const [turnos, setTurnos] = useState([]);
  const [turno, setTurno] = useState("");
  const [inicio, setInicio] = useState("");
  const [fim, setFim] = useState("");
  const [id, setId] = useState("");
  const [curso, setCurso] = useState("");
  const [idCurso, setIdCurso] = useState(0);
  const [ano_semestre, setAno_semestre] = useState("");
  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    api
      .get(`/coord/turnos`)
      .then((response) => {
        setTurnos(response.data.turnos);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  useEffect(() => {
    if (data) {
      setIdUnidade(data.id_unidade);
      setCurso(data.curso);
      setIdCurso(data.id_curso);
      setCentro_custo(data.centro_custo);
      setIdCentro_custo(data.id_centro_custo);
      setTurno(data.turno);
      setIdTurno(data.id_turno);
      setId(data.id);
      setModulo(data.modulo);
      setAno_semestre(data.semestre_ano);
      if (data.data_inicio) {
        setInicio(
          data.data_inicio.substring(6, 10) +
            "-" +
            data.data_inicio.substring(3, 5) +
            "-" +
            data.data_inicio.substring(0, 2)
        );
      }
      if (data.data_fim) {
        setFim(
          data.data_fim.substring(6, 10) +
            "-" +
            data.data_fim.substring(3, 5) +
            "-" +
            data.data_fim.substring(0, 2)
        );
      }
    }
  }, [data]);

  async function handleSubmit(e) {
    e.preventDefault();

    if (inicio > fim) {
      return Swal.fire({
        title: "Atenção!",
        text: "A data inicial deve ser antes da final!",
        icon: "warning",
      });
    }

    const data = {
      id: id,
      nome:
        curso +
        " - " +
        centro_custo +
        " - " +
        modulo +
        " - " +
        turno +
        " - " +
        ano_semestre,
      id_unidade: idUnidade,
      id_curso: idCurso,
      id_centro_custo: idCentro_custo,
      id_turno: idTurno,
      modulo: modulo,
      semestre_ano: ano_semestre,
      data_inicio: inicio,
      data_fim: fim,
    };

    try {
      const response = await api.patch(`/coord/classes`, { data });
      if (response.data.error) {
        return Swal.fire("Atenção!", response.data.error, "warning");
      }
      Toast.fire({
        icon: "success",
        title: "Editado com sucesso!",
      });
      setShowModal(!showModal);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      alert(
        error
      ); /*Swal('Atenção!', 'Serviço indisponível no momento', 'error')*/
    }
  }
  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              setShowModal(!showModal);
            }
          }}
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form onSubmit={handleSubmit}>
                <h3>Atualizar Turma</h3>
                <FormContent>
                  {/* <Label>Unidade:</Label>
                                    <Select
                                        type="name"
                                        value={unidade}
                                        placeholder="unidade"
                                        id='unidade'
                                        onChange={(e) => {
                                            setUnidade(e.target.value)
                                            setIdUnidade(e.target[e.target.options.selectedIndex].id);
                                        }}
                                        required
                                    >
                                        {unidades.map((u,i) => (
                                            <option
                                                id={u.id}
                                            >{u.nome}</option>
                                        ))}
                                    </Select>
                                    <Label>Curso:</Label>
                                    <Select
                                        value={curso}
                                        id='curso'
                                        onChange={(e) => {
                                            setCurso(e.target.value)
                                            setIdCurso(e.target[e.target.options.selectedIndex].id);
                                        }}
                                        required
                                    >
                                        {cursos.map((c,i) => (
                                            <option
                                                id={c.id}
                                            >{c.curso}</option>
                                        ))}
                                    </Select>
                                    <Label>Centro de Custo:</Label>
                                    <Select
                                        type="centro_custo"
                                        value={centro_custo}
                                        id='centro_custo'
                                        placeholder="centro_custo"
                                        onChange={(e) => {
                                            setCentro_custo(e.target.value)
                                            setIdCentro_custo(e.target[e.target.options.selectedIndex].id);
                                        }}
                                        required
                                    >
                                        {centros_custo.map((c,i) => (
                                            <option
                                                id={c.id}
                                            >{c.nome}</option>
                                        ))}
                                    </Select>
                                    <Label>Módulo:</Label>
                                    <Select
                                        value={modulo}
                                        onChange={e => setModulo(e.target.value)}
                                        required
                                    >
                                        <option selected disabled value=''>Selecione o módulo</option>
                                        <option value='MOD I'>MÓDULO I</option>
                                        <option value='MOD II'>MÓDULO II</option>
                                        <option value='MOD III'>MÓDULO III</option>
                                        <option value='MOD IV'>MÓDULO IV</option>
                                        <option value='MOD V'>MÓDULO V</option>
                                        <option value='MOD VI'>MÓDULO VI</option>
                                        <option value='MOD VII'>MÓDULO VII</option>
                                        <option value='MOD VIII'>MÓDULO VIII</option>
                                        <option value='MOD IX'>MÓDULO IX</option>
                                        <option value='MOD X'>MÓDULO X</option>
                                    </Select> */}
                  <Label>Turno:</Label>
                  <Select
                    value={turno}
                    onChange={(e) => {
                      setTurno(e.target.value);
                      setIdTurno(e.target[e.target.options.selectedIndex].id);
                    }}
                    required
                  >
                    {turnos.map((t, i) => (
                      <option id={t.id}>{t.nome}</option>
                    ))}
                  </Select>
                  <Label>Ano/Semestre:</Label>
                  <Select
                    type="text"
                    value={ano_semestre}
                    onChange={(e) => setAno_semestre(e.target.value)}
                    required
                  >
                    {AnoSemestre().map((a, i) => (
                      <option key={i}>{a}</option>
                    ))}
                  </Select>
                  <Label>Início:</Label>
                  <Input
                    type="date"
                    value={inicio}
                    placeholder="Início"
                    onChange={(e) => setInicio(e.target.value)}
                    required
                  />
                  <Label>Fim:</Label>
                  <Input
                    type="date"
                    value={fim}
                    placeholder="Fim"
                    onChange={(e) => setFim(e.target.value)}
                    required
                  />
                </FormContent>
                <br />
                <Button type="submit">Atualizar</Button>
              </Form>
              <CloseModalButton
                onClick={() => {
                  setShowModal(!showModal);
                }}
              />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
