import styled from "styled-components";

export const Background = styled.div`
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  padding: 0.5rem calc((90vw - 1200px) / 2);
  background-color: #0093e9;
  background-image: linear-gradient(
    0deg,
    #0093e9 0%,
    #99d4f7 50%,
    #ffffff 100%
  );
`;

export const Container = styled.div`
  margin: 0 0 auto 0;
  width: 98%;
  height: auto;
  background: #fff;
  padding: 2rem 1rem;
  padding-top: 0.1rem;
  align-items: center;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  /* min-height:479px; */
  min-width: 289px;
  @media (max-width: 830px) {
    width: 90%;
    margin-left: 5%;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 830px) {
    flex-direction: column;
  }
`;

export const Titulo = styled.div`
  h3 {
    margin-top: 23px;
  }

  @media (max-width: 558px) {
    font-size: 13px;
    text-align: center;
  }
`;

export const Hr = styled.div`
  background-color: #0093e9;
  height: 0.19rem;
  width: 92%;
  margin-top: 15px;
`;

export const Form = styled.div`
  margin-top: 10px;
  font-size: 18px;

  label {
    margin-left: 5px;
  }

  input {
    border-radius: 5px;
    text-align: center;
    height: 30px;
    width: 150px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 3px 7px;
  }
`;

export const InputLabel = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;

  label {
    margin-right: 15px;
    margin-left: 5px;
  }

  input {
    height: 30px;
    width: 170px;
    border-radius: 5px;
    text-align: center;
    padding: 3px 7px;
  }
`;

export const Button = styled.button`
  margin-right: 5px;

  width: 100px;
  height: 30px;
  border-radius: 4px;
  background: #2b74e0;
  color: #fff;
  border: 2px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;
  &:hover {
    background-color: #1563cf;
  }
`;

export const Table = styled.div`
  margin-top: 15px;
  overflow-x: auto;
`;

export const Select = styled.select`
  height: 30px;
  width: 210px;
  border-radius: 5px;
  text-align: center;
  margin: 0 5px 0 5px;
  -webkit-appearance: none;
  background: url("https://cdn-icons-png.flaticon.com/512/60/60995.png") 95.5%
    50% no-repeat;
  background-size: 14px;
  &:invalid {
    color: gray;
  }
`;
