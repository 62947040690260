import React, { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Background,
  Page,
  Subpage,
  Images,
  Content,
  Title,
  Table,
  Breaker,
  Assinaturas,
} from "./style";
import Prefeitura from "../../../assets/Images/prefeitura.jpg";
import Fiec from "../../../assets/Images/fiec.png";
import PdfButtons from "../../../components/PdfButtons";
import api from "../../../services/api";
import Auth from "../../../services/auth";
import { Swal } from "../../../services/SwalTemplates";

export default function PdfProfessoresTrocados() {
  const [IdCoord] = useState(Auth.id());
  const [Dados, setDados] = useState([]);
  const [Dados2, setDados2] = useState([]);

  const location = useLocation();
  const ref = useRef();

  useEffect(() => {
    api
      .get(
        `/coord/professortrocadopdf?idCoord=${IdCoord}&idLancamento=${location.state.idLancamento}`
      )
      .then((response) => {
        if (response.data.error)
          return Swal.fire({
            title: "Atenção!",
            text: response.data.error,
            icon: "warning",
            confirmButtonColor: "var(--blue-color)",
          });
        setDados(response.data.response[0]);
        setDados2(response.data.response[1]);
      })
      .catch((error) =>
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar!",
          icon: "warning",
          confirmButtonColor: "var(--blue-color)",
        })
      );
  }, [IdCoord, location.state.idLancamento, location.state.idTurma]);

  useEffect(() => {
    if (ref.current.clientHeight > 1045) {
      console.log("QUEBRAR PÁGINA");
    }
  }, [ref]);

  return (
    <Background>
      <PdfButtons />
      <Page>
        <Subpage>
          <Breaker ref={ref}>
            <Images>
              <img src={Fiec} alt="Erro" />
              <img src={Prefeitura} alt="Erro" />
            </Images>
            <Content>
              <Title>
                PROFESSORES SUBSTITUÍDOS - ANO {Dados[0]?.semestre_ano}
              </Title>
              <Title>{Dados[0]?.turma}</Title>
              <Table>
                <thead>
                  <tr>
                    <th>PROFESSOR</th>
                    <th>INICIO</th>
                    <th>FIM</th>
                    <th>COMPONENTE CURRICULAR</th>
                    <th>CARGA HORÁRIA SEMANAL</th>
                  </tr>
                </thead>
                <tbody>
                  {Dados?.map((d, i) => (
                    <tr key={i}>
                      <td>{d.professor} </td>
                      <td>{d.data_inicio}</td>
                      <td>{d.data_fim}</td>
                      <td>{d.disciplina}</td>
                      <td>{d.carga}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <br />
              <br />
              <br />

              <Title>
                PROFESSORES SUBSTITUTOS DO - ANO {Dados[0]?.semestre_ano}
              </Title>
              <Table>
                <thead>
                  <tr>
                    <th>PROFESSOR</th>
                    <th>INICIO</th>
                    <th>FIM</th>
                    <th>COMPONENTE CURRICULAR</th>
                    <th>CARGA HORÁRIA SEMANAL</th>
                    <th>ASSINATURA</th>
                  </tr>
                </thead>
                <tbody>
                  {Dados2?.map((d, i) => (
                    <tr key={i}>
                      <td>{d.professor} </td>
                      <td>{d.data_inicio}</td>
                      <td>{d.data_fim}</td>
                      <td>{d.disciplina}</td>
                      <td>{d.carga}</td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <br />
              <br />
              <br />
              <br />
              <Assinaturas>
                <div>Coordenação</div>

                <div>Direção pedagógica</div>
              </Assinaturas>
            </Content>
          </Breaker>
        </Subpage>
      </Page>
    </Background>
  );
}
