import React, { useState, useEffect } from "react";
import {
  Container,
  Background,
  Hr,
  Titulo,
  Button,
  Input,
  Row,
  Table,
} from "./style";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import Pagination from "../../../components/Pagination";
import Auth from "../../../services/auth";
import {
  STATIC_URL_DEV,
  STATIC_URL_PROD,
} from "../../../assets/json/texts.json";

export default function Usuarios() {
  const [auth] = useState(Auth.auth());
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [professores, setProfessores] = useState([]);
  const [professor, setProfessor] = useState("");
  const [id_professor, setId_professor] = useState(0);
  const [consulta, setConsulta] = useState([]);

  useEffect(() => {
    api
      .get(`/rh/contributors`)
      .then((response) => {
        setProfessores(response.data.colaboradores);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, []);

  useEffect(() => {
    api
      .get(`/rh/turmacolaborador?matricula=${id_professor}`)
      .then((response) => {
        setConsulta(response.data.turma_colaborador[0]);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [id_professor]);

  useEffect(() => {
    setTotalPages(Math.ceil(consulta.length / 15));
  }, [consulta]);

  function handleExcel(id_professor) {
    Swal.fire({
      title: "Você tem certeza?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Gerar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await api.post(`/rh/contributorsxlsx`);
        if (response.data.error) {
          return Swal.fire(
            "Criação não permitida!",
            response.data.error,
            "warning"
          );
        }
        Toast.fire({
          icon: "success",
          title: "Gerado com sucesso!",
        });
        window.open(
          window.location.href.includes("localhost")
            ? `${STATIC_URL_DEV}/colaboradoreslancamento?auth=${auth}`
            : `${STATIC_URL_PROD}/colaboradoreslancamento?auth=${auth}`
        );
        // setCustos(custos.filter((custo) => custo.id !== id));
      }
    });
  }

  return (
    <Background>
      <Container>
        <Titulo>Consulta de Horários</Titulo>
        <Hr />
        <Row>
          <Input>
            <div style={{ display: "flex" }}>
              <select
                value={professor}
                onChange={(e) => {
                  setProfessor(e.target.value.toUpperCase());
                  setId_professor(e.target[e.target.options.selectedIndex].id);
                  setPage(1);
                }}
              >
                <option disabled value="">
                  Selecione o professor
                </option>
                {professores.map((p, i) => (
                  <option key={i} id={p.matricula}>
                    {p.nome}
                  </option>
                ))}
              </select>
              <Button
                onClick={() => {
                  handleExcel(id_professor);
                }}
                style={{
                  cursor: "pointer",
                  opacity: 1,
                  pointerEvents: "auto",
                }}
              >
                Gerar planilha
              </Button>
            </div>
          </Input>
        </Row>
        <Table>
          <table>
            <thead>
              <tr>
                <th>Dia Semana</th>
                <th>Unidade</th>
                <th>Turma</th>
                <th>Horário Início</th>
                <th>Horário Término</th>
              </tr>
            </thead>
            <tbody>
              {consulta.slice((page - 1) * 15, page * 15).map((c, i) => (
                <tr key={i}>
                  <td data-title="Dia da semana" value={c.nome}>
                    {c.dia_semana}
                  </td>
                  <td data-title="Unidade" value={c.nome}>
                    {c.unidade}
                  </td>
                  <td data-title="Turma" value={c.id} className="td-left">
                    {c.turma
                      .replaceAll("TÉCNICO EM ", "")
                      .replaceAll("CURSO TÉCNICO ", "")
                      .replaceAll("TECNÓLOGO EM ", "")}
                  </td>
                  <td data-title="Horário Início" value={c.nome}>
                    {c.horario_inicio}
                  </td>
                  <td data-title="Horário Fim" value={c.nome}>
                    {c.horario_fim}
                  </td>
                  {/* <td data-title="PDF">
                      <ion-icon
                        name="pencil-outline"
                        style={{ fontSize: "25px", cursor: "pointer" }}
                        onClick={() => {
                        }}
                      />
                    </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </Table>
        {/* <p>Carga horária: {carga_horaria}</p> */}
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Container>
    </Background>
  );
}
