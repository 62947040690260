import styled from "styled-components";
import { GoPlus, GoDash } from "react-icons/go";

export const Background = styled.div`
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  background-color: #0093e9;
  background-image: linear-gradient(
    0deg,
    #0093e9 0%,
    #99d4f7 50%,
    #ffffff 100%
  );
`;

export const Container = styled.div`
  width: fit-content;
  height: 100%;
  background: #fff;
  padding: 2rem 1rem;
  align-items: center;
  border-radius: 0.5rem;
  min-width: 289px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  td {
    padding: 0 0.5rem;
  }

  @media screen and (max-width: 1050px) {
    width: 95%;
  }
`;

export const Titulo = styled.h1`
  text-align: center;
  color: #0093e9;
  width: 100%;
`;

export const Hr = styled.div`
  background-color: #0093e9;
  height: 0.19rem;
  width: 92%;
  margin-top: 15px;
  align-self: center;
`;

export const Table = styled.div`
  width: 99%;
  min-width: 90vw;
  max-width: 95vw;
  overflow-x: auto;
`;

export const Input = styled.input`
  padding: 3px 7px;
  width: 250px;
  height: 45px;
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 17px;
`;

export const Header = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
`;

export const Button = styled.button`
  margin-right: 5px;
  width: 140px;
  height: 45px;
  border-radius: 4px;
  background: #2b74e0;
  color: #fff;
  border: 2px;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;

  &:hover {
    background-color: #1563cf;
  }
`;

export const Checkbox = styled.div`
  width: 1.3rem;
  height: 1.3rem;
  border: 2px solid var(--red-color);
  border: 2px solid black;
  border-radius: 0.2rem;
  margin: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AddIcon = styled(GoPlus)``;

export const RemoveIcon = styled(GoDash)``;
