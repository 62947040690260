import styled from "styled-components";
import { Link as Rota } from "react-router-dom";

export const Background = styled.div`
  width: 100%;
  min-height: calc(100vh - 80px);
  overflow-y: auto;
  position: fixed;
  background-color: #0093e9;
  background-image: linear-gradient(
    0deg,
    #0093e9 0%,
    #99d4f7 50%,
    #ffffff 100%
  );
  margin-bottom: 2em;
  padding-bottom: 200px;
`;

export const Container = styled.div`
  margin-bottom: 20px;
  border: 2px solid green;
`;

export const Titulo = styled.h1`
  text-align: center;
  font-weight: 700;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`;

export const ContentForm = styled.div`
  width: 40%;
  background: #fff;
  min-height: 785px;
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  position: absolute;
  top: 4%;
  left: 50%;
  transform: translate(-50%);
  float: right;

  @media (max-width: 1150px) {
    width: 60%;
  }

  @media (max-width: 659px) {
    width: 80%;
  }

  @media (max-width: 470px) {
    width: 91%;
  }

  @media (max-width: 394px) {
    width: 99%;
  }
`;

export const Content = styled.div`
  width: 95%;
  background: #fff;
  padding: 2rem 2rem;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  position: absolute;
  top: 3rem;
  left: 50%;
  transform: translate(-50%);

  hr {
    margin-top: 5px;
  }

  h1 {
    color: #2b74e0;
  }

  @media (max-width: 464px) {
    width: 87%;
  }
`;

export const CheckBox = styled.input`
  display: flex;
  align-items: center;
`;

export const Form = styled.form`
  display: flex;
  width: 100%;
  align-items: flex-end;
`;

export const FormController = styled.div`
  margin-top: 0.8rem;
  background-color: #ffffff;
`;

export const THead = styled.th`
  text-align: center;
  border: 1px solid #000;
`;

export const Link = styled(Rota)`
  text-decoration: none;
  color: red;

  img {
    width: 45px;
    height: 80px;
    margin-top: 1%;
    margin-left: 50px;

    &:hover {
      opacity: 90%;
      transform: scale(1.1);
    }

    @media (max-width: 810px) {
      margin-left: 25px;
      width: 40px;
    }

    @media (max-width: 656px) {
      display: none;
    }
  }
`;

export const Input = styled.input`
  display: flex;
  flex-direction: row;
  background-color: #f3f3f3;
  border: none;
  border-radius: 20px;
  height: 30px;
  width: 334px !important;
  text-align: center;

  @media (max-width: 369px) {
    width: 260px !important;
    font-size: 12px;
  }
`;

export const Label = styled.label`
  margin-left: 1rem;
`;

export const Button = styled.button`
  font-weight: bolder;
  background: #2b74e0;
  border: 0;
  border-radius: 0.25rem;
  padding: 0.6rem 0.5rem;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s linear;

  &:hover {
    opacity: 90%;
    transform: scale(1.1);
  }

  @media screen and (max-width: 480px) {
    left: 32px;
    top: 80px;
  }
`;

export const Table = styled.div`
  margin-top: 15px;
  width: 100%;
`;

export const Filters = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  @media screen and (max-width: 760px) {
    justify-content: center;
  }
`;

export const Select = styled.select`
  border: 2px solid var(--blue-color);
  border-radius: 2rem;
  height: 42px;
  padding-left: 15px;
  width: 110px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-image: linear-gradient(45deg, transparent 50%, currentColor 50%),
    linear-gradient(135deg, currentColor 50%, transparent 50%);
  background-position: right 15px top 50%, right 10px top 50%;
  background-size: 5px 5px, 5px 5px;
  background-color: #f3f3f3;

  @media screen and (max-width: 610px) {
    border: none;
  }
`;
