import React, { useState } from "react";
import {
  Container,
  Content,
  Header,
  Hr,
  TituloLogo,
  ContentForm,
  Titulo,
  Form,
  FormController,
} from "./styles";
import Logo from "../../assets/Images/fiec.png";
import api from "../../services/api";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Texts from "../../assets/json/texts.json";
import {
  isAuthenticatedDev,
  isAuthenticatedRh,
  isAuthenticatedDiretor,
  isAuthenticatedCoordenador,
  isAuthenticatedSecretaria,
} from "../../auth";

export default function Index() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");

  if (isAuthenticatedDev()) {
    history.push("/homedev");
  }
  if (isAuthenticatedRh()) {
    history.push("/rh");
  }
  if (isAuthenticatedDiretor()) {
    history.push("/dir");
  }
  if (isAuthenticatedCoordenador()) {
    history.push("/coord");
  }
  if (isAuthenticatedSecretaria()) {
    history.push("/sec");
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const data = {
      email: email,
      senha: senha,
    };

    try {
      const response = await api.post("/login", { data });
      if (response.data.error) {
        return Swal.fire({
          title: "Alerta!",
          text: response.data.error,
          icon: "warning",
          confirmButtonColor: "var(--blue-color)",
        });
      }
      localStorage.setItem("token", response.data.token);
      if (response.data.user.perfil === "ADM") {
        return history.push("/");
      } else if (response.data.user.perfil === "COORD") {
        return history.push("/coord");
      } else if (response.data.user.perfil === "RH") {
        return history.push("/rh");
      } else if (response.data.user.perfil === "SEC") {
        return history.push("/sec");
      } else if (response.data.user.perfil === "DIR") {
        return history.push("/dir");
      } else if (response.data.user.perfil === "DEV") {
        return history.push("/homedev");
      }
    } catch (error) {
      Swal.fire({
        title: "Atenção!",
        text: "Não foi possível acessar!",
        icon: "error",
        confirmButtonColor: "var(--blue-color)",
      });
    }
  }

  async function handleRecover(email) {
    const data = {
      email: email,
    };
    try {
      const response = await api.patch(`/recuperarsenha`, { data });
      if (response.data.error) {
        return Swal.fire(
          "Não foi possível enviar!",
          response.data.error,
          "warning"
        );
      }
      Swal.fire({
        title: "Enviado !",
        text: "A nova senha foi enviada ao email",
        iconHtml:
          '<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzj2ghs3ry4uq9Wv8zQ0VaAckjJ4jKShq0Fw&usqp=CAU">',
      });
    } catch (error) {
      Swal.fire({
        title: "Atenção!",
        text: "Não foi possível recuperar!",
        icon: "error",
        confirmButtonColor: "var(--blue-color)",
      });
    }
  }

  return (
    <Container>
      <Content>
        <TituloLogo>
          <img src={Logo} alt="Erro!" />
          <h1>Lançamento de Horas</h1>
          <legend>Setor RH</legend>
          <span>{Texts.STATIC_ULTIMO_UPDATE}</span>
        </TituloLogo>
      </Content>
      <Content>
        <ContentForm>
          <Header>
            <Titulo>Bem vindo(a)!</Titulo>
          </Header>
          <Hr />
          <Titulo style={{ paddingTop: "28px" }}>Login</Titulo>
          <Form onSubmit={handleSubmit}>
            <FormController>
              <label htmlFor="email">Email:</label>
              <input
                id="email"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormController>
            <FormController>
              <label htmlFor="password">Senha:</label>
              <input
                id="password"
                type="password"
                name="senha"
                autoComplete="on"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
              />
            </FormController>
            <FormController>
              <button type="submit" name="botao">
                Entrar
              </button>
            </FormController>
            <FormController>
              <span
                onClick={async () => {
                  await Swal.fire({
                    title: "Recuperar senha",
                    text: "Digite seu e-mail para receber uma nova senha ",
                    input: "text",
                    inputAttributes: {
                      autocapitalize: "off",
                    },
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    cancelButtonText: "Cancelar",
                    confirmButtonText: "Confirmar",
                    inputPlaceholder: "Insira o e-mail",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handleRecover(result.value);
                    }
                  });
                }}
              >
                Esqueceu a senha?
              </span>
            </FormController>
          </Form>
        </ContentForm>
      </Content>
    </Container>
  );
}
