import React, { useState, useEffect } from "react";
import {
  Background,
  Container,
  Hr,
  Titulo,
  Table,
  Header,
  Button,
  Input,
} from "./style";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import Pagination from "../../../components/Pagination";
import { IoPencilOutline, IoTrashOutline } from "react-icons/io5";
import ModalRegister from "../../../components/Modal/Rh/Eventos/Register";
import ModalEdit from "../../../components/Modal/Rh/Eventos/Edit";

export default function Eventos() {
  const [eventos, setEventos] = useState([]);
  const [evento, setEvento] = useState("");
  const [search, setSearch] = useState("");
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [maxPageItems] = useState(10);

  useEffect(() => {
    api
      .get(`/rh/eventos?nome=${search}`)
      .then((response) => {
        setEventos(response.data.eventos);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [search]);

  useEffect(() => {
    setTotalPages(Math.ceil(eventos.length / maxPageItems));
  }, [eventos, maxPageItems]);

  async function handleDelete(id) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "O evento será excluído permanentemente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Excluir",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await api.delete(`/rh/eventos?id=${id}`);
        if (response.data.error) {
          return Swal.fire({
            title: "Atenção!",
            text: response.data.error,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "Cancelar",
            confirmButtonText: "Excluir",
          }).then(async (result) => {
            if (result.isConfirmed) {
              const response = await api.delete(
                `/rh/eventos?id=${id}&confirm=${true}`
              );
              if (response.data.error) {
                return Swal.fire(
                  "Exclusão não permitida!",
                  response.data.error,
                  "warning"
                );
              }
              Toast.fire({
                icon: "success",
                title: "Excluído com sucesso!",
              });
              setEventos(eventos.filter((e) => e.id !== id));
            }
          });
        }
        Toast.fire({
          icon: "success",
          title: "Excluído com sucesso!",
        });
        setEventos(eventos.filter((e) => e.id !== id));
      }
    });
  }

  return (
    <Background>
      <Container>
        <Titulo>Eventos</Titulo>
        <Hr />
        <Header>
          <Button
            onClick={() => {
              setShowModalRegister(true);
            }}
          >
            Novo
          </Button>
          <Input
            value={search}
            onChange={(e) => {
              setSearch(e.target.value.toUpperCase());
              setPage(1);
            }}
            type="search"
            placeholder="Pesquisar evento..."
          />
        </Header>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nome</th>
              <th>Evento</th>
              <th>Tipo</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {eventos
              .slice((page - 1) * maxPageItems, page * maxPageItems)
              .map((e, i) => (
                <tr key={i}>
                  <td data-title="ID">{e.id}</td>
                  <td data-title="Nome" className="td-left">
                    {e.nome}
                  </td>
                  <td data-title="Evento">{e.id_evento}</td>
                  <td data-title="Tipo">{e.tipo}</td>
                  <td data-title="Editar">
                    <IoPencilOutline
                      title="Editar"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={() => {
                        setEvento(e);
                        setShowModalEdit(true);
                      }}
                    />
                  </td>
                  <td data-title="Excluir">
                    <IoTrashOutline
                      title="Excluir"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={async () => {
                        await handleDelete(e.id);
                      }}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Container>
      <ModalRegister
        showModal={showModalRegister}
        setShowModal={setShowModalRegister}
      />
      <ModalEdit
        showModal={showModalEdit}
        setShowModal={setShowModalEdit}
        dados={evento}
      />
    </Background>
  );
}
