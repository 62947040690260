import styled from "styled-components";

export const Background = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  box-sizing: border-box;
  -moz-box-sizing: border-box;

  .hidden {
    position: relative;
    z-index: 0;

    @media print {
      display: none;
    }
  }

  @page {
    size: portrait;
    margin: 0;
  }

  @page {
    margin-top: 1cm;
    margin-bottom: 2cm;
  }

  @page :first {
    margin-top: 0;
  }

  @media print {
    width: 210mm;
    height: 297mm;
  }
`;

export const Page = styled.div`
  width: 210mm;
  min-height: 297mm;
  padding: 5mm;
  margin: 10mm auto;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  @media print {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
`;

export const Subpage = styled.div`
  /* padding: 0.5cm; */
  /* border: 5px red solid; */
  height: 257mm;
  /* outline: 2cm #ffeaea solid; */
`;

export const Images = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  object-fit: cover;
  img {
    &:nth-child(1) {
      height: 60px;
    }

    &:nth-child(2) {
      height: 40px;
    }
  }
`;

export const Breaker = styled.div`
  width: 100%;
  height: fit-content;
`;

export const Content = styled.div`
  width: 100%;

  p {
    font-size: 12px;
    z-index: 4;
    position: relative;
  }
  span {
    text-align: center;
  }
`;

export const Title = styled.h1`
  font-size: 12px;
  text-align: center;
  padding: 20px 0;
`;

export const Table = styled.table`
  /* border: 2px solid black; */
  font-size: 8px;
  width: 100%;
  text-align: center;
  margin: 20px 0;
  z-index: 5;
  position: relative;

  thead {
    height: fit-content;
    margin-top: 20px;
  }

  td,
  th {
    font-weight: normal;
    border: 0.1px solid #000;
    height: fit-content;
    padding: 5px 0;

    border-collapse: collapse;
    text-transform: uppercase;
  }
`;

export const Assinaturas = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: relative;
  z-index: 5;

  div {
    font-size: 12px;
  }
`;
