import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
  Input,
  Label,
} from "../../ModalRegisterStyle";
import api from "../../../../services/api";
import Swal from "sweetalert2";
import InputMask from "react-input-mask";

export default function ModalRegister({ showModal, setShowModal }) {
  const modalRef = useRef();
  const [nome, setNome] = useState("");
  const [endereco, setEndereco] = useState("");
  const [cep, setCep] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [fixoChecked, setFixoChecked] = useState(true);
  const [movelChecked, setMovelChecked] = useState(false);

  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  function onBlurCep(ev) {
    const { value } = ev.target;
    const cep = value.replace(/[^0-9]/g, "");
    if (cep.length !== 8) {
      return;
    }
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setCidade(data.localidade.toUpperCase());
        setEndereco(data.logradouro.toUpperCase());
        setEstado(data.uf.toUpperCase());
        document.getElementById("endereco").focus();
      });
  }

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  /**
   * Vai servir para gerar o pdf do relatório
   * @param {Previne o evento padrão da função} e
   */
  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      nome: nome,
      endereco: endereco,
      cep: cep,
      telefone: telefone,
      cidade: cidade,
      estado: estado,
    };
    try {
      const response = await api.post(`/sec/units`, { data });
      if (response.data.error) {
        return Swal.fire("Atenção!", response.data.error, "warning");
      }
      Swal.fire("Sucesso!", response.data.success, "success");
      Toast.fire({
        icon: "success",
        title: "Cadastrado com sucesso!",
      });
      setShowModal(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      alert(error);
    }
  }

  // const InputCep = (props) => (
  //   <InputMask mask="99999-999" value={props.value} onChange={props.onChange}>
  //     {(inputProps) => <MaterialInput {...inputProps} type="tel" disableUnderline />}
  //   </InputMask>
  // );

  // if(numero.length === 3){

  // }

  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              setShowModal(!showModal);
              // clear();
            }
          }}
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form onSubmit={handleSubmit}>
                <h3>Cadastrar Unidade</h3>
                <FormContent>
                  <Label>Nome:</Label>
                  <Input
                    type="name"
                    value={nome}
                    placeholder="Nome"
                    onChange={(e) => setNome(e.target.value.toUpperCase())}
                    required
                  />
                  <Label>Telefone:</Label>
                  <div
                    style={{
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center  ",
                    }}
                  >
                    <div style={{ padding: "5px" }}>
                      <input
                        type="radio"
                        id="numero"
                        name="numero"
                        value="Fixo"
                        onClick={() => {
                          setFixoChecked(true);
                          setMovelChecked(false);
                        }}
                        checked={fixoChecked}
                      ></input>
                      <label for="fixo" style={{ marginLeft: "5px" }}>
                        Telefone fixo
                      </label>
                    </div>

                    <div style={{ padding: "5px", marginLeft: "30px" }}>
                      <input
                        type="radio"
                        id="numero"
                        name="numero"
                        value="Celular"
                        onClick={() => {
                          setMovelChecked(true);
                          setFixoChecked(false);
                        }}
                      ></input>
                      <label for="movel" style={{ marginLeft: "5px" }}>
                        Telefone movel
                      </label>
                    </div>
                  </div>
                  {fixoChecked ? (
                    <InputMask
                      mask="(99)9999-9999"
                      value={telefone}
                      placeholder="(00)0000-0000"
                      onChange={(e) => setTelefone(e.target.value)}
                      style={{
                        borderRadius: "5px",
                        height: "30px",
                        marginBottom: "20px",
                        textAlign: "center",
                        border: "2px solid black",
                        caretcolor: "#0093E9",
                      }}
                    />
                  ) : movelChecked ? (
                    <InputMask
                      mask="(99)99999-9999"
                      value={telefone}
                      placeholder="(00)00000-0000"
                      onChange={(e) => setTelefone(e.target.value)}
                      style={{
                        borderRadius: "5px",
                        height: "30px",
                        marginBottom: "20px",
                        textAlign: "center",
                        border: "2px solid black",
                        caretcolor: "#0093E9",
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  <Label>CEP:</Label>
                  <InputMask
                    mask="99999-999"
                    value={cep}
                    placeholder="00000-000"
                    onChange={(e) => setCep(e.target.value)}
                    onBlur={onBlurCep}
                    pattern="\d{5}-?\d{3}"
                    style={{
                      borderRadius: "5px",
                      height: "30px",
                      marginBottom: "20px",
                      textAlign: "center",
                      border: "2px solid black",
                      caretcolor: "#0093E9",
                    }}
                    required
                  />
                  <Label>Endereço:</Label>
                  <Input
                    type="text"
                    value={endereco}
                    placeholder="Endereço"
                    id="endereco"
                    onChange={(e) => setEndereco(e.target.value.toUpperCase())}
                    required
                  />
                  <Label>Cidade:</Label>
                  <Input
                    type="text"
                    value={cidade}
                    placeholder="Cidade"
                    onChange={(e) => setCidade(e.target.value.toUpperCase())}
                    required
                  />
                  <Label>Estado:</Label>
                  <Input
                    type="text"
                    value={estado}
                    placeholder="Estado"
                    onChange={(e) => setEstado(e.target.value.toUpperCase())}
                    required
                  />
                </FormContent>
                <br />
                <Button type="submit">Cadastrar</Button>
              </Form>
              <CloseModalButton
                onClick={() => {
                  setShowModal(!showModal);
                  // clear();
                }}
              />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
