import React from "react";
import { Container, Titulo } from "./styles";
import { BsCaretLeftFill, BsCaretRightFill } from "react-icons/bs";

export default function Pagination({ page, setPage, totalPages, title }) {
  return (
    <Container>
      {page <= 1 ? (
        <BsCaretLeftFill
          size={25}
          color={"var(--blue-color)"}
          style={{ opacity: "50%", cursor: "not-allowed" }}
        />
      ) : (
        <BsCaretLeftFill
          size={25}
          color={"var(--blue-color)"}
          style={{ cursor: "pointer" }}
          onClick={() => setPage(page - 1)}
        />
      )}
      <Titulo>{title}</Titulo>
      {page < totalPages ? (
        <BsCaretRightFill
          size={25}
          color={"var(--blue-color)"}
          style={{ cursor: "pointer" }}
          onClick={() => setPage(page + 1)}
        />
      ) : (
        <BsCaretRightFill
          size={25}
          color={"var(--blue-color)"}
          style={{ opacity: "50%", cursor: "not-allowed" }}
        />
      )}
    </Container>
  );
}
