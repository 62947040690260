import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(
    0deg,
    #0093e9 0%,
    #99d4f7 50%,
    #ffffff 100%
  );

  img {
    width: 350px;
    height: 200px;
  }
`;

export const Button = styled.button`
  border-radius: 4px;
  background: #e8343d;
  padding: 10px 22px;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
  height: 38px;
  font-weight: bolder;
  margin-top: 20px;

  &:hover {
    background: #f8f8ff;
    color: #010606;
  }
`;
