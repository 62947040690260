import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
  Input,
  Label,
  ToggleButton,
  Circle,
  Select,
} from "./style";
import api from "../../../../services/api";
import Swal from "sweetalert2";

export default function Modal({ showModal, setShowModal, dados, setDados }) {
  const modalRef = useRef();
  const [nome, setNome] = useState("");
  const [ValorNumero, setValorNumero] = useState(0);
  const [ValorTexto, setValorTexto] = useState("");
  const [descricao, setDescricao] = useState("");
  const [ativo, setAtivo] = useState(false);
  const [id, setId] = useState(0);
  const [Tipo, setTipo] = useState("number");

  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  function clear() {
    setNome("");
    setAtivo(false);
    setDescricao("");
    setValorTexto("");
    setValorNumero(0);
    setDados("");
    setId(0);
    setTipo("number");
  }

  useEffect(() => {
    if (dados) {
      setNome(dados?.nome);
      setValorNumero(dados?.valorFloat ? dados?.valorFloat : 0);
      setValorTexto(dados?.valorString ? dados?.valorString : "");
      setDescricao(dados?.descricao);
      setAtivo(dados?.ativo);
      setId(dados?.id);
      setTipo(dados?.valorString ? "text" : "number");
    }
  }, [dados]);

  /**
   * Vai servir para gerar o pdf do relatório
   * @param {Previne o evento padrão da função} e
   */
  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      nome: nome,
      descricao: descricao,
      ativo: ativo,
      valorFloat: ValorNumero ? ValorNumero : undefined,
      valorString: ValorTexto ? ValorTexto : undefined,
      id: id,
    };

    try {
      const response = id
        ? await api.patch(`dev/regras`, { data })
        : await api.post(`dev/regras`, { data });
      if (response.data.error) {
        return Swal.fire("Atenção!", response.data.error, "warning");
      }
      Swal.fire("Sucesso!", response.data.success, "success");
      Toast.fire({
        icon: "success",
        title: "Cadastrado com sucesso!",
      });
      setShowModal(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Error",
        text: "Não foi possível cadastrar!",
        icon: "error",
        confirmButtonColor: "var(--blue-color)",
      });
    }
    clear();
  }

  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              setShowModal(!showModal);
              clear();
            }
          }}
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form onSubmit={handleSubmit}>
                <h3>{dados ? "Atualizar Regra" : "Cadastrar Regra"}</h3>
                <FormContent>
                  <Label>Nome:</Label>
                  <Input
                    value={nome}
                    placeholder="Nome"
                    onChange={(e) => setNome(e.target.value.toUpperCase())}
                    required
                  />
                  <Label>Descrição:</Label>
                  <Input
                    type="text"
                    value={descricao}
                    placeholder="Descrição"
                    onChange={(e) => setDescricao(e.target.value)}
                    required
                  />
                  <Label>Tipo:</Label>
                  <Select
                    value={Tipo}
                    placeholder="Tipo"
                    onChange={(e) => {
                      setValorTexto("");
                      setValorNumero(0);
                      setTipo(e.target.value);
                    }}
                    required
                  >
                    <option value="number">Número</option>
                    <option value="text">Texto</option>
                  </Select>
                  <Label>Valor:</Label>
                  {Tipo === "text" ? (
                    <Input
                      type="text"
                      value={ValorTexto}
                      placeholder="Valor"
                      onChange={(e) => setValorTexto(e.target.value)}
                      required
                    />
                  ) : (
                    <Input
                      type="number"
                      value={ValorNumero}
                      onChange={(e) => setValorNumero(e.target.value)}
                      required
                    />
                  )}
                  <span>
                    Ativo:
                    <ToggleButton
                      active={ativo}
                      onClick={() => {
                        setAtivo(!ativo);
                      }}
                    >
                      <Circle active={ativo} />
                    </ToggleButton>
                  </span>
                </FormContent>
                <br />
                <Button type="submit">{id ? "Atualizar" : "Cadastrar"}</Button>
              </Form>
              <CloseModalButton
                onClick={() => {
                  setShowModal(!showModal);
                  clear();
                }}
              />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
