import React, { useState, useEffect, useRef } from "react";
import {
  Background,
  Container,
  Titulo,
  Hr,
  Table,
  CloseModalButton,
} from "./style";
import { useSpring, animated } from "react-spring";
import TitlePagination from "../../../TitlePagination";
import Pagination from "../../../Pagination";

export default function GradesCurriculares({ showModal, setShowModal, dados }) {
  const [titlePage, setTitlePage] = useState(1);
  const [totalTitlePages, setTotalTitlePages] = useState(1);
  const [page, setPage] = useState(1);
  const modalRef = useRef();
  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });

  useEffect(() => {
    setTotalTitlePages(Math.ceil(dados.length / 1));
  }, [dados]);

  useEffect(() => {
    setPage(1);
  }, [titlePage]);

  function close() {
    setShowModal(false);
    setPage(1);
    setTitlePage(1);
  }

  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              close();
            }
          }}
        >
          <animated.div style={animation}>
            <Container>
              <Titulo style={{ marginTop: 40 }}>Disciplinas</Titulo>
              <Hr />
              {dados.slice((titlePage - 1) * 1, titlePage * 1).map((d, i) => (
                <>
                  <TitlePagination
                    page={titlePage}
                    setPage={setTitlePage}
                    totalPages={totalTitlePages}
                    title={d.modulo}
                  />
                  <Table>
                    <table>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Disciplina</th>
                          <th>Carga Horária</th>
                        </tr>
                      </thead>
                      <tbody>
                        {d.dados
                          ? d.dados
                              .slice((page - 1) * 10, page * 10)
                              .map((h, i) => (
                                <tr key={i}>
                                  <td data-title="ID da Disciplina">
                                    {h.id_disciplina}
                                  </td>
                                  <td data-title="Disciplina">
                                    {h.disciplina}
                                  </td>
                                  <td data-title="Carga Horaria">
                                    {h.carga_horaria}
                                  </td>
                                </tr>
                              ))
                          : dados
                              .slice((page - 1) * 10, page * 10)
                              .map((h, i) => (
                                <tr key={i}>
                                  <td data-title="ID da Disciplina">
                                    {h.id_disciplina}
                                  </td>
                                  <td data-title="Disciplina">
                                    {h.disciplina}
                                  </td>
                                  <td data-title="Carga Horaria">
                                    {h.carga_horaria}
                                  </td>
                                </tr>
                              ))}
                        <tr>
                          <th colspan="2">Total</th>
                          <td>
                            <b>
                              {d.dados
                                .map((item) => item.carga_horaria)
                                .reduce((prev, next) => prev + next)}
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Table>
                  <Pagination
                    page={page}
                    setPage={setPage}
                    totalPages={d.dados ? Math.ceil(d.dados.length / 10) : 1}
                  />
                </>
              ))}
              <CloseModalButton
                onClick={() => {
                  close();
                }}
              />
            </Container>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
