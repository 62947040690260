import styled from "styled-components";

export const Background = styled.div`
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  padding: 0.5rem calc((100vw - 1000px) / 2);
  background-color: #0093e9;
  background-image: linear-gradient(
    0deg,
    #0093e9 0%,
    #99d4f7 50%,
    #ffffff 100%
  );
`;

export const Input = styled.input`
  margin-top: 15px;
  padding: 3px 7px;
  width: 250px;
  height: 45px;
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 17px;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 2rem 1rem;
  align-items: center;
  border-radius: 0.5rem;
  min-width: 289px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 1050px) {
    width: 95%;
  }
`;

export const Titulo = styled.h1`
  text-align: center;
  color: #0093e9;
  width: 100%;
`;

export const Hr = styled.div`
  background-color: #0093e9;
  height: 0.19rem;
  width: 92%;
  margin-top: 15px;
  align-self: center;
`;

export const Table = styled.div`
  margin-top: 15px;
  width: 99%;
  overflow: auto;
`;
