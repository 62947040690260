export default function AnoSemestre() {
  const month = new Date().getMonth();
  let year = new Date().getFullYear();
  let semestre = month <= 5 ? 1 : 2;
  let anoSemestre = [];

  anoSemestre.push(
    `0${semestre === 1 ? semestre + 1 : semestre - 1}/${
      semestre === 2 ? year : year - 1
    }`
  );
  anoSemestre.push(`0${semestre}/${year}`);
  anoSemestre.push(
    `0${semestre === 1 ? semestre + 1 : semestre - 1}/${
      semestre === 1 ? year : year + 1
    }`
  );

  return anoSemestre;
}
