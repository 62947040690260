import React, { useState, useEffect } from "react";
import {
  Background,
  Container,
  Titulo,
  Hr,
  Button,
  Esquerda,
  InputLabel,
  Direita,
  Center,
} from "./style";
import GoBack from "../../../components/GoBack";
import api from "../../../services/api";
import Auth from "../../../services/auth";
import { Swal } from "../../../services/SwalTemplates";
import { IoTrashOutline } from "react-icons/io5";

export default function RegisterGrades() {
  const [idCoord] = useState(Auth.id());
  const [unidades, setUnidades] = useState([]);
  const [unidade, setUnidade] = useState("");
  const [cursos, setCursos] = useState([]);
  const [curso, setCurso] = useState("");
  const [centros_custo, setCentros_custo] = useState([]);
  const [centro_custo, setCentro_custo] = useState("");
  const [modulos, setModulos] = useState([]);
  const [modulo, setModulo] = useState("");

  useEffect(() => {
    api
      .get(`/coord/units?idCoord=${idCoord}`)
      .then((response) => {
        setUnidades(response.data.unidades);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/courses?idCoord=${idCoord}`)
      .then((response) => {
        setCursos(response.data.cursos);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/costcenter?idCoord=${idCoord}`)
      .then((response) => {
        setCentros_custo(response.data.centros_custo);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/lancamento?idCoord=${idCoord}`)
      .then((response) => {
        setModulos(response.data.lancamento_grade);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [idCoord]);

  return (
    <>
      <Background>
        <GoBack />
        <Container>
          <Titulo>
            <h1>Cadastro de Grade Curricular</h1>
            <Hr />
          </Titulo>
          <Esquerda>
            <InputLabel>
              <label>Unidade:</label>
              <select
                value={unidade}
                onChange={(e) => setUnidade(e.target.value)}
              >
                <option selected disabled value="">
                  Selecione a unidade
                </option>
                {unidades.map((u, i) => (
                  <option>{u.unidade}</option>
                ))}
              </select>
              <label>Cursos:</label>
              <select value={curso} onChange={(e) => setCurso(e.target.value)}>
                <option selected disabled value="">
                  Selecione o curso
                </option>
                {cursos.map((c, i) => (
                  <option>{c.nome}</option>
                ))}
              </select>
              <label>Centro de Custo:</label>
              <select
                value={centro_custo}
                onChange={(e) => setCentro_custo(e.target.value)}
              >
                <option selected disabled value="">
                  Selecione o centro de custo
                </option>
                {centros_custo.map((c, i) => (
                  <option>{c.nome}</option>
                ))}
              </select>
              <label>Módulo:</label>
              <select
                value={modulo}
                onChange={(e) => setModulo(e.target.value)}
              >
                <option selected disabled value="">
                  Selecione o módulo
                </option>
                {modulos.map((m, i) => (
                  <option>{m.modulo}</option>
                ))}
              </select>
            </InputLabel>
            <label>Disciplinas das Turmas:</label>
            <Button>Nova</Button>
          </Esquerda>
          <Direita>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Carga Horárias</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <IoTrashOutline
                      title="Excluir"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <Center>
              <Button>Salvar</Button>
              <Button style={{ background: "red" }}>Cancelar</Button>
            </Center>
          </Direita>
        </Container>
      </Background>
    </>
  );
}
