import React, { useState, useEffect } from "react";
import {
  Container,
  Background,
  Hr,
  Titulo,
  Button,
  Input,
  Row,
  Table,
} from "./style";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import Pagination from "../../../components/Pagination";
import ModalRegister from "../../../components/Modal/Rh/DefineCoord/Define";
import ModalEdit from "../../../components/Modal/Rh/DefineCoord/Edit";
import { IoPencilOutline, IoTrashOutline } from "react-icons/io5";
import { isAuthenticatedDev } from "../../../auth";

export default function Coordenador() {
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [usuario, setUsuario] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    // &nome=${search}&dev=${isAuthenticatedDev()}&perfil=${`COORD`}
    api
      .get(
        `/rh/definicaocoord?curso=${search}&dev=${isAuthenticatedDev()}&perfil=${`COORD`}`
      )
      .then((response) => {
        setUsuarios(response.data.cursos_unidades);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [search]);

  useEffect(() => {
    setTotalPages(Math.ceil(usuarios.length / 15));
  }, [usuarios]);

  async function handleDelete(
    idCurso,
    idUnidade,
    idCentroCusto,
    idCoordenador
  ) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "A definição de coordenador será excluída permanentemente.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Excluir",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await api.delete(
          `rh/definicaocoord?idCurso=${idCurso}&idUnidade=${idUnidade}&idCentroCusto=${idCentroCusto}&idCoordenador=${idCoordenador}`
        );
        if (response.data.error) {
          return Swal.fire(
            "Exclusão não permitida!",
            response.data.error,
            "warning"
          );
        }
        Toast.fire({
          icon: "success",
          title: "Excluído com sucesso!",
        });
        setUsuarios(
          usuarios.filter(
            (usuario) =>
              usuario.id_curso !== idCurso || usuario.id_unidade !== idUnidade
          )
        );
      }
    });
  }

  return (
    <Background>
      <Container>
        <Titulo>Definição de Coordenador por Curso</Titulo>
        <Hr />
        <Row>
          <Button
            onClick={() => {
              setShowModalRegister(true);
            }}
          >
            Novo
          </Button>
          <Input>
            <input
              value={search}
              onChange={(e) => {
                setSearch(e.target.value.toUpperCase());
                setPage(1);
              }}
              type="search"
              placeholder="Pesquisar curso..."
            />
          </Input>
        </Row>
        <Table>
          <table>
            <thead>
              <tr>
                <th>Coord</th>
                <th>Unidade</th>
                <th>Curso</th>
                <th>C.C.</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {usuarios.slice((page - 1) * 15, page * 15).map((u, i) => (
                <tr key={i}>
                  <td
                    data-title="Coord"
                    value={u.coordenadores}
                    className="td-left"
                  >
                    {u.coordenadores}
                  </td>
                  <td
                    data-title="Unidade"
                    value={u.unidade.substring(0, 20) + "..."}
                    className="td-left"
                  >
                    {u.unidade}
                  </td>
                  <td data-title="Curso" value={u.curso} className="td-left">
                    {u.curso
                      .replaceAll("TÉCNICO EM ", "")
                      .replaceAll("TECNÓLOGO EM ", "")}
                  </td>
                  {/* Talvez deixar só o número do centro de custo */}
                  <td data-title="Centro Custo" value={u.centro_custo}>
                    {u.centro_custo
                      .replaceAll("CURSO TÉCNICO ", "")
                      .replaceAll("TECNÓLOGO EM ", "")}
                  </td>
                  <td data-title="Editar">
                    <IoPencilOutline
                      title="Editar"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={() => {
                        setUsuario(u);
                        setShowModalEdit(true);
                      }}
                    />
                  </td>
                  <td data-title="Excluir">
                    <IoTrashOutline
                      title="Excluir"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={async () => {
                        await handleDelete(
                          u?.id_curso,
                          u?.id_unidade,
                          u?.id_centro_custo,
                          u?.id_coordenador
                        );
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Table>
        <ModalRegister
          showModal={showModalRegister}
          setShowModal={setShowModalRegister}
        />
        <ModalEdit
          showModal={showModalEdit}
          setShowModal={setShowModalEdit}
          data={usuario}
        />
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Container>
    </Background>
  );
}
