import React, { useState, useEffect } from "react";
import {
  Content,
  Container,
  Titulo,
  Hr,
  Button,
  Table,
  MainDiv,
  Row,
  Input,
} from "./style";
import excel from "../../../assets/Images/excel.png";
import pdf from "../../../assets/Images/pdf.png";
import { IoPencilOutline, IoTrashOutline } from "react-icons/io5";
import Auth from "../../../services/auth";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import Pagination from "../../../components/Pagination";
import ModalRegister from "../../../components/Modal/Coord/TurmasCoord/Register";
import ModalEdit from "../../../components/Modal/Coord/TurmasCoord/Edit";
import { Link } from "react-router-dom";

export default function TurmasGrades() {
  const [id] = useState(Auth.id());
  const [turmasGrade, setTurmasGrade] = useState([]);
  const [turmaGrade, setTurmaGrade] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);

  useEffect(() => {
    api
      .get(`/coord/lancamento?idCoord=${id}`)
      .then((response) => {
        setTurmasGrade(response.data.lancamento_grade);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [id, search]);

  useEffect(() => {
    setTotalPages(Math.ceil(turmasGrade.length / 6));
  }, [turmasGrade]);

  function handleDelete(id) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "Você não poderá reverter essa ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Deletar",
      cancelButtonColor: "gray",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await api.delete(`/coord/lancamento/${id}`);
        if (response.data.error) {
          return Swal.fire(
            "Exclusão não permitida!",
            response.data.error,
            "warning"
          );
        }
        Toast.fire({
          icon: "success",
          title: "Deletado com sucesso!",
        });
        setTurmasGrade(turmasGrade.filter((turma) => turma.id !== id));
      }
    });
  }

  function handlePdf(idTurma) {
    Swal.fire({
      title: "Você tem certeza?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "blue",
      confirmButtonText: "Gerar PDF",
      cancelButtonColor: "gray",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        api
          .post(`/coord/lancamentopdf?idTurma=${idTurma}&idCoord=${id}`)
          .then((response) => {
            if (response.data.error) return alert(response.data.error);
          })
          .catch(() => {
            Swal.fire({
              title: "Atenção!",
              text: "Não foi possível consultar os dados!",
              icon: "error",
            });
          });
        Toast.fire({
          icon: "success",
          title: "Gerado com sucesso!",
        });
      }
    });
  }

  function handleExcel(idTurma) {
    Swal.fire({
      title: "Você tem certeza?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "blue",
      confirmButtonText: "Gerar Planilha",
      cancelButtonColor: "gray",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        api
          .get(`/coord/turmasxlsx?idTurma=${idTurma}`)
          .then((response) => {
            if (response.data.error) return alert(response.data.error);
          })
          .catch(() => {
            Swal.fire({
              title: "Atenção!",
              text: "Não foi possível consultar os dados!",
              icon: "error",
            });
          });
        Toast.fire({
          icon: "success",
          title: "Gerado com sucesso!",
        });
      }
    });
  }

  return (
    <Container>
      <Content>
        <MainDiv>
          <Titulo>Lançamento da Grade das Turmas</Titulo>
          <Hr />
          <Row>
            <Link to="/turmascoord">
              <Button>Novo</Button>
            </Link>
            <Input>
              <input
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value.toUpperCase());
                }}
                type="search"
                placeholder="Pesquisar nome..."
              />
            </Input>
          </Row>
          <Table>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Unidade</th>
                  <th>Curso</th>
                  <th>C.C.</th>
                  <th>Turma</th>
                  <th>Data início</th>
                  <th>Data fim</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {turmasGrade.slice((page - 1) * 6, page * 6).map((t, i) => (
                  <tr key={i}>
                    <td>{t.id}</td>
                    <td>{t.unidade}</td>
                    <td>{t.curso}</td>
                    <td title={t.centro_custo}>{t.id_centro_custo}</td>
                    <td>{t.turma}</td>
                    <td>{t.data_inicio}</td>
                    <td>{t.data_fim}</td>
                    <td>
                      <IoPencilOutline
                        title="Editar"
                        style={{ fontSize: "25px", cursor: "pointer" }}
                        onClick={() => {
                          setShowModalEdit(true);
                          setTurmaGrade(t);
                          // handleEdit(t.id)
                        }}
                      />
                    </td>
                    <td>
                      <IoTrashOutline
                        title="Excluir"
                        style={{ fontSize: "25px", cursor: "pointer" }}
                        onClick={() => {
                          handleDelete(t.id);
                        }}
                      />
                    </td>
                    <td
                      onClick={() => {
                        handlePdf(t.id);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <img src={pdf} alt="Erro!" />
                    </td>
                    <td
                      onClick={() => {
                        handleExcel(t.id);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <img src={excel} alt="Erro!" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Table>
          <Pagination page={page} setPage={setPage} totalPages={totalPages} />
          <ModalRegister
            showModal={showModalRegister}
            setShowModal={setShowModalRegister}
          />
          <ModalEdit
            showModal={showModalEdit}
            setShowModal={setShowModalEdit}
            // lancamento={lancamentoTurma}
            data={turmaGrade}
          />
        </MainDiv>
      </Content>
    </Container>
  );
}
