import React, { useState, useEffect } from "react";
import Pagination from "../../../components/Pagination";
import api from "../../../services/api";
import Auth from "../../../services/auth";
import { Swal, Toast } from "../../../services/SwalTemplates";
import { IoPencilOutline, IoTrashOutline } from "react-icons/io5";
import {
  Content,
  Container,
  Titulo,
  Hr,
  Button,
  Table,
  MainDiv,
  Row,
  Input,
} from "./style";
import ModalRegister from "../../../components/Modal/Coord/Grades/Register";
import ModalEdit from "../../../components/Modal/Coord/Grades/Edit";

export default function Grades() {
  const [id] = useState(Auth.id());
  const [grades, setGrades] = useState([]);
  const [grade, setGrade] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);

  useEffect(() => {
    api
      .get(`/coord/lancamento?idCoord=${id}`)
      .then((response) => {
        setGrades(response.data.lancamento_grade);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [id, search]);

  useEffect(() => {
    setTotalPages(Math.ceil(grades.length / 6));
  }, [grades]);

  function handleDelete(id) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "Você não poderá reverter essa ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Deletar",
      cancelButtonColor: "gray",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await api.delete(`/coord/events/${id}`);
        if (response.data.error) return alert(response.data.error);
        Toast.fire({
          icon: "success",
          title: "Deletado com sucesso!",
        });
        setGrades(grades.filter((grade) => grade.id !== id));
      }
    });
  }

  return (
    <Container>
      <Content>
        <MainDiv>
          <Titulo>Controle de Grades Cuaaaarriculares</Titulo>
          <Hr />
          <Row>
            <Button
              onClick={() => {
                setShowModalRegister(true);
              }}
            >
              Novo
            </Button>
            <Input>
              <input
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value.toUpperCase());
                }}
                type="search"
                placeholder="Pesquisar nome..."
              />
            </Input>
          </Row>
          <Table>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Unidade</th>
                  <th>Curso</th>
                  <th>C.C.</th>
                  <th>Módulo</th>
                  <th>Data Início</th>
                  <th>Data Fim</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {grades.slice((page - 1) * 6, page * 6).map((g, i) => (
                  <tr>
                    <td>{g.id}</td>
                    <td>{g.unidade}</td>
                    <td>{g.curso}</td>
                    <td>{g.centro_custo}</td>
                    <td>{g.modulo}</td>
                    <td>{g.data_inicio}</td>
                    <td>{g.data_fim}</td>
                    <td>
                      <IoPencilOutline
                        title="Editar"
                        style={{ fontSize: "25px", cursor: "pointer" }}
                        onClick={() => {
                          setShowModalEdit(true);
                          setGrade(g);
                        }}
                      />
                    </td>
                    <td>
                      <IoTrashOutline
                        title="Excluir"
                        style={{ fontSize: "25px", cursor: "pointer" }}
                        onClick={() => {
                          handleDelete(g.id);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Table>
          <ModalRegister
            showModal={showModalRegister}
            setShowModal={setShowModalRegister}
          />
          <ModalEdit
            showModal={showModalEdit}
            setShowModal={setShowModalEdit}
            data={grade}
          />
          <Pagination page={page} setPage={setPage} totalPages={totalPages} />
        </MainDiv>
      </Content>
    </Container>
  );
}
