import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import {
  Background,
  Container,
  Titulo,
  Hr,
  Form,
  InputLabel,
  ButtonAdd,
  BigButton,
  H4,
  Table,
  Inputs,
} from "./style";
import Auth from "../../../services/auth";
import GoBack from "../../../components/GoBack";
import { Swal, Toast } from "../../../services/SwalTemplates";
import {
  IoTrashOutline,
  IoTimeOutline,
  IoSwapVerticalOutline,
} from "react-icons/io5";
import ModalEdit from "../../../components/Modal/Coord/TrocaHorarios/Horarios.js";
import ModalTrocaProfessor from "../../../components/Modal/Coord/TrocaProfessorEdicaoHorarios/Edit";

export default function LancamentoTurmas() {
  const location = useLocation();
  const [id] = useState(Auth.id());
  const [id_turma] = useState(0);
  const [matricula_colaborador, setMatricula_colaborador] = useState(0);
  const [data_inicio, setData_inicio] = useState("");
  const [data_fim, setData_fim] = useState("");
  const [horario_inicio, setHorario_inicio] = useState("");
  const [horario_fim, setHorario_fim] = useState("");
  const [horario_fim_anterior, setHorario_fim_anterior] = useState("");
  const [horario_inicio_posterior, setHorario_inicio_posterior] = useState("");
  const [trocaProfessor, setTrocaProfessor] = useState("");
  const [dia_semana, setDia_semana] = useState("");
  const [disciplinas, setDisciplinas] = useState([]);
  const [disciplina, setDisciplina] = useState("");
  const [id_disciplina, setId_disciplina] = useState(0);
  const [colaboradores, setColaboradores] = useState([]);
  const [colaborador, setColaborador] = useState("");
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalEditTroca, setShowModalEditTroca] = useState(false);
  const [horarios, setHorarios] = useState("");
  const [turma, setTurma] = useState("");
  
  const [rowTable, setRowTable] = useState([]);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    api
      .get(`/coord/turmasdisciplinas?idTurma=${location.state.id_turma}`)
      .then((response) => {
        setTurma(response.data.disciplinas[0].turma)
        setDisciplinas(response.data.disciplinas);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/classesnaolancadas?idCoord=${id}&idTurma=${id_turma}`)
      .then((response) => {
        setData_inicio(
          response.data.turmas.length ? response.data.turmas[0].data_inicio : ""
        );
        setData_fim(
          response.data.turmas.length ? response.data.turmas[0].data_fim : ""
        );
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/lancamentosfeitos?idLancamentoGrade=${location.state.id}`)
      .then((response) => {
        setRowTable(response.data.lancamento_grade);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [id, id_turma, location.state.id, location.state.id_turma, turma]);

  useEffect(() => {
    api
      .get(`/coord/contributors?inativo=${false}`)
      .then((response) => {
        setColaboradores(response.data.colaboradores);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, []);

  // Talvez usar o useLocation em todos elementos do objeto
  function addRowTable() {
    setChanged(true);
    setDisciplina("");
    setColaborador("");
    setDia_semana("");
    setHorario_fim("");
    setHorario_inicio("");

    setRowTable([
      ...rowTable,
      {
        disciplina: disciplina,
        colaborador: colaborador,
        id_disciplina: id_disciplina,
        matricula_colaborador: matricula_colaborador,
        dia_semana: dia_semana,
        horario_inicio: horario_inicio,
        horario_fim: horario_fim,
        e_troca: 0,
        data_inicio: location.state.data_inicio,
        data_fim: location.state.data_fim,
      },
    ]);
  }

  function sendObj() {
    if (
      disciplina === "" ||
      colaborador === "" ||
      dia_semana === "" ||
      horario_inicio === "" ||
      horario_fim === ""
    ) {
      return Swal.fire("Atenção!", "Preencha todos os itens!", "warning");
    }
    if (horario_fim <= horario_inicio) {
      return Swal.fire(
        "Atenção!",
        "Horário final deve ser superior ao horário de início!",
        "warning"
      );
    }
    if (rowTable.length !== 0) {
      let f = rowTable.filter(
        (r) => r.dia_semana === dia_semana && r.e_troca === 0
      );
      if (f.length) {
        let valid = false;
        for (let i = 0; i <= f.length; i++) {
          if (
            i === 0
              ? f[i].horario_inicio >= horario_fim
              : i === f.length
              ? f[i - 1].horario_fim <= horario_inicio
              : f[i - 1].horario_fim <= horario_inicio &&
                f[i].horario_inicio >= horario_fim
          ) {
            valid = true;
          }
        }

        if (valid || disciplina.includes("ESTÁGIO") || disciplinas[0].turma.includes("ENFERMAGEM"))  {
          addRowTable();
        } else {
          Swal.fire(
            "Atenção!",
            `O horário definido ${horario_inicio} e ${horario_fim} já está alocado`,
            "warning"
          );
        }
      }
      if (f.length === 0) {
        addRowTable();
      }
    } else {
      addRowTable();
    }
  }

  function handleDelete(id, index) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "Você não poderá reverter essa ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Deletar",
      cancelButtonColor: "gray",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (id) {
          const response = await api.delete(
            `/coord/lancamentogradeitem?idLancamentoGrade=${id}`
          );
          if (response.data.error)
            return Swal.fire(
              "Exclusão não permitida!",
              response.data.error,
              "warning"
            );
          Toast.fire({
            icon: "success",
            title: "Deletado com sucesso!",
          });
          setRowTable(rowTable.filter((row) => row.id !== id));
        } else {
          Toast.fire({
            icon: "success",
            title: "Deletado com sucesso!",
          });
          setRowTable(rowTable.filter((row, i) => i !== index));
        }
      }
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const data = {
      id: location.state.id,
      id_unidade: location.state.id_unidade,
      id_curso: location.state.id_curso,
      id_centro_custo: location.state.id_centro_custo,
      id_turma: location.state.id_turma,
      data_inicio: location.state.data_inicio,
      data_fim: location.state.data_fim,
      e_troca: 0,
      dados: rowTable,
    };

    try {
      const response = await api.patch(`/coord/lancamentogradeitem`, {
        data,
      });
      if (response.data.error) {
        return Swal.fire("Atenção!", response.data.error, "warning");
      }
      if (rowTable.length === 0) {
        return Swal.fire(
          "Atenção!",
          "Adicione ao menos um lançamento à lista!",
          "warning"
        );
      }
      Toast.fire({
        icon: "success",
        title: "Cadastrado com sucesso!",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      Swal.fire("Atenção!", error, "error");
    }
  }


  return (
    <Background>
      <GoBack />
      <Container>
        <Titulo>Edição de Horários</Titulo>
        <Hr />
        <h3>{location.state.unidade + " - " + location.state.turma}</h3>
        <Form>
          <form onSubmit={handleSubmit}>
            <Table>
              <table style={{ marginTop: 20 }}>
                <thead>
                  <tr>
                    <th>Disciplina</th>
                    <th>Professor</th>
                    <th>Dia</th>
                    <th>Início</th>
                    <th>Fim</th>
                    <th>Data Início</th>
                    <th>Data Fim</th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {rowTable.map((r, i) => (
                    <tr key={i} className={r.e_troca === 1 ? "disabled" : ""}>
                      <td data-title="Disciplina" className="td-left">
                        {r.disciplina}
                      </td>
                      <td
                        className="td-left"
                        data-title="Professor"
                        style={{
                          color:
                            r.colaborador === "SEM PROFESSOR" ? "red" : "black",
                          fontWeight:
                            r.colaborador === "SEM PROFESSOR" ? "bold" : "none",
                        }}
                      >
                        {r.colaborador}
                      </td>
                      <td data-title="Dia">{r.dia_semana}</td>
                      <td data-title="Início">{r.horario_inicio}</td>
                      <td data-title="Fim">{r.horario_fim}</td>
                      <td data-title="Data Início">{r.data_inicio}</td>
                      <td data-title="Data Fim">{r.data_fim}</td>
                      <td data-title="Editar Horário">
                        <IoTimeOutline
                          title="Editar Horário"
                          style={{ fontSize: "25px", cursor: "pointer" }}
                          onClick={() => {
                            setHorarios(r);
                            for (const row of rowTable.slice(
                              i + 1,
                              rowTable.length
                            )) {
                              if (
                                row.dia_semana === r.dia_semana &&
                                row.e_troca === 0
                              ) {
                                setHorario_inicio_posterior(row.horario_inicio);
                                break;
                              }
                            }
                            for (const row of rowTable.slice(0, i).reverse()) {
                              if (
                                row.dia_semana === r.dia_semana &&
                                row.e_troca === 0
                              ) {
                                setHorario_fim_anterior(row.horario_fim);
                                break;
                              }
                            }
                            setShowModalEdit(true);
                          }}
                        />
                      </td>
                      <td data-title="Trocar Professor">
                        <IoSwapVerticalOutline
                          title="Trocar Professor"
                          style={{ fontSize: "25px", cursor: "pointer" }}
                          onClick={() => {
                            setTrocaProfessor(r);
                            setShowModalEditTroca(true);
                          }}
                        />
                      </td>
                      <td data-title="Excluir">
                        <IoTrashOutline
                          title="Excluir"
                          style={{ fontSize: "25px", cursor: "pointer" }}
                          onClick={() => {
                            handleDelete(r.id, i);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Table>
            <br />
            {data_inicio && data_fim ? (
              <div style={{ display: "flex", marginTop: -5 }}>
                <label style={{ marginTop: 15, marginRight: 10 }}>
                  Vigência:
                </label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <h5>De:</h5>
                  <p style={{ fontSize: 15 }}>{data_inicio}</p>
                </div>
                <div style={{ marginLeft: 30 }}>
                  <h5>Até:</h5>
                  <p style={{ fontSize: 15 }}>{data_fim}</p>
                </div>
              </div>
            ) : (
              <></>
            )}
            <Hr />
            <Inputs>
              <H4>Componente Curricular</H4>
              <H4>Professor</H4>
              <H4>Horário das Aulas</H4>
              <InputLabel>
                <select
                  value={disciplina}
                  onChange={(e) => {
                    setDisciplina(e.target.value);
                    setId_disciplina(
                      e.target[e.target.options.selectedIndex].id
                    );
                  }}
                >
                  <option disabled value="">
                    Selecione a disciplina
                  </option>
                  {disciplinas.map((d, i) => (
                    <option key={i} id={d.id_disciplina}>
                      {d.disciplina}
                    </option>
                  ))}
                </select>
              </InputLabel>
              <InputLabel>
                <select
                  value={colaborador}
                  onChange={(e) => {
                    setColaborador(e.target.value);
                    setMatricula_colaborador(
                      e.target[e.target.options.selectedIndex].id
                    );
                  }}
                >
                  <option disabled value="">
                    Selecione o professor
                  </option>
                  {colaboradores.map((p, i) => (
                    <option key={i} id={p.matricula}>
                      {p.nome}
                    </option>
                  ))}
                </select>
              </InputLabel>
              <InputLabel>
                <select
                  value={dia_semana}
                  onChange={(e) => {
                    setDia_semana(e.target.value);
                  }}
                  id="dia_semana"
                >
                  <option disabled value="">
                    Dia
                  </option>
                  <option>SEG</option>
                  <option>TER</option>
                  <option>QUA</option>
                  <option>QUI</option>
                  <option>SEX</option>
                  <option>SÁB</option>
                </select>
              </InputLabel>
              <input
                type="time"
                value={horario_inicio}
                onChange={(e) => {
                  setHorario_inicio(e.target.value);
                }}
              />
              <input
                type="time"
                value={horario_fim}
                onChange={(e) => {
                  setHorario_fim(e.target.value);
                }}
              />
              <ButtonAdd type="button" onClick={() => sendObj()}>
                +
              </ButtonAdd>
            </Inputs>
            <span style={{ display: "flex" }}>
              <BigButton
                style={{ display: changed ? "" : "none" }}
                type="submit"
              >
                Salvar Alterações
              </BigButton>
              <Link
                to="/lancamentoturmas"
                className="link"
                style={{ display: changed ? "" : "none" }}
              >
                <BigButton style={{ background: "#e8343d" }}>
                  Cancelar
                </BigButton>
              </Link>
            </span>
          </form>
        </Form>
        <ModalEdit
          showModalEdit={showModalEdit}
          setShowModalEdit={setShowModalEdit}
          dados={horarios}
          turma={turma} 
          horario_inicio_posterior={horario_inicio_posterior}
          horario_fim_anterior={horario_fim_anterior}
          rowTable={rowTable}
        />
        <ModalTrocaProfessor
          showModal={showModalEditTroca}
          setShowModal={setShowModalEditTroca}
          data={trocaProfessor}
          idTurma={location.state.id_turma}
          idCurso={location.state.id_curso}
          idCentro_custo={location.state.id_centro_custo}
          dataInicio={location.state.data_inicio}
          dataFim={location.state.data_fim}
        />
      </Container>
    </Background>
  );
}
