import { Background, Text, Image, Title, Container } from "./style";
import dev from "../../../assets/Images/dev.jpeg";
import React, { useState } from "react";

export default function Home() {
  const [hidden, setHidden] = useState(true);

  return (
    <Background>
      <Container>
        <Title>Home Perfil Dev</Title>
      </Container>

      <Image hidden={hidden} onClick={() => setHidden(!hidden)}>
        <Text>Bem-Vindo Dev!</Text>
        <img alt="Erro!" src={dev} />
      </Image>
    </Background>
  );
}
