import styled from "styled-components";

import { Link as Rota } from "react-router-dom";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`;

export const ContentForm = styled.div`
  width: 40%;
  height: 50%;
  background: #fff;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  min-height: 479px;
  min-width: 289px;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:nth-child(1) {
    /* background: linear-gradient(90deg, rgba(63,58,148,1) 0%, rgba(0,212,255,1) 100%); */
    background-color: #0093e9;
    background-image: linear-gradient(
      0deg,
      #0093e9 0%,
      #99d4f7 50%,
      #ffffff 100%
    );
  }
  &:nth-child(2) {
    background-color: #0093e9;
    background-image: linear-gradient(
      0deg,
      #0093e9 0%,
      #99d4f7 50%,
      #ffffff 100%
    );
  }

  @media (max-width: 688px) {
    &:nth-child(1) {
      width: 20% !important;
    }
    &:nth-child(2) {
      width: 80% !important;
    }
  }
  @media (max-width: 410px) {
    &:nth-child(1) {
      display: none;
    }
  }
`;

export const Hr = styled.div`
  background-color: #0093e9;
  height: 0.19rem;
  width: 92%;
  margin-top: 15px;
`;

export const Header = styled.div`
  display: flex;
  margin-top: -42px;
`;

export const TituloLogo = styled.div`
  font-family: "Roboto", sans-serif;
  color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  h6 {
    font-size: 22px;
  }

  legend {
    font-size: 32px;
    font-weight: bold;
  }

  h1 {
    font-size: 65px;
    @media (max-width: 768px) {
      font-size: 35px;
    }
    @media (max-width: 688px) {
      display: none;
    }
  }
  legend {
    color: black;
    @media (max-width: 768px) {
      font-size: 26px;
    }
    @media (max-width: 515px) {
      font-size: 18px;
    }
  }

  img {
    display: flex;
    object-fit: contain;
    width: 100%;
    height: 200px;

    @media (max-width: 768px) {
      width: 380px;
      height: 300px;
    }
    @media (max-width: 688px) {
      width: 300px;
      height: 180px;
    }
    @media (max-width: 515px) {
      width: 150px;
      height: 100px;
    }
  }

  span {
    font-size: 0.8rem;
  }
`;

export const Form = styled.form`
  margin-top: 10px;
`;

export const FormController = styled.div`
  display: flex;
  flex-direction: column;

  span {
    color: black;
    align-self: center;
    text-align: center;
    margin-top: 12px;
    width: fit-content;
    border-bottom: 1px solid black;
    cursor: pointer;

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }

  input {
    border-radius: 5px;
    height: 30px;
    text-align: center;
    border: 2px solid black;
    caret-color: #0093e9;
  }

  label {
    color: #000;
    margin-top: 14px;
  }

  button {
    width: 200px;
    margin-top: 15px;
    background-color: #0093e9;
    border: none;
    border-radius: 15px;
    height: 35px;
    cursor: pointer;
    color: white;
    transition: linear 0.2s;
    &:hover {
      background-color: #0073e9;
    }
  }
`;

export const Link = styled(Rota)`
  text-decoration: none;
  color: #00d4ff;
`;

export const Titulo = styled.h1`
  color: #0093e9;
  text-align: center;
`;
