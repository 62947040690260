import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Background,
  Hr,
  Titulo,
  Button,
  Input,
  Row,
  Table,
  AccessUserIcon,
} from "./style";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import Pagination from "../../../components/Pagination";
import ModalRegister from "../../../components/Modal/Rh/Usuarios/Register";
import ModalEdit from "../../../components/Modal/Rh/Usuarios/Edit";
import { isAuthenticatedDev } from "../../../auth";
import { IoPencilOutline, IoTrashOutline, IoKeyOutline } from "react-icons/io5";

export default function Usuarios() {
  const history = useHistory();
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [usuario, setUsuario] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    api
      .get(`/rh/users?nome=${search}&dev=${isAuthenticatedDev()}`)
      .then((response) => {
        setUsuarios(response.data.usuarios);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [search]);

  useEffect(() => {
    setTotalPages(Math.ceil(usuarios.length / 15));
  }, [usuarios]);

  async function handleDelete(id) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "O usuário será excluído permanentemente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Excluir",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await api.delete(`/rh/users/${id}`);
        if (response.data.error) {
          return Swal.fire({
            title: "Exclusão não permitida!",
            text: response.data.error,
            icon: "warning",
            confirmButtonColor: "var(--blue-color)",
          });
        }
        Toast.fire({
          icon: "success",
          title: "Excluído com sucesso!",
        });
        setUsuarios(usuarios.filter((usuario) => usuario.id !== id));
      }
    });
  }

  async function handleRecover(email) {
    const data = {
      email: email,
    };
    const response = await api.patch(`/rh/recuperarsenha`, { data });
    if (response.data.error) {
      return Swal.fire(
        "Não foi possível enviar!",
        response.data.error,
        "warning"
      );
    }
    Swal.fire({
      title: "Enviado !",
      text: "A nova senha foi enviada ao email",
      iconHtml:
        '<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzj2ghs3ry4uq9Wv8zQ0VaAckjJ4jKShq0Fw&usqp=CAU">',
    });
  }

  async function accessUser(id, tipo) {
    const data = { id: id, accessUser: true, perfil: tipo };
    try {
      await api.patch(`/dev/accessuser`, { data }).then((response) => {
        if (response.data.error)
          return Swal.fire({
            title: "Atenção!",
            text: "Não foi possível acessar!",
            icon: "warning",
            confirmButtonColor: "var(--blue-color)",
          });
        localStorage.setItem("token", response.data.token);
        switch (tipo) {
          case "COORD":
            history.push("/coord");
            break;
          case "RH":
            history.push("/rh");
            break;
          case "SEC":
            history.push("/sec");
            break;
          case "DIR":
            history.push("/dir");
            break;
          default:
            break;
        }
      });
    } catch (err) {
      Swal.fire({
        title: "Atenção!",
        text: "Não foi possível acessar!",
        icon: "warning",
        confirmButtonColor: "var(--blue-color)",
      });
    }
  }

  return (
    <Background>
      <Container>
        <Titulo>Controle de Usuários</Titulo>
        <Hr />
        <Row>
          <Button
            onClick={() => {
              setShowModalRegister(true);
            }}
          >
            Novo
          </Button>
          <Input>
            <input
              value={search}
              onChange={(e) => {
                setSearch(e.target.value.toUpperCase());
                setPage(1);
              }}
              type="search"
              placeholder="Pesquisar nome..."
            />
          </Input>
        </Row>
        <Table>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>E-mail</th>
                <th>Perfil</th>
                <th></th>
                <th></th>
                <th></th>
                <th hidden={!isAuthenticatedDev()}></th>
              </tr>
            </thead>
            <tbody>
              {usuarios.slice((page - 1) * 15, page * 15).map((u, i) => (
                <tr key={i}>
                  <td data-title="ID" className="td-left">
                    {u.id}
                  </td>
                  <td data-title="Nome" value={u.nome} className="td-left">
                    {u.nome}
                  </td>
                  <td data-title="E-mail" value={u.email} className="td-left">
                    {u.email}
                  </td>
                  <td data-title="Perfil" value={u.perfil} className="td-left">
                    {u.perfil}
                  </td>
                  <td data-title="Editar" className="center">
                    <IoPencilOutline
                      title="Editar"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={() => {
                        setUsuario(u);
                        setShowModalEdit(true);
                      }}
                    />
                  </td>
                  <td data-title="Excluir" className="center">
                    <IoTrashOutline
                      title="Excluir"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={async () => {
                        await handleDelete(u.id);
                      }}
                    />
                  </td>
                  <td
                    data-title="Recuperar senha do usuário"
                    className="center"
                  >
                    <IoKeyOutline
                      onClick={async () => {
                        await Swal.fire({
                          title: "Recuperar senha",
                          text:
                            "Deseja gerar uma nova senha para este usuário? " +
                            " A senha gerada será enviada ao email cadastrado",
                          icon: "question",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          cancelButtonText: "Cancelar",
                          confirmButtonText: "Sim",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            handleRecover(u.email);
                          }
                        });
                      }}
                      title="Recuperar Senha"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                    />
                  </td>
                  <td className="center" hidden={!isAuthenticatedDev()}>
                    <AccessUserIcon
                      onClick={() =>
                        u.perfil !== "DEV" && accessUser(u.id, u.perfil)
                      }
                      title="Acessar Usuário"
                      disabled={u.perfil === "DEV"}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Table>
        <ModalRegister
          showModal={showModalRegister}
          setShowModal={setShowModalRegister}
        />
        <ModalEdit
          showModal={showModalEdit}
          setShowModal={setShowModalEdit}
          usuario={usuario}
        />
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Container>
    </Background>
  );
}
