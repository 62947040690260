import { Container, Selector, Input, ButtonRight, ButtonLeft, SerchArea } from './styles';
import { IoMdClose } from 'react-icons/io';
import InputMask from 'react-input-mask'
import './styles.css'

export default function Pagination({ text1, text2, selected, setSelected, placeholder1, placeholder2, search, setSearch, cpf }) {
    return (
        <Container>
            <Selector
                id="select"
            >DOAÇÂO</Selector>
            <ButtonLeft
                id="buttonCpf"
                onClick={() => {
                    document.getElementById("select").style.transform = "translateX(0%)";
                    document.getElementById("buttonCpf").style.color = "white";
                    document.getElementById("buttonAcao").style.color = "black";
                    setSelected(0);
                    setSearch('');
                }}
            >
                {text1}
            </ButtonLeft>
            <ButtonRight
                id="buttonAcao"
                onClick={() => {
                    document.getElementById("select").style.transform = "translateX(103%)";
                    document.getElementById("buttonCpf").style.color = "black";
                    document.getElementById("buttonAcao").style.color = "white";
                    setSelected(1);
                    setSearch('');
                }}
            >
                {text2}
            </ButtonRight>
            <SerchArea>
                {cpf === "0" && selected === 0
                    ?
                    <InputMask
                        type="text"
                        mask='999.999.999-99'
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        className="mask"
                        placeholder={placeholder1}
                    />
                    :
                    cpf === "1" && selected === 1
                        ?
                        <InputMask
                            type="text"
                            mask='999.999.999-99'
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            className="mask"
                            placeholder={placeholder2}
                        />
                        :
                        <Input
                            type='search'
                            placeholder={selected === 0 ? placeholder1 : placeholder2}
                            value={search}
                            onChange={e => {
                                setSearch(e.target.value);
                            }}
                        />
                }
                <IoMdClose
                    color='#2B74E0'
                    onClick={() => {
                        setSearch('');
                    }}
                />
            </SerchArea>
        </Container>
    );
}
