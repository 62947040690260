import React, { useState, useEffect } from "react";
import {
  Container,
  Background,
  Hr,
  Titulo,
  Button,
  Input,
  Row,
  Table,
} from "./style";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import ModalRegister from "../../../components/Modal/Sec/Unidades/Register";
import ModalEdit from "../../../components/Modal/Sec/Unidades/Edit";
import Pagination from "../../../components/Pagination";
import { IoPencilOutline, IoTrashOutline } from "react-icons/io5";

export default function Unidades() {
  const [unidades, setUnidades] = useState([]);
  const [unidade, setUnidade] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filtro, setFiltro] = useState("");
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);

  useEffect(() => {
    api
      .get(`/sec/units?nome=${filtro}`)
      .then((response) => {
        setUnidades(response.data.unidades);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [filtro]);

  useEffect(() => {
    setTotalPages(Math.ceil(unidades.length / 15));
  }, [unidades]);

  function handleDelete(id) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "Você não poderá reverter essa ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Deletar",
      cancelButtonColor: "gray",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await api.delete(`/sec/units/${id}`);
        if (response.data.error) {
          return Swal.fire(
            "Exclusão não permitida!",
            response.data.error,
            "warning"
          );
        }
        Toast.fire({
          icon: "success",
          title: "Deletado com sucesso!",
        });
        setUnidades(unidades.filter((unidade) => unidade.id !== id));
      }
    });
  }

  return (
    <Background>
      <Container>
        <Titulo>Controle de Unidades</Titulo>
        <Hr />
        <Row>
          <Button
            onClick={() => {
              setShowModalRegister(true);
            }}
          >
            Novo
          </Button>
          <Input>
            <input
              type="text"
              placeholder="Pesquisar unidade..."
              value={filtro}
              onChange={(e) => {
                setFiltro(e.target.value.toUpperCase());
                setPage(1);
              }}
            />
          </Input>
        </Row>
        <Table className="tabela">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Unidade</th>
                <th>Endereço</th>
                <th style={{ width: "100px" }}>CEP</th>
                <th>Telefone</th>
                <th>Cidade</th>
                <th>Estado</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {unidades.slice((page - 1) * 15, page * 15).map((u, i) => (
                <tr key={i}>
                  <td data-title="ID" value={u.id}>
                    {u.id}
                  </td>
                  <td data-title="Unidade" title={u.nome} className="td-left">
                    {u.nome.substring(0, 30)}
                    {u.nome.length > 30 && "..."}
                  </td>
                  <td
                    data-title="Endereço"
                    title={u.endereco}
                    className="td-left"
                  >
                    {u.endereco.slice(0, 30)}
                    {u.endereco.length > 30 && "..."}
                  </td>
                  <td data-title="CEP" value={u.cep}>
                    {u.cep}
                  </td>
                  <td data-title="Telefone" value={u.telefone}>
                    {u.telefone ? u.telefone : "-"}
                  </td>
                  <td data-title="Cidade" value={u.cidade} className="td-left">
                    {u.cidade}
                  </td>
                  <td data-title="Estado" value={u.estado}>
                    {u.estado}
                  </td>
                  <td data-title="Editar">
                    <IoPencilOutline
                      title="Editar"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={() => {
                        setUnidade(u);
                        setShowModalEdit(true);
                      }}
                    />
                  </td>
                  <td data-title="Excluir">
                    <IoTrashOutline
                      title="Excluir"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={() => {
                        handleDelete(u.id);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Table>
        <ModalRegister
          showModal={showModalRegister}
          setShowModal={setShowModalRegister}
        />
        <ModalEdit
          showModal={showModalEdit}
          setShowModal={setShowModalEdit}
          data={unidade}
        />
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Container>
    </Background>
  );
}
