import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
  Input,
  Select,
  Label,
} from "../../ModalEditStyle";
import api from "../../../../services/api";
import Swal from "sweetalert2";

export default function ModalRegister({
  showModal,
  setShowModal,
  deslocamento,
}) {
  const modalRef = useRef();
  const [idOrigem, setIdOrigem] = useState(0);
  const [idDestino, setIdDestino] = useState(0);
  const [valorIda, setValorIda] = useState("");
  const [valorVolta, setValorVolta] = useState("");
  const [centrosCusto, setCentrosCusto] = useState([]);
  const [centroCusto, setCentroCusto] = useState("");
  const [cidades, setCidades] = useState([]);
  const [id, setId] = useState("");
  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  useEffect(() => {
    if (deslocamento) {
      setValorIda(deslocamento.valor_ida);
      setValorVolta(deslocamento.valor_volta);
      setIdOrigem(deslocamento.id_cidade_origem);
      setIdDestino(deslocamento.id_cidade_destino);
      setId(deslocamento.id);
      setCentroCusto(deslocamento.id_centro_custo);
    }
  }, [deslocamento]);

  useEffect(() => {
    api
      .get(`/rh/cidades`)
      .then((response) => {
        setCidades(response.data.cidades);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/rh/costcenter`)
      .then((response) => {
        setCentrosCusto(response.data.centros_custo);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      id: id,
      id_centro_custo: centroCusto,
      id_cidade_origem: idOrigem,
      id_cidade_destino: idDestino,
      valor_ida: valorIda,
      valor_volta: valorVolta,
    };
    try {
      const response = await api.patch(`/rh/deslocamentos`, {
        data,
      });
      if (response.data.error) {
        return Swal.fire("Atenção!", response.data.error, "warning");
      }
      Toast.fire({
        icon: "success",
        title: "Editado com sucesso!",
      });
      setShowModal(!showModal);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      alert(
        error
      ); /*Swal('Atenção!', 'Serviço indisponível no momento', 'error')*/
    }
  }
  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              setShowModal(!showModal);
              // clear();
            }
          }}
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form onSubmit={handleSubmit}>
                <h3>Atualizar Deslocamento</h3>
                <FormContent>
                  <Label>Centro de custo:</Label>
                  <Select
                    value={centroCusto}
                    onChange={(e) => {
                      setCentroCusto(e.target.value);
                    }}
                  >
                    <option disabled value="">
                      Selecione o centro de custo
                    </option>
                    {centrosCusto.map((c, i) => (
                      <option key={i} id={c.id} value={c.id}>
                        {c.nome}
                      </option>
                    ))}
                  </Select>
                  <Label>Origem:</Label>
                  <Select
                    value={idOrigem}
                    onChange={(e) => {
                      setIdOrigem(e.target[e.target.options.selectedIndex].id);
                    }}
                  >
                    <option disabled value={0}>
                      Selecione a cidade
                    </option>
                    {cidades.map((c, i) => (
                      <option key={i} id={c.id} value={c.id}>
                        {c.nome.toUpperCase()}
                      </option>
                    ))}
                  </Select>
                  <Label>Destino:</Label>
                  <Select
                    value={idDestino}
                    onChange={(e) => {
                      setIdDestino(e.target[e.target.options.selectedIndex].id);
                    }}
                  >
                    <option disabled value={0}>
                      Selecione a cidade
                    </option>
                    {cidades.map((c, i) => (
                      <option key={i} id={c.id} value={c.id}>
                        {c.nome.toUpperCase()}
                      </option>
                    ))}
                  </Select>
                  <Label>Valor da Ida:</Label>
                  <Input
                    type="number"
                    pattern="[0-9]+([,\.][0-9]+)?"
                    min="0"
                    step="any"
                    value={valorIda}
                    placeholder="R$"
                    onChange={(e) => setValorIda(e.target.value)}
                    required
                  />
                  <Label>Valor da Volta:</Label>
                  <Input
                    type="number"
                    value={valorVolta}
                    placeholder="R$"
                    onChange={(e) => setValorVolta(e.target.value)}
                    required
                  />
                </FormContent>
                <br />
                <Button type="submit">Atualizar</Button>
              </Form>
              <CloseModalButton
                onClick={() => {
                  setShowModal(!showModal);
                  // clear();
                }}
              />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
