import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import { Swal } from "../../../services/SwalTemplates";
import GoBack from "../../../components/GoBack";
import {
  Background,
  Container,
  Titulo,
  Hr,
  Form,
  InputLabel,
  BigButton,
} from "./style";
import "./styles.css";

export default function TrocaProfessorDetalhe() {
  const [professores, setProfessores] = useState([]);
  const [disciplinas, setDisciplinas] = useState([]);
  const [centrosCusto, setCentrosCusto] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [turmas, setTurmas] = useState([]);
  const [disciplina, setDisciplina] = useState([]);
  const [curso, setCurso] = useState([]);
  const [centroCusto, setCentroCusto] = useState([]);
  const [turma, setTurma] = useState([]);

  useEffect(() => {
    api
      .get(`coord/courses`)
      .then((response) => {
        setCursos(response.data.cursos);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/classes`)
      .then((response) => {
        setTurmas(response.data.turmas);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/subjects`)
      .then((response) => {
        setDisciplinas(response.data.disciplinas);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/costcenter`)
      .then((response) => {
        setCentrosCusto(response.data.centros_custo);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/contributors`)
      .then((response) => {
        setProfessores(response.data.colaboradores);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, []);

  return (
    <Background>
      <GoBack />
      <Container>
        <Titulo>
          <h1>Troca de Professor</h1>
        </Titulo>
        <Hr />
        <Form>
          <form>
            <InputLabel>
              <label>Curso</label>
              <select
                value={curso}
                placeholder="Curso"
                onChange={(e) => {
                  setCurso(e.target.value);
                }}
                required
              >
                {" "}
                <option selected disabled value="">
                  Selecione o Curso
                </option>
                {cursos.map((c, i) => (
                  <option id={c.id}>{c.nome}</option>
                ))}
              </select>
            </InputLabel>
            <InputLabel>
              <label>Centro de custo</label>
              <select
                value={centroCusto}
                placeholder="Centro de custo"
                onChange={(e) => {
                  setCentroCusto(e.target.value);
                }}
              >
                <option selected disabled value="">
                  Selecione o Centro de Custo
                </option>
                {centrosCusto.map((c, i) => (
                  <option id={c.id}>{c.nome}</option>
                ))}
              </select>
            </InputLabel>
            <InputLabel>
              <label>Turma</label>
              <select
                value={turma}
                placeholder="Turma"
                onChange={(e) => {
                  setTurma(e.target.value);
                }}
              >
                <option selected disabled value="">
                  Selecione a Turma
                </option>
                {turmas.map((t, i) => (
                  <option id={t.id}>{t.nome}</option>
                ))}
              </select>
            </InputLabel>
            <InputLabel>
              <label>Componente Curricular</label>
              <select
                value={disciplina}
                placeholder="Disciplina"
                onChange={(e) => {
                  setDisciplina(e.target.value);
                }}
              >
                <option selected disabled value="">
                  Selecione o Componente
                </option>
                {disciplinas.map((d, i) => (
                  <option id={d.id}>{d.nome}</option>
                ))}
              </select>
            </InputLabel>
            <br />
            <div style={{ display: "flex" }}>
              <label style={{ marginTop: 20, marginRight: 10 }}>
                No Período
              </label>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h5>Início:</h5>
                <input type="date" name="date" id="" required />
              </div>
              <div>
                <h5>Fim:</h5>
                <input type="date" name="date" id="" required />
              </div>
            </div>
            <InputLabel>
              <label style={{ marginTop: 15 }}>Considerar o professor</label>
              <select>
                {professores.map((p, i) => (
                  <option id={p.id}>{p.nome}</option>
                ))}
              </select>
            </InputLabel>
          </form>
          {/* AS TABELAS SERÃO GERADAS APÓS APERTAR NO BOTÃO */}
        </Form>
        <br />
        <BigButton style={{ width: "120px" }}>Salvar</BigButton>
        <Link to="/lanctoturmas" className="link">
          <BigButton style={{ background: "#e8343d" }}>Cancelar</BigButton>
        </Link>
      </Container>
    </Background>
  );
}
