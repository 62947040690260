import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Background, Hr, Titulo, Input, Row, Table } from "./style";
import api from "../../../services/api";
import { Swal } from "../../../services/SwalTemplates";
import Pagination from "../../../components/Pagination";

export default function Consulta() {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [professores, setProfessores] = useState([]);
  const [professor, setProfessor] = useState("");
  const [id_professor, setId_professor] = useState(0);
  const [consulta, setConsulta] = useState([]);

  useEffect(() => {
    api
      .get(`/coord/contributors`)
      .then((response) => {
        setProfessores(response.data.colaboradores);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [history]);

  useEffect(() => {
    api
      .get(`/coord/turmacolaborador?matricula=${id_professor}`)
      .then((response) => {
        setConsulta(response.data.turma_colaborador[0]);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [history, id_professor]);

  useEffect(() => {
    setTotalPages(Math.ceil(consulta.length / 15));
  }, [consulta]);

  return (
    <Background>
      <Container>
        <Titulo>Consulta de Horários</Titulo>
        <Hr />
        <Row>
          <Input>
            <div style={{ display: "flex" }}>
              <select
                value={professor}
                onChange={(e) => {
                  setProfessor(e.target.value.toUpperCase());
                  setId_professor(e.target[e.target.options.selectedIndex].id);
                  setPage(1);
                }}
              >
                <option disabled value="">
                  Selecione o professor
                </option>
                {professores.map((p, i) => (
                  <option key={i} id={p.matricula}>
                    {p.nome}
                  </option>
                ))}
              </select>
            </div>
          </Input>
        </Row>
        <Table>
          <table>
            <thead>
              <tr>
                <th>Dia Semana</th>
                <th>Unidade</th>
                <th>Turma</th>
                <th>Horário Início</th>
                <th>Horário Término</th>
              </tr>
            </thead>
            <tbody>
              {consulta.slice((page - 1) * 15, page * 15).map((c, i) => (
                <tr key={i}>
                  <td data-title="Dia da semana" value={c.nome}>
                    {c.dia_semana}
                  </td>
                  <td data-title="Unidade" value={c.nome}>
                    {c.unidade}
                  </td>
                  <td data-title="Turma" value={c.id} className="td-left">
                    {c.turma
                      .replaceAll("TÉCNICO EM ", "")
                      .replaceAll("CURSO TÉCNICO ", "")
                      .replaceAll("TECNÓLOGO EM ", "")}
                  </td>
                  <td data-title="Horário Início" value={c.nome}>
                    {c.horario_inicio}
                  </td>
                  <td data-title="Horário Fim" value={c.nome}>
                    {c.horario_fim}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Table>
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Container>
    </Background>
  );
}
