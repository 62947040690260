import styled from "styled-components";

export const Button = styled.button`
  background: white;
  border: none;
  cursor: pointer;
  width: 0;
  height: 0;
  position: relative;
`;
