import React, { useState, useEffect, useRef } from "react";
import { useSpring, animated } from "react-spring";
import api from "../../../../services/api";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  TableForm,
  CheckBox,
  Button,
  Input,
  RowCenter,
  Hr,
  InputLabel,
} from "./style";
import Auth from "../../../../services/auth";
import Swal from "sweetalert2";
import Pagination from "../../../Pagination";
import { IoMdCheckmark } from "react-icons/io";

export default function RegisterGradesCurriculares({
  showModal,
  setShowModal,
  outputData,
  setOutputData,
  setTitlePage,
  defaultModulo,
  setDefaultModulo,
}) {
  const modalRef = useRef();
  const [search, setSearch] = useState("");
  const [id] = useState(Auth.id());
  const [disciplinas, setDisciplinas] = useState([]);
  const [disciplinasConst, setDisciplinasConst] = useState([]);
  const [disciplinasChecked, setDisciplinasChecked] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [modulo, setModulo] = useState("");
  const [modulos] = useState([
    "MOD/Série I",
    "MOD/Série II",
    "MOD/Série III",
    "MOD/Série IV",
    "MOD/Série V",
    "MOD/Série VI",
    "MOD/Série VII",
    "MOD/Série VIII",
    "MOD/Série IX",
    "MOD/Série X",
  ]);

  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });

  useEffect(() => {
    api
      .get(`/coord/disciplinagrade?idCoord=${id}&nome=${search}`)
      .then((response) => {
        setDisciplinas(response.data.disciplinas);
        if (disciplinasConst.length === 0) {
          setDisciplinasConst(response.data.disciplinas);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    setPage(1);
  }, [disciplinasConst, id, search]);

  useEffect(() => {
    setModulo(defaultModulo);
  }, [defaultModulo]);

  useEffect(() => {
    setTotalPages(Math.ceil(disciplinas.length / 6));
  }, [disciplinas]);

  async function closeModal() {
    setShowModal(false);
    setDisciplinasChecked([]);
    setModulo("");
    setPage(1);
    setSearch("");
    setDefaultModulo("");
  }

  /**
   * Ordena a array com relação ao módulo
   * @param {*} a
   * @param {*} b
   * @returns
   */
  function orderModulo(a, b) {
    if (a.modulo < b.modulo) {
      return -1;
    }
    if (a.modulo > b.modulo) {
      return 1;
    }
    return 0;
  }

  function handleSubmit() {
    if (!modulo || disciplinasChecked?.length === 0) {
      return Swal.fire({
        title: "Atenção!",
        text: "Selecione o módulo e as disciplinas!",
        icon: "warning",
        confirmButtonColor: "var(--blue-color)",
      });
    }

    let id_disciplinas = [];
    outputData.forEach((x) => {
      x.dados.forEach((y) => {
        id_disciplinas.push(y.id_disciplina);
      });
    });

    if (
      disciplinasChecked.filter((d) => id_disciplinas.includes(d)).length > 0
    ) {
      return Swal.fire({
        title: "Atenção!",
        text: "Disciplinas já inseridas!",
        icon: "warning",
        confirmButtonColor: "var(--blue-color)",
      });
    }

    setOutputData(
      outputData.filter((o) => o.modulo === modulo).length === 0
        ? [
            ...outputData,
            {
              modulo: modulo,
              dados: disciplinasConst?.filter((d) =>
                disciplinasChecked?.includes(d?.id_disciplina)
              ),
            },
          ].sort(orderModulo)
        : [
            ...outputData.filter((o) => o.modulo !== modulo),
            {
              modulo: modulo,
              dados: [
                ...outputData[outputData.findIndex((o) => o.modulo === modulo)]
                  .dados,
                ...disciplinasConst?.filter((d) =>
                  disciplinasChecked?.includes(d?.id_disciplina)
                ),
              ],
            },
          ].sort(orderModulo)
    );
    setTitlePage(modulo);
    closeModal();
  }

  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              closeModal();
            }
          }}
        >
          <animated.div style={animation}>
            <ModalContent>
              <h3 style={{ textAlign: "center" }}>
                Cadastro da Matriz Curricular
              </h3>
              <Hr />
              <InputLabel>
                <label>Módulo</label>
                <select
                  value={modulo}
                  onChange={(e) => setModulo(e.target.value)}
                  required
                >
                  <option disabled value="">
                    Selecione o módulo
                  </option>
                  {modulos.map((m, i) => (
                    <option value={m} key={i}>
                      {m}
                    </option>
                  ))}
                </select>
              </InputLabel>
              {/* <h3 style={{ textAlign: "center" }}></h3> */}
              <Hr />
              <Form>
                <Input
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value.toUpperCase());
                  }}
                  type="search"
                  placeholder="Pesquisar disciplina..."
                />
                <TableForm>
                  <table style={{ width: "600px" }}>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Nome</th>
                        <th>Carga Horária</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {disciplinas
                        .slice((page - 1) * 6, page * 6)
                        .map((d, i) => (
                          <tr key={i}>
                            <td>{d.id_disciplina}</td>
                            <td>{d.disciplina}</td>
                            <td>{d.carga_horaria}</td>
                            <td>
                              <CheckBox
                                checked={disciplinasChecked.includes(
                                  d.id_disciplina
                                )}
                                onClick={() => {
                                  if (
                                    disciplinasChecked.includes(d.id_disciplina)
                                  ) {
                                    setDisciplinasChecked((disciplina) =>
                                      disciplina.filter(
                                        (h, index) => h !== d.id_disciplina
                                      )
                                    );
                                  } else {
                                    setDisciplinasChecked([
                                      ...disciplinasChecked,
                                      d.id_disciplina,
                                    ]);
                                  }
                                }}
                              >
                                <IoMdCheckmark
                                  style={{
                                    transition: "all 0.2s ease-in-out",
                                  }}
                                  color={
                                    disciplinasChecked.includes(d.id_disciplina)
                                      ? "var(--blue-color)"
                                      : "transparent"
                                  }
                                />
                              </CheckBox>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </TableForm>
              </Form>
              <Pagination
                page={page}
                setPage={setPage}
                totalPages={totalPages}
              />
              <RowCenter>
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Adicionar
                </Button>
              </RowCenter>
              <CloseModalButton
                onClick={() => {
                  closeModal();
                }}
              />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
