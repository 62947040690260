import { useHistory } from "react-router-dom";
import { Button } from "./style";
import { IoArrowBackOutline } from "react-icons/io5";

const GoBack = () => {
  const history = useHistory();
  return (
    <Button
      onClick={() => {
        history.goBack();
      }}
    >
      <IoArrowBackOutline title="Voltar" style={{ fontSize: "40px" }} />
    </Button>
  );
};

export default GoBack;
