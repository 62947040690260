import React, { useState, useEffect } from "react";
import { Container, MenuButton, Button } from "./style";
import { useHistory } from "react-router-dom";
import Auth from "../../services/auth";
import { STATIC_URL_DEV, STATIC_URL_PROD } from "../../assets/json/texts.json";
import {
  isAuthenticatedRh,
  isAuthenticatedSecretaria,
  isAuthenticatedCoordenador,
} from "../../auth";
import { IoDocumentText } from "react-icons/io5";
import { RiLockPasswordLine, RiArrowGoBackLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../services/api";
import routes from "../../assets/json/routes.json";

export default function Documentation() {
  const history = useHistory();
  const [auth, setAuth] = useState(Auth.auth());
  const [open, setOpen] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [name, setName] = useState(
    isAuthenticatedRh()
      ? "rh"
      : isAuthenticatedSecretaria()
      ? "sec"
      : isAuthenticatedCoordenador() && "coord"
  );
  const [showManual, setShowManual] = useState(false);
  const [showReturn, setShowReturn] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setHidden(!routes.routes.includes(location.pathname));
    setShowManual(
      isAuthenticatedRh() ||
        isAuthenticatedSecretaria() ||
        isAuthenticatedCoordenador()
    );
    setName(
      isAuthenticatedRh()
        ? "rh"
        : isAuthenticatedSecretaria()
        ? "sec"
        : isAuthenticatedCoordenador() && "coord"
    );
    setAuth(Auth.auth());
    setOpen(false);
    setShowReturn(Auth.accessUser());
  }, [location.pathname]);

  async function openPdf() {
    window.open(
      window.location.href.includes("localhost")
        ? `${STATIC_URL_DEV}/manual${name}?auth=${auth}`
        : `${STATIC_URL_PROD}/manual${name}?auth=${auth}`
    );
  }

  async function changePassword() {
    const { value: password } = await Swal.fire({
      title: "Alteração de Senha",
      input: "password",
      confirmButtonText: "Alterar",
      confirmButtonColor: "var(--blue-color)",
      // inputLabel: "Alterar senha",
      inputPlaceholder: "Insira uma nova senha",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
      },
      inputValidator: (value) => {
        if (value.length < 6)
          return "A senha deve possuir pelo menos 6 caracteres!";
      },
    });

    const data = {
      senha: password,
    };

    if (password) {
      const response = await api.patch(`/mudarsenha`, { data });
      if (response.data.error) {
        return Swal.fire({
          title: "Exclusão não permitida!",
          text: response.data.error,
          icon: "warning",
          confirmButtonColor: "var(--blue-color)",
        });
      }
      Swal.fire({
        title: "Sucesso!",
        text: "Senha alterada com sucesso!",
        icon: "success",
        confirmButtonColor: "var(--blue-color)",
      }).then(() => {
        localStorage.removeItem("token");
        history.push("/");
      });
    }
  }

  async function returnAccessUser() {
    const data = { accessUser: false };
    try {
      await api.patch(`/dev/accessuser`, { data }).then((response) => {
        if (response.data.error)
          return Swal.fire({
            title: "Atenção!",
            text: "Não foi possível acessar!",
            icon: "warning",
            confirmButtonColor: "var(--blue-color)",
          });
        localStorage.setItem("token", response.data.token);
        history.push("/usuarios");
      });
    } catch (err) {
      Swal.fire({
        title: "Atenção!",
        text: "Não foi possível acessar!",
        icon: "warning",
        confirmButtonColor: "var(--blue-color)",
      });
    }
  }

  return (
    <Container hidden={hidden}>
      <Button
        show={showReturn}
        open={open}
        onClick={() => returnAccessUser()}
        title={"Voltar para o usuário padrão"}
      >
        <RiArrowGoBackLine />
      </Button>
      <Button
        show={showManual}
        open={open}
        onClick={() => openPdf()}
        title={"Manual"}
      >
        <IoDocumentText />
      </Button>
      <Button
        show={true}
        open={open}
        onClick={() => changePassword()}
        title={"Alterar Senha"}
      >
        <RiLockPasswordLine />
      </Button>
      <MenuButton
        open={open}
        onClick={() => setOpen(!open)}
        title={"Alterar Senha / Manual"}
      >
        <div>
          <div />
        </div>
        <div />
        <div>
          <div />
        </div>
        <div />
        <div />
        <div />
        <div>
          <div />
        </div>
        <div />
        <div>
          <div />
        </div>
      </MenuButton>
    </Container>
  );
}
