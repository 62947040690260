import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
  Input,
  Select,
  Label,
  CheckBox,
} from "./style";
import { IoMdCheckmark } from "react-icons/io";
import Auth from "../../../../services/auth";
import api from "../../../../services/api";
import { Swal, Toast } from "../../../../services/SwalTemplates";
import AnoSemestre from "../../../../services/AnoSemestre";

export default function ModalRegister({ showModal, setShowModal }) {
  const [idAuth] = useState(Auth.id());
  const [cursos, setCursos] = useState([]);
  const [turnos, setTurnos] = useState([]);
  const [curso, setCurso] = useState("");
  const [turno, setTurno] = useState("");
  const [unidades, setUnidades] = useState([]);
  const [unidade, setUnidade] = useState("");
  const [centroCusto, setCentroCusto] = useState("");
  const [centros_custo, setCentros_custo] = useState([]);
  const [grade, setGrade] = useState("");
  const [id_grade, setId_grade] = useState(0);
  const [id_unidade, setId_unidade] = useState(0);
  const [id_curso, setId_curso] = useState(0);
  const [id_centro_custo, setId_centro_custo] = useState(0);
  const [idTurno, setIdTurno] = useState(0);
  const [data_inicio, setData_inicio] = useState("");
  const [data_fim, setData_fim] = useState("");
  const [ano_semestre, setAno_semestre] = useState("");
  const [ano_semestre_grade, setAno_semestre_grade] = useState([]);
  const [modulo, setModulo] = useState("");
  const [Parceiro, setParceiro] = useState("");
  const [TemParceiro, setTemParceiro] = useState(false);
  const [modulos, setModulos] = useState([]);
  const modalRef = useRef();
  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  useEffect(() => {
    api
      .get(`/coord/units?idCoord=${idAuth}`)
      .then((response) => {
        setUnidades(response.data.unidades);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    if (id_unidade) {
      api
        .get(`/coord/costcenter?idCoord=${idAuth}&idUnidade=${id_unidade}`)
        .then((response) => {
          setCurso("");
          setId_curso(0);
          setCursos([]);
          setCentros_custo([]);
          setCentros_custo(response.data.centros_custo);
        })
        .catch((error) => {
          setCurso("");
          setId_curso(0);
          setCursos([]);
          setCentros_custo([]);
          Swal.fire({
            title: "Atenção!",
            text: "Não foi possível consultar os dados!",
            icon: "error",
          });
        });
    }
    if (id_unidade && id_centro_custo) {
      api
        .get(
          `/coord/courses?idCoord=${idAuth}&idUnidade=${id_unidade}&idCentroCusto=${id_centro_custo}`
        )
        .then((response) => {
          setCursos([]);
          setCursos(response.data.cursos);
        })
        .catch((error) => {
          setId_grade(0);
          setGrade("");
          setAno_semestre_grade([]);
          setCursos([]);
          Swal.fire({
            title: "Atenção!",
            text: "Não foi possível consultar os dados!",
            icon: "error",
          });
        });
    }
    api
      .get(`/coord/turnos`)
      .then((response) => {
        setTurnos([]);
        setTurnos(response.data.turnos);
      })
      .catch((error) => {
        setTurnos([]);
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [idAuth, id_centro_custo, id_unidade]);

  useEffect(() => {
    api
      .get(
        `/coord/descricaograde?idCurso=${id_curso}&idCentroCusto=${id_centro_custo}`
      )
      .then((response) => {
        setModulo("");
        setModulos([]);
        setAno_semestre_grade(response.data.descricao_grade);
      })
      .catch((error) => {
        setModulo("");
        setModulos([]);
        setAno_semestre_grade([]);
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [id_curso, id_unidade, id_centro_custo, grade]);

  useEffect(() => {
    api
      .get(`/coord/modulosgrade?idGrade=${id_grade}`)
      .then((response) => {
        setModulos([]);
        setModulos(response.data.modulos_grade);
      })
      .catch((error) => {
        setModulos([]);
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [id_curso, id_unidade, id_centro_custo, grade, id_grade]);

  /**
   * Vai servir para gerar o pdf do relatório
   * @param {Previne o evento padrão da função} e
   */
  async function handleSubmit(e) {
    e.preventDefault();

    if (data_inicio > data_fim) {
      return Swal.fire({
        title: "Atenção!",
        text: "A data inicial deve ser antes da final!",
        icon: "warning",
      });
    }

    const data = {
      // id: id,
      nome: `${curso} - ${centroCusto} - ${modulo} - ${turno} - ${ano_semestre}${
        TemParceiro ? ` - ${Parceiro}` : ""
      }`,

      id_curso: id_curso,
      id_turno: idTurno,
      id_unidade: id_unidade,
      data_inicio: data_inicio,
      data_fim: data_fim,
      id_centro_custo: id_centro_custo,
      semestre_ano: ano_semestre,
      modulo: modulo,
      curso: curso,
      id_grade: id_grade,
    };

    try {
      const response = await api.post(`/coord/classes`, { data });
      if (response.data.error) {
        return Swal.fire("Atenção!", response.data.error, "warning");
      }
      Swal.fire("Sucesso!", response.data.success, "success");
      Toast.fire({
        icon: "success",
        title: "Cadastrado com sucesso!",
      });
      setShowModal(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      alert(error);
    }
  }

  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              setShowModal(!showModal);
            }
          }}
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form onSubmit={handleSubmit}>
                <h3>Cadastrar Turma</h3>
                <FormContent>
                  <Label>Unidade:</Label>
                  <Select
                    type="name"
                    value={unidade}
                    onChange={(e) => {
                      setUnidade(e.target.value);
                      setId_unidade(
                        e.target[e.target.options.selectedIndex].id
                      );
                    }}
                    required
                  >
                    <option disabled value="">
                      Selecione a unidade
                    </option>
                    {unidades.map((u, i) => (
                      <option key={i} id={u.id}>
                        {u.nome}
                      </option>
                    ))}
                  </Select>
                  <Label>Centro de custo:</Label>
                  <Select
                    type="name"
                    value={centroCusto}
                    onChange={(e) => {
                      setCentroCusto(e.target.value);
                      setId_centro_custo(
                        e.target[e.target.options.selectedIndex].id
                      );
                    }}
                    required
                  >
                    <option disabled value="">
                      {!id_centro_custo
                        ? "Selecione uma unidade"
                        : centros_custo.length
                        ? "Selecione o centro de custo"
                        : "Nenhum centro de custo encontado"}
                    </option>
                    {centros_custo.map((c, i) => (
                      <option id={c.id} key={i}>
                        {c.nome}
                      </option>
                    ))}
                  </Select>
                  <Label>Curso:</Label>
                  <Select
                    type="name"
                    value={curso}
                    onChange={(e) => {
                      setCurso(e.target.value);
                      setId_curso(e.target[e.target.options.selectedIndex].id);
                      setId_grade(0);
                      setGrade("");
                    }}
                    required
                  >
                    <option disabled value="">
                      {!id_centro_custo
                        ? "Selecione o centro de custo"
                        : cursos.length
                        ? "Selecione o curso"
                        : "Nenhum curso encontrado"}
                    </option>
                    {cursos.map((c, i) => (
                      <option key={i} id={c.id}>
                        {c.curso}
                      </option>
                    ))}
                  </Select>

                  <Label>
                    Empresa Parceira ?
                    <CheckBox
                      onClick={() => {
                        setTemParceiro(!TemParceiro);
                      }}
                    >
                      <IoMdCheckmark
                        style={{
                          transition: "all 0.2s ease-in-out",
                        }}
                        color={
                          TemParceiro ? "var(--blue-color)" : "transparent"
                        }
                      />
                    </CheckBox>
                  </Label>
                  <Input
                    type="text"
                    placeholder="Empresa parceira se existir"
                    value={Parceiro}
                    onChange={(e) => setParceiro(e.target.value)}
                    disabled={!TemParceiro}
                    maxLength="15"
                  />

                  <Label>Matriz:</Label>
                  <Select
                    type="name"
                    value={grade}
                    onChange={(e) => {
                      setGrade(e.target.value);
                      setId_grade(e.target[e.target.options.selectedIndex].id);
                    }}
                    required
                  >
                    <option disabled value="">
                      {!id_curso
                        ? "Selecione um curso"
                        : ano_semestre_grade.length === 0
                        ? "Curso sem Matriz"
                        : "Selecione a matriz"}
                    </option>
                    {ano_semestre_grade?.map((a, i) => (
                      <option id={a?.id_grade} key={i}>
                        {a?.descricao}
                      </option>
                    ))}
                  </Select>
                  <Label>Módulo:</Label>
                  <Select
                    type="text"
                    value={modulo}
                    placeholder="Módulo"
                    onChange={(e) => setModulo(e.target.value)}
                    required
                  >
                    <option disabled value="">
                      {!id_grade ? "Selecione a matriz" : "Selecione o módulo"}
                    </option>
                    {modulos.map((m, i) => (
                      <option value={m.modulo} key={i}>
                        {m.modulo}
                      </option>
                    ))}
                  </Select>
                  <Label>Turno:</Label>
                  <Select
                    type="name"
                    value={turno}
                    onChange={(e) => {
                      setTurno(e.target.value);
                      setIdTurno(e.target[e.target.options.selectedIndex].id);
                    }}
                    required
                  >
                    <option disabled value="">
                      Selecione o turno
                    </option>
                    {turnos.map((c, i) => (
                      <option id={c.id} key={i}>
                        {c.nome}
                      </option>
                    ))}
                  </Select>
                  <Label>Ano/Semestre:</Label>
                  <Select
                    type="text"
                    value={ano_semestre}
                    onChange={(e) => setAno_semestre(e.target.value)}
                    required
                  >
                    <option disabled value="">
                      Selecione o ano/semestre
                    </option>
                    {AnoSemestre().map((a, i) => (
                      <option key={i}>{a}</option>
                    ))}
                  </Select>
                  <Label>Início:</Label>
                  <Input
                    type="date"
                    value={data_inicio}
                    onChange={(e) => setData_inicio(e.target.value)}
                    required
                  />
                  <Label>Fim:</Label>
                  <Input
                    type="date"
                    value={data_fim}
                    onChange={(e) => setData_fim(e.target.value)}
                    required
                  />
                </FormContent>
                <br />
                <Button type="submit">Cadastrar</Button>
              </Form>
              <CloseModalButton
                onClick={() => {
                  setShowModal(!showModal);
                }}
              />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
