import styled from "styled-components";

export const Background = styled.div`
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 80px);
  background-color: #0093e9;
  background-image: linear-gradient(
    0deg,
    #0093e9 0%,
    #99d4f7 50%,
    #ffffff 100%
  );
  @media (max-height: 510px) {
    height: 100%;
  }
`;

export const Container = styled.div`
  margin-top: 55px;
  height: 100%;
  min-width: 80vw;
  width: fit-content;
  background: #fff;
  padding: 2rem 1rem;
  align-items: center;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;

  @media screen and (max-width: 1050px) {
    width: 95%;
  }

  th {
    @media screen and (max-width: 830px) {
      display: none;
    }
  }
`;

export const Titulo = styled.div`
  span {
    font-size: 20px;
  }

  legend {
    margin: 12px 0 0px 30px;
  }
`;

export const Hr = styled.div`
  background-color: #0093e9;
  height: 0.19rem;
  width: 92%;
  margin-top: 15px;
`;

export const Form = styled.div`
  margin-top: 10px;
  font-size: 18px;

  form {
    display: grid;
    grid-template-areas:
      "c m b"
      "i f p";
    align-items: center;
    justify-content: start;
    gap: 15px 30px;

    @media (max-width: 950px) {
      grid-template-areas:
        "c c"
        "m m"
        "i i"
        "f f"
        "b p";

      button {
        justify-self: center;
      }
    }
  }
`;

export const InputLabel = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  /* border: 1px solid red; */

  label {
    margin-top: 5px;
    margin-right: 5px;
    text-align: right;
  }

  input {
    height: 30px;
    width: 250px;
    border-radius: 5px;
    text-align: center;
    padding: 3px 7px;
  }

  select {
    height: 30px;
    width: 250px;
    border-radius: 5px;
    border-top: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid #767676;
    border-bottom: 2px solid #767676;
    text-align: center;
    -webkit-appearance: none;
    background: url("https://cdn-icons-png.flaticon.com/512/60/60995.png") 95.5%
      50% no-repeat;
    background-size: 14px;
    &:invalid {
      color: gray;
    }
  }

  b {
    margin-left: 5px;
  }

  @media (max-width: 950px) {
    label {
      width: 150px !important;
    }
  }
`;

export const InputLabel2 = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;

  label {
    margin-right: 15px;
    margin-left: 5px;
  }

  input {
    height: 30px;
    width: 210px;
    border-radius: 5px;
    text-align: center;
    padding: 3px 7px;
  }

  select {
    height: 30px;
    width: 210px;

    border-radius: 5px;
    text-align: center;
    margin-left: 5px;
    -webkit-appearance: none;
    background: url("https://cdn-icons-png.flaticon.com/512/60/60995.png") 95.5%
      50% no-repeat;
    background-size: 14px;
    &:invalid {
      color: gray;
    }
  }
`;

export const Button = styled.button`
  margin-right: 5px;
  width: 100px;
  height: 40px;
  border-radius: 4px;
  background: #2b74e0;
  color: #fff;
  border: 2px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;
  &:hover {
    background-color: #1563cf;
  }
`;

export const Date = styled.div`
  padding: 5px 0 0 30px;
`;

export const Table = styled.div`
  margin-top: 15px;

  table {
    margin-bottom: 15px;
  }
`;

export const BigButton = styled.button`
  margin-right: 15px;
  width: 140px;
  height: 45px;
  border-radius: 4px;
  background: #2b74e0;
  color: #fff;
  border: 2px;
  outline: none;
  cursor: pointer;

  font-weight: bold;
  font-size: 17px;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;
  &:hover {
    background-color: #1563cf;
  }
`;

export const CheckBox = styled.div`
  border: 2px solid var(--blue-color);
  width: 19px;
  height: 19px;
  border-radius: 25%;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
