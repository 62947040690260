import styled from "styled-components";

export const Background = styled.div`
  min-height: calc(100vh - 80px);
  @media (max-height: 739px) {
    height: 100%;
  }
  display: flex;
  padding: 0.5rem calc((90vw - 1000px) / 2);
  background-color: #0093e9;
  background-image: linear-gradient(
    0deg,
    #0093e9 0%,
    #99d4f7 50%,
    #ffffff 100%
  );
`;

export const Container = styled.div`
  margin: 50px auto auto 0;
  width: 100%;
  height: auto;
  background: #fff;
  padding: 2rem 1rem;
  align-items: center;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  min-width: 89px;
`;

export const Inputs = styled.div`
  padding: 20px 0;
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr 100px 100px 100px auto;
  gap: 5px 10px;
  grid-template-areas:
    "e1 e2 e3 e3 e3 e3"
    "e4 e5 e6 e7 e8 e9";

  h4 {
    &:nth-child(1) {
      grid-area: e1;
    }
    &:nth-child(2) {
      grid-area: e2;
    }
    &:nth-child(3) {
      grid-area: e3;
    }
  }

  select {
    &:nth-child(4) {
      grid-area: e4;
    }
    &:nth-child(5) {
      grid-area: e5;
    }
    &:nth-child(6) {
      grid-area: e6;
    }
  }

  input {
    &:nth-child(7) {
      grid-area: e7;
    }
    &:nth-child(8) {
      grid-area: e8;
    }
  }

  button {
    &:nth-child(9) {
      grid-area: e9;
    }
  }
`;

export const Hr = styled.div`
  background-color: #0093e9;
  height: 0.19rem;
  width: 100%;
  margin-top: 15px;
`;

export const Form = styled.div`
  margin-top: 10px;
  font-size: 18px;

  label {
    margin-left: 5px;
    margin-top: 10px;
  }

  input {
    border-radius: 5px;
    text-align: center;
    height: 30px;
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
    padding: 3px 7px;
  }

  select {
    border-radius: 5px;
    text-align: center;
    height: 30px;
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
    -webkit-appearance: none;
    background: url("https://cdn-icons-png.flaticon.com/512/60/60995.png") 95.5%
      50% no-repeat;
    background-size: 14px;
  }
`;

export const InputLabel = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  label {
    margin-right: 15px;
    margin-left: 5px;
  }

  select {
    border-radius: 5px;
    text-align: center;
    height: 30px;
    margin-left: 5px;
    margin-right: 5px;
    -webkit-appearance: none;
    background: url("https://cdn-icons-png.flaticon.com/512/60/60995.png") 95.5%
      50% no-repeat;
    background-size: 14px;
    width: 100%;
  }

  input {
    height: 30px;
    border-radius: 5px;
    text-align: center;
    width: 100%;
    padding: 3px 7px;
  }
`;

export const Button = styled.button`
  margin-right: 5px;
  width: 100px;
  height: 30px;
  border-radius: 4px;
  background: #2b74e0;
  color: #fff;
  border: 2px;
  outline: none;
  cursor: pointer;

  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;
  &:hover {
    background-color: #1563cf;
  }
`;

export const Table = styled.div`
  margin-top: 15px;

  table {
    color: black;
    font-weight: bolder;
    font-size: 20px;
    border: 2px solid whitesmoke;
    width: 100%;
    text-align: center;
    border-collapse: collapse;

    thead {
      background: whitesmoke;
    }

    tr:nth-child(even) {
      background: #f3f3f3;
    }

    td {
      border: 2px solid black;
    }
  }
`;

export const ButtonAdd = styled.button`
  width: 25px;
  height: 25px;
  border-radius: 4px;
  background: #2b74e0;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;
  margin-top: 2px;

  &:hover {
    background-color: #1563cf;
  }
`;

export const Titulo = styled.h1`
  color: #0093e9;
  text-align: center;

  @media (max-width: 550px) {
    font-size: 28px;
  }
`;

export const H4 = styled.h4`
  font-weight: normal;
  padding: 0 5px;
`;

export const BigButton = styled.button`
  margin-right: 5px;
  width: 140px;
  height: 45px;
  border-radius: 4px;
  background: #2b74e0;
  color: #fff;
  border: 2px;
  outline: none;
  cursor: pointer;

  font-weight: bold;
  font-size: 17px;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;
  &:hover {
    background-color: #1563cf;
  }
`;
