import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import {
  Background,
  Container,
  Titulo,
  Button,
  Form,
  Table,
  Row,
} from "./style";
import Auth from "../../../services/auth";
import DiasFechamento from "../../../services/DiasFimMes";
import { useHistory } from "react-router-dom";
import { Swal } from "../../../services/SwalTemplates";

export default function Home() {
  const history = useHistory();
  const [id] = useState(Auth.id());
  const [homeCoord, setHomeCoord] = useState([]);
  const [dataInicio, setDataInicio] = useState("");
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    api
      .get(`/coord/home/${id}?dataInicio=${dataInicio}`)
      .then((response) => {
        setHomeCoord(response.data.grade);
      })
      .catch(() => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [dataInicio, history, id, trigger]);

  return (
    <Background>
      <Container>
        <Form>
          <Row>
            <form>
              <br />
              <div>
                <label>Aulas de</label>
                <input
                  type="date"
                  name="date"
                  id=""
                  value={dataInicio}
                  onChange={(e) => setDataInicio(e.target.value)}
                />
                <Button
                  type="button"
                  onClick={() => {
                    setTrigger(!trigger);
                  }}
                >
                  Mostrar
                </Button>
              </div>
            </form>
            <Titulo>
              <h3>
                <DiasFechamento />
              </h3>
            </Titulo>
          </Row>
        </Form>
        <Table>
          <table>
            <thead>
              <tr>
                <th>Unidade</th>
                <th>Turma</th>
                <th>Dia</th>
                <th>Início</th>
                <th>Fim</th>
                <th>Professor</th>
                <th>Disciplina</th>
              </tr>
            </thead>
            <tbody>
              {homeCoord.map((h, i) => (
                <tr key={i}>
                  <td
                    title={h.unidade}
                    data-title="Unidade"
                    className="td-left"
                  >
                    {h.unidade}
                  </td>
                  <td title={h.turma} data-title="Turma" className="td-left">
                    {h.turma
                      .replaceAll("TÉCNICO EM ", "")
                      .replaceAll("CURSO TÉCNICO ", "")
                      .replaceAll("TECNÓLOGO EM ", "")
                      .slice(0, 50)}
                    {h.turma
                      .replaceAll("TÉCNICO EM ", "")
                      .replaceAll("CURSO TÉCNICO ", "")
                      .replaceAll("TECNÓLOGO EM ", "").length > 50 && "..."}
                  </td>
                  <td title={h.dia_semana} data-title="Dia da semana">
                    {h.dia_semana}
                  </td>
                  <td title={h.horario_inicio} data-title="Horario início">
                    {h.horario_inicio}
                  </td>
                  <td title={h.horario_fim} data-title="Horario fim">
                    {h.horario_fim}
                  </td>
                  <td
                    title={h.professor}
                    data-title="Professor"
                    className="td-left"
                  >
                    {h.professor}
                  </td>
                  <td
                    title={h.disciplina}
                    data-title="Disciplina"
                    className="td-left"
                  >
                    {h.disciplina.toUpperCase()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Table>
      </Container>
    </Background>
  );
}
