import styled from "styled-components";
import { MdClose } from "react-icons/md";

export const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 23;
`;

export const Button = styled.button`
  margin-right: 5px;
  width: 180px;
  height: 45px;
  border-radius: 4px;
  background: #2b74e0;
  color: #fff;
  border: 2px;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;
  &:hover {
    background-color: #1563cf;
  }
`;

export const ModalContent = styled.div`
  min-width: 800px;
  width: 100%;
  height: 90%;
  margin: 0 auto;
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin-top: 10px;
    text-transform: uppercase;
    color: #0093e9;
  }
`;

export const InputLabel = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  width: 250px;

  label {
    margin-right: 15px;
    margin-left: 5px;
    margin-top: 10px;
  }

  select {
    height: 30px;
    border-radius: 5px;
    text-align: center;
    padding: 3px 7px;
  }
`;

export const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;

export const Form = styled.form`
  width: 100%;
  text-align: center;
`;

export const Input = styled.input`
  border-radius: 5px;
  width: 300px;
  height: 35px;
  margin: 10px;
  text-align: center;
  border: 2px solid black;
  caret-color: #0093e9;
`;

export const Hr = styled.div`
  background-color: #0093e9;
  height: 0.19rem;
  width: 100%;
  margin-top: 15px;
`;

export const TableForm = styled.div`
  margin-top: 15px;

  table {
    color: black;
    font-size: 15px;
    border: 1px solid whitesmoke;
    width: 100%;
    text-align: center;
    border-collapse: collapse;

    thead {
      background: whitesmoke;
      border: 1px solid;
    }

    tr:nth-child(even) {
      background: #f3f3f3;
    }

    tbody tr {
      cursor: pointer;

      &:hover {
        filter: brightness(90%);
        box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
        transform: translateY(-1px);
        background: #f3f3f3;
      }
    }

    td {
      border: 1px solid black;
    }
  }
`;
