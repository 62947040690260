import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
  Input,
  Label,
  Select,
} from "../../ModalRegisterStyle";
import api from "../../../../services/api";
import Swal from "sweetalert2";

export default function ModalRegister({ showModal, setShowModal }) {
  const modalRef = useRef();
  const [nome, setNome] = useState("");
  const [evento, setEvento] = useState(0);
  const [centroCusto, setCentroCusto] = useState("");
  const [idCentroCusto, setIdCentroCusto] = useState(0);
  const [centrosCusto, setCentrosCusto] = useState([]);
  const [vinculo, setVinculo] = useState("");
  const [Multiplicador, setMultiplicador] = useState(1);
  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    api
      .get(`/rh/costcenter`)
      .then((response) => {
        setCentrosCusto(response.data.centros_custo);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, []);

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  function clear() {
    setNome("");
  }

  /**
   * Vai servir para gerar o pdf do relatório
   * @param {Previne o evento padrão da função} e
   */
  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      nome: nome.trim(),
      id_evento: evento,
      id_centro_custo: idCentroCusto,
      vinculo: vinculo,
      multiplicador: Multiplicador,
    };
    try {
      const response = await api.post(`rh/releases`, { data });
      if (response.data.error) {
        return Swal.fire("Atenção!", response.data.error, "warning");
      }
      Swal.fire("Sucesso!", response.data.success, "success");
      Toast.fire({
        icon: "success",
        title: "Cadastrado com sucesso!",
      });
      setShowModal(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      alert(error);
    }
  }

  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              setShowModal(!showModal);
              clear();
            }
          }}
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form onSubmit={handleSubmit}>
                <h3>Cadastrar Tipo de Lançamento</h3>
                <FormContent>
                  <Label>Nome:</Label>
                  <Input
                    type="name"
                    value={nome}
                    placeholder="Nome"
                    onChange={(e) => setNome(e.target.value.toUpperCase())}
                    required
                  />
                  <Label>Código do Evento:</Label>
                  <Input
                    type="number"
                    value={evento}
                    placeholder="Código Evento"
                    onChange={(e) => setEvento(e.target.value)}
                    required
                  />
                  <Label>Centro de Custo</Label>
                  <Select
                    value={centroCusto}
                    defaultvalue="Selecione o vínculo"
                    onChange={(e) => {
                      setCentroCusto(e.target.value);
                      setIdCentroCusto(
                        e.target[e.target.options.selectedIndex].id
                      );
                    }}
                    required
                  >
                    <option disabled value="">
                      Selecione o centro de custo
                    </option>
                    {centrosCusto.map((c, i) => (
                      <option key={i} id={c.id}>
                        {c.nome}
                      </option>
                    ))}
                  </Select>
                  <Label>Vínculo</Label>
                  <Select
                    defaultValue="Selecione o vínculo"
                    value={vinculo}
                    onChange={(e) => {
                      setVinculo(e.target.value);
                    }}
                    required
                  >
                    <option disabled value="">
                      Selecione o vínculo
                    </option>
                    <option>TODOS</option>
                    <option>ESTATUTARIO</option>
                    <option>TEMPORARIO - CLT</option>
                  </Select>
                  <Label>Multiplicador:</Label>
                  <Input
                    type="number"
                    value={Multiplicador}
                    placeholder="Multiplicador"
                    onChange={(e) => setMultiplicador(e.target.value)}
                    required
                  />
                </FormContent>
                <br />
                <Button type="submit">Cadastrar</Button>
              </Form>
              <CloseModalButton
                onClick={() => {
                  setShowModal(!showModal);
                  clear();
                }}
              />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
