import React, { useState, useEffect } from "react";
import {
  Background,
  Container,
  Titulo,
  Button,
  Form,
  Table,
  Row,
} from "./style";
import api from "../../../services/api";
import DiasFechamento from "../../../services/DiasFimMes";
import { Swal } from "../../../services/SwalTemplates";

export default function Home() {
  const [homeRh, setHomeRh] = useState([]);
  const [dataInicio, setDataInicio] = useState("");
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    api
      .get(`/rh/home?dataInicio=${dataInicio}`)
      .then((response) => {
        setHomeRh(response.data.grade);
      })
      .catch(() => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [dataInicio, trigger]);

  return (
    <Background>
      <Container>
        <Form>
          <Row>
            <form>
              <br />
              <label>Aulas de</label>
              <input
                type="date"
                name="date"
                id=""
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
              />
              <Button
                type="button"
                onClick={() => {
                  setTrigger(!trigger);
                }}
              >
                Mostrar
              </Button>
            </form>
            <Titulo>
              <h3>
                <DiasFechamento />
              </h3>
            </Titulo>
          </Row>
        </Form>
        <Table>
          <table>
            <thead>
              <tr>
                <th style={{ width: "20%" }}>Unidade</th>
                <th style={{ width: "35%" }}>Turma</th>
                <th style={{ width: "5%" }}>Dia</th>
                <th style={{ width: "7%" }}>Inicio</th>
                <th style={{ width: "7%" }}>Fim</th>
                <th style={{ width: "15%" }}>Professor</th>
              </tr>
            </thead>
            <tbody>
              {homeRh.map((h, i) => (
                <tr key={i}>
                  <td
                    title={h.unidade}
                    data-title="Unidade"
                    className="td-left"
                  >
                    {h.unidade.slice(0, 30)}
                    {h.unidade?.length > 30 && "..."}
                  </td>
                  <td title={h.turma} data-title="Turma" className="td-left">
                    {h.turma
                      .replaceAll("TÉCNICO EM ", "")
                      .replaceAll("CURSO TÉCNICO ", "")
                      .replaceAll("TECNÓLOGO EM ", "")
                      .slice(0, 55)}
                    {h.turma
                      .replaceAll("TÉCNICO EM ", "")
                      .replaceAll("CURSO TÉCNICO ", "")
                      .replaceAll("TECNÓLOGO EM ", "")?.length > 55 && "..."}
                  </td>
                  <td title={h.dia_semana} data-title="Dia da semana">
                    {h.dia_semana}
                  </td>
                  <td title={h.horario_inicio} data-title="Horário início">
                    {h.horario_inicio}
                  </td>
                  <td title={h.horario_fim} data-title="Horário fim">
                    {h.horario_fim}
                  </td>
                  <td
                    title={h.professor}
                    data-title="Professor"
                    className="td-left"
                  >
                    {h.professor}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Table>
      </Container>
    </Background>
  );
}
