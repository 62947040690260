import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import {
  Container,
  Titulo,
  Hr,
  Button,
  Table,
  Background,
  Row,
  Input,
} from "./style";
import Auth from "../../../services/auth";
import { Swal, Toast } from "../../../services/SwalTemplates";
import Pagination from "../../../components/Pagination";
import ModalRegister from "../../../components/Modal/Coord/Turmas/Register";
import ModalEdit from "../../../components/Modal/Coord/Turmas/Edit";
import { IoPencilOutline, IoTrashOutline } from "react-icons/io5";

export default function ListTurmas() {
  const [id] = useState(Auth.id());
  const [page, setPage] = useState(1);
  const [turmas, setTurmas] = useState([]);
  const [turma, setTurma] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalRegister, setShowModalRegister] = useState(false);

  useEffect(() => {
    api
      .get(`/coord/turmas/${id}?nome=${search}`)
      .then((response) => {
        setTurmas(response.data.turmas);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [id, search]);

  useEffect(() => {
    setTotalPages(Math.ceil(turmas.length / 15));
  }, [turmas]);

  function handleDelete(id) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "Você não poderá reverter essa ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Deletar",
      cancelButtonColor: "gray",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await api.delete(`/coord/classes/${id}`);
        if (response.data.error) {
          return Swal.fire({
            title: "Exclusão não permitida!",
            text: response.data.error,
            icon: "warning",
            confirmButtonColor: "var(--blue-color)",
          });
        }
        Toast.fire({
          icon: "success",
          title: "Deletado com sucesso!",
        });
        setTurmas(turmas.filter((turma) => turma.id !== id));
      }
    });
  }

  return (
    <Background>
      <Container>
        <Titulo>Controle de Turmas</Titulo>
        <Hr />
        <Row>
          <Button
            onClick={() => {
              setShowModalRegister(true);
            }}
          >
            Novo
          </Button>
          <Input>
            <input
              value={search}
              onChange={(e) => {
                setSearch(e.target.value.toUpperCase());
              }}
              type="search"
              placeholder="Pesquisar nome..."
            />
          </Input>
        </Row>
        <Table>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Unidade</th>
                <th>Início</th>
                <th>Fim</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {turmas.slice((page - 1) * 15, page * 15).map((t, i) => (
                <tr key={i}>
                  <td>{t.id}</td>
                  <td
                    data-title="Turma"
                    className="td-left"
                    title={t.turma
                      .replaceAll("TECNÓLOGO EM ", "")
                      .replaceAll("TÉCNICO EM ", "")
                      .replaceAll("CURSO TÉCNICO ", "")}
                  >
                    {t.turma
                      .replaceAll("TECNÓLOGO EM ", "")
                      .replaceAll("TÉCNICO EM ", "")
                      .replaceAll("CURSO TÉCNICO ", "")
                      .slice(0, 60)}
                    {t.turma
                      .replaceAll("TECNÓLOGO EM ", "")
                      .replaceAll("TÉCNICO EM ", "")
                      .replaceAll("CURSO TÉCNICO ", "").length > 60 && "..."}
                  </td>
                  <td
                    title={t.unidade}
                    data-title="Unidade"
                    className="td-left"
                  >
                    {t.unidade.slice(0, 30)}
                    {t.unidade.length > 30 && "..."}
                  </td>
                  <td data-title="Data Início">{t.data_inicio}</td>
                  <td data-title="Data Fim">{t.data_fim}</td>
                  <td data-title="Editar">
                    <IoPencilOutline
                      title="Editar"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={() => {
                        setShowModalEdit(true);
                        setTurma(t);
                      }}
                    />
                  </td>
                  <td data-title="Excluir">
                    <IoTrashOutline
                      title="Excluir"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={() => {
                        handleDelete(t.id);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Table>
        <ModalRegister
          showModal={showModalRegister}
          setShowModal={setShowModalRegister}
        />
        <ModalEdit
          showModal={showModalEdit}
          setShowModal={setShowModalEdit}
          data={turma}
        />
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Container>
    </Background>
  );
}
